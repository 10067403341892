@import "~react-dates/lib/css/_datepicker.css";

.CalendarDay__selected_span {
    background: $wcra-green !important;;
    border: 1px double $wcra-green !important;;
    color: $white !important;
    opacity: 0.6;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: $wcra-green !important;;
    border: 1px double $wcra-green !important;;
    color: $white !important;
    opacity: 1;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: $wcra-green !important;
    border: 1px double $wcra-green !important;
    color: $white !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: $wcra-green !important;
    opacity: 0.4 !important;
    border: 1px double $wcra-green !important;
    color: $white !important;
}

.CalendarDay__hovered_span:active {
    background: $wcra-green !important;
    opacity: 0.4 !important;
    border: 1px double $wcra-green !important;
    color: $white !important;
}

.DayPickerNavigation_button__default {
    border: none !important;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: none !important;
}

.DayPickerNavigation_svg__horizontal {
    fill: $wcra-green !important;
}

.DateInput {
    width: 100% !important;
    background-color: transparent !important;
    &:nth-child(3) {
        display: none !important;
    }
}

.DateInput_input {
    padding-left: 0;
    font-size: 14px !important;
    white-space: nowrap;
    line-height: 34px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    height: 36px !important;
    border-bottom: 1px solid rgba($medium-gray2, 0.6) !important;
    background-color: transparent !important;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    color: $gray;

    &:hover {
        border-bottom: 1px solid $medium-gray2 !important;
    }

    &::-webkit-input-placeholder {
        opacity: 0.5 !important;
    }
    &::-moz-placeholder {
        opacity: 0.5 !important;
    }
    &:-ms-input-placeholder {
        opacity: 0.5 !important;
    }
    &:-moz-placeholder {
        opacity: 0.5 !important;
    }
}

.DateInput_input__focused {
    border-bottom: 1px solid $medium-gray2 !important;
}

.DateInput_input__disabled {
    background: $white !important;
    font-style: normal !important;
    border-color: $mid-gray !important;
    box-shadow: none !important;
    opacity: 0.5;
    cursor: default;
}

.DateInput__disabled {
    background: $white !important;
    box-shadow: none;
    cursor: default;

    input:hover {
        box-shadow: none !important;
    }
}

.DateRangePicker {
    width: 100%;
}

.DateRangePickerInput {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    background-color: transparent;
    border: none;
    position: relative;
}

.DateRangePickerInput_arrow_svg {
    width: 16px;
    height: 16px;
    margin: 0 10px;
}

.DateRangePickerInput_calendarIcon {
    position: absolute;
    right: 0px;
    margin: 0 auto;
    color: $wcra-green;
}

.DateRangePickerInput_calendarIcon > svg {
    font-size: 16px;
}