.stock-action-button {
  margin: 25px 25px 25px 15px !important;
}

.performance-collection {
  padding: 1em;
  border: .5px solid $wcra-gray;
  border-radius: 2px;
  background-color: $white;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.05), 0 6px 12px 0 rgba(0,0,0,0.1);

  position: absolute;
  left: 82%;
  z-index: 3;
  width: 16em;
  font-family: $secondary-font;
  color: $wcra-black;
  font-size: 16px;
  letter-spacing: 0.7px;
}

.animal-event-down-arrow {
  position: relative;
  font-size: 9px;
  padding-left: .3em;
  color: $wcra-green;
  cursor: pointer;
  border: 0px;
  background: transparent;
}

.table-cell-listing.no-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
