$diameter: 84px;

.spinner-container {
  display: none;
  background-color: rgba(255,255,255, 0.5);
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 1301;

  &.modal {
    position: absolute;
  }

  &.show {
    display: block;
  }

  .spinner {
    color: $green;
    position: fixed;
    z-index: 1302;
    top: 50%;
    left: 50%;
    width: $diameter;
    height: $diameter;
    margin: -$diameter / 2;
    animation: spin 1s linear infinite;
  }
}

// parent container must be set to position relative
.component-spinner-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  opacity: .8;

  &.show {
    display: flex;
  }

  .spinner {
    color: $green;
    z-index: 1302;
    margin: auto;
    width: 48px;
    height: 48px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.overlayed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loadingRotation {
  animation: spin 1s linear infinite;
}