.membership-no-season{
  margin-top: 100px;
  text-align: center;
  .paper-no-season{
    margin-bottom: 20px;
  }
  h3{
    padding: 24px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.704px;
  }
  .create-new-season-button{
    display: flex;
    flex-direction: row;
    gap: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    span{
      color: white;
    }
  }
}
.new-season-container{
  margin-top: 24px;
  .section-title{
    margin-bottom: 24px;
  }
  .button-container{
    margin-bottom: 24px;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }
  .container{
    padding: 24px;
  }
  .disciplines-text{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 24px;
    &.error {
      color: $unavailable-red;
    }
  }
}

.season-info{
  .dates-containers{
    margin-top: 24px;
    .date-title{
      font-size: 13px;
      margin-bottom: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.disciplines-seasons{
  .checkbox-component{
    padding: 6px;
  }
  .checkbox-label{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.484px;
    text-transform: uppercase;
  }
  .discipline-gird{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-auto-flow: column;
  }
}

.membership-cards{
  .subtitle{
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .details{
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .age-fields{
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }
  .toggle-wrap {
    margin-top: 24px;
    .toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      .form-label {
        text-transform: uppercase;
        position: relative;
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        transform: none;
      }
    }
  }
}

.modal-membership{
  .modal-title{
    font-size: 20px;
  }
  .body-modal{
    padding: 24px;
  }
  .membership-cards{
    .details{
      .subtitle{
        font-size: 16px;
      }
    }
  }
  .actions{
    .btn-actions{
      align-items: center;
      gap: 10px;
      justify-content: flex-end;;
    }
  }
}




