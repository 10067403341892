.alert-snackbar {
  z-index: 3;
  position: fixed;
  text-align: center;
  right: 50%;
  transform: translate(50%, 0%);
}
.alert-notification {
  &.success {
    background-color: $wcra-green;
    max-width: 800px;
  }

  &.fail {
    background-color: $red;
    max-width: 800px;
  }

  &.warn {
    color: $black;
    background-color: $gold;
    max-width: 800px;
  }

  &.right {
    left: 78%;
  }

  &.content-centered {
    div:nth-child(1) {
      margin: auto;
    }
  }
}
