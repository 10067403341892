.csv-file-upload input[type="file"] {
    display: none;
}
.csv-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
.merge-event-field {
    height: 180px;
}

.merge-event-info-text{
    font-weight: 400;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 12px;
    margin-bottom: 7px;
}

.merge-event-field{
    margin-top: 50px;
}

p.merge-event-error-message{
    font-size: 14px;
    color: $red;
}
