.summary-add-go-round-row {
  margin-top: 15px;
  color: $wcra-black !important;

  .add-row-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .add-row-button {
      margin-left: 15px;
      padding: 8px 16px !important;
      &:disabled {
        opacity: 0.4;
      }
    }

    .add-row-input {
      max-width: 80px;
      border-radius: 5px;
      width: 100%;
      border: 1px solid $wcra-dark-blue;
      padding: 10px;
      font-size: 16px;
    }
  }
}

.nominated {
  display: flex;
  align-items: center;
  .nominated-icon {
    width: 10px;
    height: 10px;
    border: 1px solid $wcra-green;
    background-color: $wcra-green;
    border-radius: 10px;
    margin-right: 10px;
  }
}

.summary-view-go-round-table {
  .go-round-table-head {
    th:first-child {
      padding-left: 15px !important;
    }
  }

  .go-round-field-array {
    td:first-child {
      padding-left: 15px !important;
    }

    height: 58px !important;

    th:first-child {
      padding-left: 15px !important;
    }

    .done-icon {
      color: $wcra-green;
    }

    .done-icon, .clear-icon, .delete-icon, .edit-icon {
      cursor: pointer;

      &.disabled {
        cursor: default;
        opacity: 0.2;
      }
    }

    .input-cell {
      padding: 0px 20px 0px 5px !important;

      &.input-number {
        padding: 0px 20px 0px 20px !important;

        .no-label {
          input {
            text-align: center;
          }
        }
      }

      .no-label {
        margin-top: 0px !important;
        position: relative;

        .Select-control {
          margin-top: 0px !important;
          height: unset !important;
          border-bottom-color: rgba(0, 0, 0, 0.42) !important;

          .Select-input {
            max-height: 31px !important;
          }

          .Select-placeholder, .Select-value {
            letter-spacing: normal !important;
            word-spacing: normal !important;
            color: $wcra-black !important;
            text-transform: uppercase !important;
            font-size: 14px !important;
          }

          .Select-placeholder {
            opacity: 0.6 !important;
          }
        }

        input {
          font-size: 14px !important;
          height: unset;

          &::placeholder {
            color: $wcra-black !important;
            text-transform: uppercase !important;
            opacity: 0.6 !important;
          }
        }
      }
    }
  }
}