@import
  'base/fonts',
  'base/settings',
  'base/layout',
  'base/reuse',
  'base/base',
  'components/changes-not-saved-modal',
  'components/table',
  'components/edit-performance-dialog',
  'components/enhanced-table-toolbar',
  'components/select-box',
  'components/spinner',
 'components/chip_alliance_level',
  'components/notification',
  'components/alert-notification',
  'components/bulk-merge-input',
  'components/text-message-dialog',
  'components/splash-screen',
  'components/datepicker',
  'components/disciplines-modal',
  'components/merge-events-modal',
  'components/filter-input',
  'components/entries-table-cell-discipline', 'components/select-checkboxes','components/chip',
  'components/entries-table-cell-status', 'components/entries_indicator',
  'components/entry-add-partner-modal',
  'components/chip-popover',
  'components/_inline-alert-notification.scss', 'components/stripe-input','components/_stock-performance-modal.scss',
  'components/transaction-history-cards', 'containers/summary', 'components/nominationLogsModal',
  'containers/nominations', 'containers/coupons', 'containers/trade-performance', 'containers/giftcards',
  'containers/stock-selection', 'containers/entry-modal',
  'containers/contestant', 'containers/manage-results', 'containers/events',
  'containers/export-entries-modal', 'containers/manage-purse',
  'containers/dashboard', 'containers/event-associations',
  'containers/athlete-entry', 'containers/entries', 'containers/classification', 'containers/associations-promoters',
 'containers/membership',
  'containers/error-boundary';

body {
  font-family: $secondary-font;
  font-weight: 500;
  margin: 0;
  padding: 0;
  background-color: #f5f6f9;
}
