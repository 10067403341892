.purse-management, .configure {
  .main-grid {
    padding: 16px 24px;

    &.colored {
      background-color: #C9D6E7;
    }

    .item {
      margin-bottom: 12px;

      .centered {
        display: flex;
        justify-content: center;
        align-content: center;
      }

      &.bordered {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #f2f4f7;
      }

      .title {
        display: flex;
        align-items: center;
        .remove-custom-icon {
          font-size: 14px;
          margin-top: 2px;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .input-item {
        background-color: #C9D6E7;
        border: 1px solid #576475;
        border-radius: 5px;
        padding: 5px 10px;
        &::after,
        &::before {
          content: unset;
        }

        &.not-disabled {
          background-color: white;
        }
      }
    }
  }
}

.configure {
  .purse {
    padding-bottom: 0px;
    .go-rounds {
      margin-top: 10px;
      margin-bottom: 20px;

      select {
        font-size: 14px;
      }
    }
  }

  .day-money {
    margin-top: -10px;
    .go-rounds {
      margin-top: 10px;
      margin-bottom: 20px;

      select {
        font-size: 14px;
      }
    }
  }
}

.purse-management {
  .payouts {
    padding-bottom: 0px;
    border-bottom: 1px solid #c9d6e7;

    .go-rounds {
      margin-top: 5px;
      select {
        font-size: 14px;
      }
    }

    .bordered {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #c9d6e7;
    }

    .input-item-colored {
      background-color: #C9D6E7;
    }

    .places {
      margin-top: 15px;

      .place-row {
        padding: 5px 12px;

        &:nth-child(odd) {
          background-color: #f2f4f7;
        }
        .place-cell {
          font-weight: lighter !important;
        }
      }
    }
  }

  .money-payouts {
    .performances {
      margin-top: 15px;
    }
  }
}
