.notification-container {
  background-color:  #461e1e;
  border-radius: 0;
  padding: 12px;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 15px;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
  align-content: stretch;
  letter-spacing: 0;

  color: #fff;

  &.success {
    background-color: transparent;
  }
}
.notification-column {
  display: inline-block;
  max-width: 80%;
}
.notification-heading {
  color: #fff;
  font-weight: 700;
  font-size: 16px;

  &.success {
    color: #8CE04A;
  }
}
.notification-message {
  color: #fff;
  font-weight: 600;
  font-size: 14px;

  &.success {
    color: #8CE04A;
  }
}
.notification-icon {
  height: 24px;
  width: 24px;
  color: #fff;
  float: left;
  padding-right: 35px;

  &.success {
    color: #8CE04A;
  }
}
