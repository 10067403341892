.chip-alliance-level {
  .gold-chip-container{
    width: 86px;
    height: 31px;
    background: linear-gradient(98.94deg, #ECC335 8.54%, #E0A630 93.47%);
    border-radius: 22px;

  }
  .silver-chip-container {
    width: 86px;
    height: 31px;
    background: linear-gradient(97.52deg, #F8F8F8 0%, #D0D0CE 106.59%);
    border-radius: 22px;

  }
  .bronze-chip-container {
    width: 86px;
    height: 31px;
    background: linear-gradient(97.52deg, #E3C9A6 0%, #A78E6F 106.59%);
    border-radius: 22px;
  }
}
