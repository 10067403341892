.inline-alert-container {
  margin-top: 20px;

  &.hidden {
    display: none;
  }
}

.inline-alert-notification {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .message {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    vertical-align: middle;
    align-items: center;
    font-size: 24px;
    padding: 10px 0 10px 10px;

    .message-icon-error {
      color: $red;
    }

    .message-icon-warn {
      color: $gold-darker;
    }

    .message-icon-success {
      color: $wcra-green;
    }

    .message-text {
      font-size: 14px;
      font-weight: 300;
      padding-left: 10px;
      color: $black;
    }
  }

  .dismiss {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
  }

  &.success {
    color: $wcra-green;
    background-color: rgb(16, 172, 132, 0.1);
    border: 1px solid $wcra-green;
    border-radius: 5px;
  }

  &.fail {
    color: $red;
    background-color: #fceeee;
    border: 1px solid $red;
    border-radius: 5px;
  }

  &.warn {
    color: $gold-darker;
    background-color: rgb(255, 196, 4, 0.1);
    border: 1px solid $gold-darker;
    border-radius: 5px;
  }
}
