.entries-table-cell-status {
  .container {
    display: flex;
    align-items: center;

    .unpaid,
    .pending {
      padding-right: 0.375rem;
      display: flex;
      align-items: center;

      .warning-icon {
        fill: $unavailable-red;
        stroke: white;
      }

      .alert-icon{
        fill: $selected-orange;
        stroke: white;
      }
    }
  }
}
