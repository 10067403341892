.associations-promoters {
  .main-page {
    .table-cell-filter > div {
      width: 100%;
    }
    .header-container {
      display: flex;
      margin-bottom: 24px;
      justify-content: space-between;
      align-items: center;

      .button-container {
        display: flex;
        align-items: center;
        gap: 20px;

        .export{
          background-color: $wcra-dark-blue;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
  .form {
    padding-top: 20px;
    .export-entries-btn{
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
    }

    .container {
      margin-bottom: 24px;
      padding: 24px;
    }
    .details-container {
      margin-top: 24px;
    }

    .sub-title {
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .radios{
      flex-direction: row;
    }

    .alliance-container {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;

      .form-label {
        transform: none;
        transform-origin: unset;
        height: auto;
        margin: 0;
        margin-right: 10px;
        transition: none;
        font-size: 13px;
      }
    }

    .association-logo {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row;
    }

    .button-container {
      margin-top: 24px;
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      align-items: center;
    }

    .btn-upload-event-image {
      margin-left: 0 !important; // base has important
    }

    .section {
      margin-bottom: 24px;
    }

    .form-helper {
      color: #f44336;
    }

    .checkbox-color {
       color: #10ac84 !important; //base has important
    }

    .outlined {
      height: 81px;

      input {
        padding-top: 60px;
        padding-left: 8px;
      }
    }

    .select-checkboxes-container {
      .react-select__control {
        font-size: 16px;
        height: 81px;
        font-weight: bold;
        background-color: $blue-light;
      }
    }

    .association-membership-details {
      .btn-upload-event-image {
        background-color: $wcra-dark-blue;
      }
    }
  }

  .gold-chip-container {
    width: 86px;
    height: 31px;
    background: linear-gradient(98.94deg, #ECC335 8.54%, #E0A630 93.47%);
    border-radius: 22px;

  }

  .silver-chip-container {
    width: 86px;
    height: 31px;
    background: linear-gradient(97.52deg, #F8F8F8 0%, #D0D0CE 106.59%);
    border-radius: 22px;

  }

  .bronze-chip-container {
    width: 86px;
    height: 31px;
    background: linear-gradient(97.52deg, #E3C9A6 0%, #A78E6F 106.59%);
    border-radius: 22px;
  }

  .cell-center {
    text-align: center;
  }
  .associations-promoters-event-list{
    padding-top: 20px;
    .export-entries-btn{
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
    }
    thead{
      td{
        & > span {
          white-space: nowrap;
        }
      }
    }

  }
  .associations-promoters-tabs{
    button {
      max-width: inherit;
    }
  }
}
