.export-entries-dialog {
    width: 600px;
  
    h3 {
      margin-bottom: 0px;
      margin-top: 0px;
      padding-left: 23px;
    }
  
    .notification-container.success {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
    }
  }

.export-entries-title {
    padding-bottom: 10px !important;
}

.export-entries-header {
    text-transform: uppercase;
    font-weight: bold !important;

}

.export-entries-instructions {
    padding: 0px 24px 24px;
    margin-top: 0px;
    font-weight: lighter;
}

.export-entries-actions-container {
    padding-top: 36px;
}

.export-entries-content-container {
    overflow: visible !important;
}

.export-entries-paper {
    overflow: visible !important;
}
