.wcra-logo {
  width: 140px;

  &.home-size {
    width: 271px;
  }
}

.normal-align {
  margin-left: 10px !important;
}

.back-button {
  color: $green!important;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 19px;
  margin-top: 20px;
  border: none;
  text-transform: uppercase;
  background: none;
  margin-left: 15px;
  margin-bottom: 0;

  .arrow-left {
    height: 18px;
    vertical-align: middle;
    margin-bottom: 4px;
  }
}

.align-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.password-icon {
  &.edit-athlete {
    position: absolute;
    right: 0.8em;
    cursor: pointer;
  }
}

.paper-main-content-bg {
  margin-top: 25px;
  background: transparent !important;
}

.form-input-control {
  label {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.form-label-bold {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 8px;
  margin-left: 10px;
}

.form-input-uppercase {
  text-transform: uppercase;

  input {
    text-transform: uppercase;
  }
}

.bold {
  font-weight: bold !important;
}

.address-field,
div.Select.address-field div.Select-control div.Select-value span.Select-value-label{
  color: rgba(0,0,0,0.8) !important;
  font-family: 'IBM Plex Sans' !important;
  font-size: 16px !important;
}
.filter-button {
  background-color: transparent;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-align: inherit;
  color: inherit;
  text-transform: inherit;
  line-height: inherit;
  padding: inherit;
  margin: inherit;
  opacity: inherit;
}

.select-contestant {
  margin-top: 45px;
}
.color-gold {
  color: $gold !important;
}

.color-grey {
  color: $gray !important;
}

.marLeftN-1 {
  margin-left: -1em !important;
}

.marLeft-1 {
  margin-left: 0.5em !important;
}

.marLeft-2 {
  margin-left: 0.5em !important;
}

.marLeft-20 {
  margin-left: 20px !important;
}

.marLeft-50 {
  margin-left: 50px !important;
}

.marRight-60 {
  margin-right: 60px;
}

.marRight-1 {
  margin-right: 0.5em !important;
}

.marRight-2 {
  margin-right: 1em !important;
}

.marLeft-auto {
  margin-left: auto !important;
}

.marTop-0 {
  margin-top: 0px !important;
}

.marTop-5 {
  margin-top: 5px !important;
}

.marTop-15 {
  margin-top: 15px !important;
}

.marTop-20 {
  margin-top: 20px !important;
}

.padBot-0 {
  padding-bottom: 0px !important;
}
.padBot-1 {
  padding-bottom: 1em;
}
.padBot-2 {
  padding-bottom: 2em;
}
.padTop-0 {
  padding-top: 0px !important;
}
.padTop-1 {
  padding-top: 1em;
}
.padTop-2 {
  padding-top: 2em;
}
.padLeft-1 {
  padding-left: 1em;
}
.padLeft-0 {
  padding-left: 0px !important;
}

.padRight-1 {
  padding-right: 1em;
}

.borderBot-0 {
  border-bottom: 0;
}

.color-green {
  color: $green !important;
}

.color-black {
  color: $wcra-black !important;
}

.weak {
  font-weight: lighter !important;
}

.paper-section-title {
  text-transform: uppercase;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
}

.paper-section-sub-title {
  display: flex;
  text-transform: uppercase;
  font-size: 1.125rem !important;
  font-family: $secondary-font !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
  align-content: center;
}

.entry-card-sub-section-title {
  font-size: 1.125rem !important;
  text-transform: uppercase;
  font-family: $secondary-font !important;
  margin-bottom: 0.9375rem !important;
  letter-spacing: 0.049375rem !important;
  line-height: 1.5625rem !important;
  color: $wcra-black !important;
}
.entry-card-sub-section-title-spacing {
  padding-top: 55px;
  padding-bottom: 15px;
}
.entry-card-country-grid-item {
  padding-left: 25px !important;
}

.filled-input {
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: $blue-light !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 27px 0 0 !important;

  &.no-padding {
    padding: 3px 0 0 !important;
  }

  &.no-padding-select {
    padding: 10px 0 0 !important;
  }

  .form-label {
    margin-top: 8px;
    margin-left: 10px;
  }

  input {
    padding: 10px;
  }

  textarea {
    width: 100%;
    padding: 10px;

    &::placeholder  {
      line-height: 145px;
    }
  }

  .Select {
    padding-top: 12px !important;

    .Select-control {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      .Select-value,
      .Select-placeholder,
      .Select-multi-value-wrapper {
        padding-left: 10px !important;
        margin-bottom: 4px !important;
      }
    }
  }

  .custom-select {
    .form-label {
      position: absolute;
      margin-top: -42px;
      font-size: 0.75rem;
      z-index: 1;
    }
  }

  .filled-start-adornment {
    display: inline-block;
    margin-top: -22px;
    margin-left: 5px;
  }

  &.margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  .disabled {
    background: white;
  }
}

.filled-input-narrow,
.filled-input-small {
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: $blue-light;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px 0 0 !important;
  margin-top: 0px !important;

  &.no-padding {
    padding: 3px 0 0 !important;
  }

  &.no-padding-select {
    padding: 10px 0 0 !important;
  }

  .form-label {
    margin-top: 8px;
    margin-left: 10px;
  }

  input {
    padding: 11px;
    height: 36px;
  }

  .Select {
    padding-top: 12px !important;

    .Select-control {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      .Select-value,
      .Select-placeholder,
      .Select-multi-value-wrapper {
        padding-left: 10px !important;
        margin-bottom: 4px !important;
      }
    }
  }

  .filled-start-adornment {
    display: inline-block;
    margin-top: -22px;
    margin-left: 5px;
  }

  &.margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  &.margin-bottom-10 {
    margin-bottom: 10px !important;
  }
}

.filled-input-narrow {
  input {
    height: 41px;
    padding: 22px 10px 0 10px;
  }
}

.form-helper {
  margin-top: 0 !important;
  padding-top: 8px !important;
  background: $white !important;
}

.form-counter {
  position: absolute;
  right: 5px;
  bottom: 10px;
  color: $gray;
}

.filled-input-height {
  margin-top: 50px !important;
}

.toggle-wrp {
  margin-top: 15px !important;
}

.toggle-form-control {
  padding: 27px 0 0 !important;

  .form-label {
    margin-top: 0px;
    margin-left: 0px;
  }
}

.tab-disabled {
  color: #555 !important;
  color: #e2e2e2 !important;
}

.wcra-green-cta {
  background-color: $wcra-green !important;
  color: $white !important;
  position: relative;

  &:disabled {
    opacity: 0.3;
  }
}

.wcra-danger-cta {
  background-color: $unavailable-red !important;
  color: $white !important;
  position: relative;

  &:disabled {
    opacity: 0.5;
  }
}

.button-indicator {
  position: absolute;
  background-color: $white;
  width: 15px;
  height: 15px;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  right: -5px;
  top: -5px;
  font-size: 10px;
  box-shadow: 2px 2px 7px -3px rgba(0, 0, 0, 0.75);
}

.wcra-default-cta {
  background-color: transparent !important;
  color: $black !important;
  border-color: $black !important;

  &:disabled {
    opacity: 0.3;
  }
}

.reride-button {
  width: 115px;
}

.wcra-dark-blue-cta {
  background-color: $wcra-dark-blue !important;
  color: $white !important;

  &:disabled {
    opacity: 0.3;
  }
}

.wcra-unavailable-red-cta {
  border-color: $unavailable-red !important;
  color: $unavailable-red !important;
}

.wcra-muted-grey-cta {
  border-color: $muted-gray !important;
  color: $muted-gray !important;
}

.white-text-button {
  color: $white !important;
  text-decoration: underline !important;

  &:hover {
    background-color: unset !important;
  }
}

.wcra-white-blue-cta {
  background-color: $white !important;
  border: 1px solid $light-gray-button !important;
  box-shadow: unset !important;

  &:hover {
    background: $wcra-gray !important;
  }
  &:disabled {
    background: $wcra-gray !important;
    cursor: default;
    opacity: 0.5;
  }
}

.main-new-nomination, .main-nomination-refund  {
    margin-top: 50px;
    .back-button {
      padding-bottom: 20px;
      padding-left: 2px;
    }
}

.back-button {
  color: $wcra-blue;
  text-transform: uppercase;

  a {
    font-size: 1.2rem;
    text-decoration: none;
    color: $wcra-blue;
  }
}

.checkbox-color {
  color: #bcc5ce !important;
  &.blue {
    color: $wcra-blue !important;
  }
  &.green{
    color: $wcra-green !important;
  }

  &.no-padding {
    padding: 0px !important;
  }
}

.light-gray-button {
  background-color: $light-gray-button !important;
  color: #ffffff !important;
  &.disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.menu-icon-color {
  color: $light-gray !important;
}

.divider-menu-color {
  background: $light-gray !important;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hidden {
  padding: 0 !important;
  visibility: hidden;
}

.pos-relative {
  position: relative;
}

.full-name-search {
  position: relative;

  div:first-child {
    position: absolute;
    left: 20px;
    top: 12px;
    right: -100px;
  }
}

.extra-wide-filter {
  div:nth-child(1) {
    width: 13em;
  }
}

.button-dark {
  line-height: 16px;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: $white;
  background-color: $dark;

  &:hover {
    background-color: $wcra-black;
    cursor: pointer;
  }

  &:disabled {
    background-color: $gray;

    &:hover {
      background-color: $gray;
      cursor: auto;
    }
  }
}

.button-light {
  line-height: 16px;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: $dark;
  background-color: $wcra-gray;
  border-style: solid;

  &:hover {
    background-color: $light-gray;
    cursor: pointer;
  }

  &:disabled {
    background-color: $gray;

    &:hover {
      background-color: $gray;
      cursor: auto;
    }
  }
}

.full-width {
  width: 100%;
}

.medium-width {
  min-width: 160px;
}

.text-center {
  text-align: center;
}

.paper-section-subtitle {
  font-size: 20px !important;
  margin-top: 15px !important;
  margin-bottom: 3px !important;
  text-transform: uppercase;
}

.cloud-icon {
  margin-bottom: -6px;
  margin-left: 8px;
}

.table-cell-filter {
  label {
    top: -12px;
    line-height: 1.1;
  }
  padding-left: 0 !important;
}

.table-data-filters {
  .table-cell-filter > div {
    width: 100%;
  }
}

.modal-with-select-box {
  div:nth-child(2) {
    div:first-child {
      overflow-y: visible;
    }
  }

  .modal-with-select-box-content {
    overflow-y: visible;
  }
}

.info-icon {
  color: $black;
  margin: -5px 0 -5px 5px;
}

.team-shared-label,
.team-discipline {
  position: absolute;
}

.team-shared-label.team-discipline {
  margin-top: 0;
  padding-top: 7px;
}

.team-shared-label {
  margin-top: 0px;
}

.team-shared-label.nomination-description {
  margin-top: 0px;
  display: flex;
}

.team-shared-label {

  .team-elegible-nom {
    display: inline-block;
    line-height: 2em;

    @media (max-width: 1699px) {
      width: 81px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.alliance-partner-icon {
  color: $green;
}

.entries-additional-options {
  text-transform: uppercase;
}

.options-menu {
  ul {
    padding-bottom: 0;
    padding-top: 0;

    li {
      width: 154px;
      height: 25px;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.704px;
      text-transform: capitalize;
      color: $black;

      &:not(:hover){
        background-color: white;
      }
    }
  }
  div:nth-child(2) {
    box-shadow: 0px 2px 5px rgb(17 24 31 / 30%);
    border-radius: 3px;
  }
}

// For addazle grid
.filter-cell {
  line-height: 35px;
  padding: 0;

  > div {
    padding-block: 0;
    padding-inline: 8px;

    &:first-child {
      border-block-end: 1px solid var(--rdg-border-color);
    }
  }
}

.filter-input {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
}

.data-grid-parent {
  > .rdg {
    flex: 1;
    max-height: 800px;
  }
}

// Needed when using a react-select inside a modal
.modal-overflow-visible {
  > div > div {
    overflow-y: visible;
  }
}

.highlighted-row {
  background-color: #e6e6e6;
  td {
    color: black;
    font-weight: 900;
  }
}

.scrollable-data-grid-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  block-size: 65vh;
  padding: 8px;
  contain: inline-size;
  overflow: auto;
}

.fill-grid {
  block-size: 100%;
}