.entry-add-partner-modal {
  overflow: visible;

  .title {
    padding: 1.75rem 2rem 0.6875rem;
    h6 {
      font-size: 1.375rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.5625rem;
    }
  }

  .description {
    font-size: 1rem !important;
    letter-spacing: 0.7px !important;
    line-height: 1.5625rem !important;
    font-weight: lighter !important;
    height: 1.5625rem !important;
    font-family: "IBM Plex Sans" !important;
  }

  .content {
    padding: 0 2rem 1.5rem !important;
    overflow: visible;
  }

  .hidden {
    visibility: hidden;
    display: none;
  }
  .partner-option-primary-line {
    text-transform: capitalize;
  }
  .Select-value-label {
    text-transform: capitalize;
  }
  .radio {
    margin-bottom: 1.5625rem;
  }

  .label {
    font-family: "IBM Plex Sans";
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0.79px;
    line-height: 1.4375rem;
    text-transform: uppercase;
  }

  .radio-content {
    margin-left: 2.125rem;
    margin-bottom: 2rem;
  }

  @mixin select {
    min-width: 400px;
    z-index: 2;
    margin-bottom: 1rem;

    .Select-control {
      height: 3.5rem;
      margin-top: 0 !important;

      .Select-placeholder {
        line-height: 3.5rem;
      }

      .Select-value {
        line-height: 3.5rem !important;
        font-size: 1rem;

        .Select-value-label {
          line-height: 3.5rem;
          font-size: 1rem;
        }
      }

      .Select-input {
        height: 3.5rem;

        input {
          height: 3.5rem;
          vertical-align: middle;
          padding: 0 !important;
        }
      }
    }

    .Select-menu-outer {
      overflow: visible;
    }

    .Select-option:hover {
      color: white
    }

    .Select-option {
      line-height: initial;

      &.is-selected {
        color: white
      }

      &.is-focused {
        color: white
      }
    }
  }

  .select-unmatched {
    @include select();

    z-index: 3;
    margin-bottom: 1rem;
  }

  .select-matched {
    .Select-option:first-child {
      color: $wcra-green;
      font-weight: bold;
    }

    @include select();
  }

  .actions {
    margin: 0 !important;
    padding-bottom: 1rem;
    padding-right: 1.375rem;

    .submit {
      margin-right: 0;
    }
  }

  .partner-form {
    display: flex;
    margin-top: 1rem;

    >*:last-child {
      margin-left: 1rem;
      width: auto;
    }
  }

  .partner-athlete-option {
    display: 'flex';
    flex-direction: 'column';
    font-weight: normal;
    font-size: 1rem;

    .partner-option-secondary-line {
      font-weight: 100;
    }
  }
}
