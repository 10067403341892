.page-title {
  font-family: $secondary-font !important; //!important required to overwrite MUI style
  text-transform: uppercase;
  font-size: 3.3125rem !important;
  font-weight: 700 !important;
}

.page-subtitle {
  margin-top: 20px !important;
  margin-bottom: -15px !important;
  text-transform: uppercase;
  font-size: 2.3125rem !important;
  font-weight: 500 !important;
  color: #8394a7 !important;
}

.head-padding {
  padding: 23px 10px;
}