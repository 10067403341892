.table-classifications {
  .option-icon {
    fill: $gray;
  }
}
.add-classification-modal {
  width: 664px;
}

.edit-classification-title {
  h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.968px;
    text-transform: uppercase;
    color: $black;
  }
}

.classification-modal-content {
  label {
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.484px;
    text-transform: uppercase;
    color: #576574;
    font-weight: bold;
  }
}

.classification-table-cell-verified {
  svg {
    fill: $wcra-green;
    font-size: 26px;
  }
}
