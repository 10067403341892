.add-association-main-modal {
  .add-associations-modal {
    &-v2 {
      padding: 20px;
      min-width: 700px;

      .alliance-container {
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        .radios {
          padding-left: 7px;
          display: flex;
          flex-direction: row;
        }
      }
    }

    min-width: 600px;

    .subtitle {
      font-size: 15px;
      font-weight: normal;
      text-transform: none;
      letter-spacing: 0px;
      line-height: 0px;
      display: block;
      margin-top: 15px;
    }

    .add-new-association-modal-content {
      margin-top: 10px;

      .add-association-input {
        label {
          color: rgba(0, 0, 0, 0.54) !important;
        }
        .Select-control {
          margin-top: 12px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
        }
      }
    }

    .add-association-modal-content {
      background-color: $white;
      overflow-y: visible;
      div {
        color: $wcra-black;
      }

      &.no-padding {
        padding: 0px !important;
      }

      .event-dropdown-from-control {
        margin-top: 12px;
        display: block;
        padding-bottom: unset !important;

        label {
          z-index: 1;
          margin-left: 8px;
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 12px;
          letter-spacing: 0.484px;
          text-transform: uppercase;
          color: #576574;
        }

        .Select--single {
          .Select-control {
            // height: 60px;
            border: 1px solid #9D9D9D;

            .Select-multi-value-wrapper {
              padding-top: 20px;

              .Select-value {
                padding-top: 20px;
              }

              .Select-placeholder {
                padding-top: 20px;
                padding-left: 16px;
                color: rgba(0, 0, 0, 0.54);
                // font-weight: 600;
                font-size: 16px !important;
                // line-height: 25px;
              }
            }

            .Select-arrow-zone {
              padding-top: 20px;
            }
          }
        }
      }
    }
  }
}

.association-list {
  text-transform: uppercase;


  .cell-result-level{
    min-width: 130px;
  }

  &.in-modal {
    margin-top: 15px;
  }

  thead, tbody {
    tr {
      height: 40px;

      th {
        color: $black;
        font-weight: bold;
        padding: 15px 15px 4px 10px !important;
      }

      td {
        padding: 4px 15px 4px 10px !important;

        .alliance-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: $wcra-green;
          color: $white;
          font-weight: bold;
          height: 25px;
          width: 25px;
          border-radius: 5px;
          margin-left: 25%;
        }
      }
    }
  }
}
