.rodeo-logistics-logo {
  margin: 0 40px;
  &.rodeo-logistics-logo-large {
    width: 100%;
  }
  &.rodeo-logistics-logo-small {
    width: 150px;
  }
}
.splash-screen {

  background-color: #fff;
  display: none;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
  align-content: stretch;

  -webkit-transition: $transition_speed_long;
  -moz-transition: $transition_speed_long;
  -o-transition: $transition_speed_long;
  transition: $transition_speed_long;
  opacity: 0;
  visibility: hidden;

  &.show {
    z-index: 9999;
    display: flex;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0;
    bottom: 0;
    opacity: 1;
    visibility: visible; 
  }
}
