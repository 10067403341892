.modal-section-title {
  font-size: 1.2em;
  text-transform: uppercase;
}

.modal-section-subtitle {
  text-transform: uppercase;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
}

.event-creation-type {
  flex-direction: row !important;
  column-gap: 24px;
  padding-bottom: 14px;
}

.modal-subsection-title {
  font-size: 0.9em;
  font-weight: 100;
  text-transform: uppercase;
}

.saved-cards-section {
  color: $gray;

  .card-info-wrp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
  }

  .card-description {
    padding-left: 10px;
    color: #333;
    display: inline-block;
    width: 87%;
  }

  .brand-card {
    margin-left: 0;
    width: 50px;
    height: 50px;
    display: inline-block;

    &.mastercard {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/cards/MasterCard.png');
    }

    &.americanexpress {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/cards/AmericanExpress.png');
    }

    &.dinersclub {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/cards/DinersClub.png');
    }

    &.discover {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/cards/Discover.png');
    }

    &.jcb {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/cards/JCB.png');
    }

    &.unionpay {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/cards/UnionPay.png');
    }

    &.visa {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/img/cards/Visa.png');
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.action-buttons {
  display: flex;
  align-items: center;

  .link-refund {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ebebeb;
    background-color: #ebebeb;
    border-radius: 30px;
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;

    .refund-icon {
      padding: 6px;
      border-radius: 50px;
      background-color: white;
    }

    .refund-label {
      margin-left: 10px;
      margin-right: 20px;
      color: #000000;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
    }
  }

  .deleted {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ebebeb;
    background: #F8CECE;
    border-radius: 22px;
    width: 79px;
    height: 28px;
    cursor: none;

    .label {
      color: #000000;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
    }
  }
}

.remove-entry-modal {
  div:nth-child(2) {
   div:first-child {
     overflow-y: visible;
   }
 }
  .title {
    font-size: 1.375rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .filled-default-input {
   padding-top: 7px;

    .Select-control {
     border-bottom-color: rgba(0, 0, 0, 0.42)
   }
 }
}
