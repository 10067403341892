.chip-popover-container{
  .chips-container{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
    padding: 18px;
    flex-direction: column;
    display: flex;
    align-items: center;
    gap: 10px;
  }

}

