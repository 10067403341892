.text-message-dialog {
  width: 600px;

  h3 {
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 23px;
  }

  .notification-container.success {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}
.character-count {
  margin-top: 12px;
  float: right;
}
.message-confirm {
  text-align: right;
  margin-right: 4px;
  padding-bottom: 10px;
  padding-top: 40px;
}
.text-submit-test {
  justify-content: flex-start !important;
  margin-left: -5px !important;

  button {
    border: 1px solid !important;
    margin-top: 14px !important;
  }
  button:disabled, button[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border: none !important;
  }
}
