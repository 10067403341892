.dashboard {
  .segment-days-container {
    display: flex;

    .segment-days-left {
      background: $medium-gray;
      padding: 20px 25px;
      color: white;
      font-size: 0.9em;
      font-weight: normal;
      border-radius: 5px;
    }
  }

  .paper-bg-white {
    background-color: $white !important;
  }

  .dashboard-search {
    margin-top: -15px;

    .dashboard-input-field {
      margin-top: 0px;
    }

    .dashboard-bt-run-search {
      margin-left: auto;
      margin-right: 25px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        padding-left: 35px;
        padding-right: 35px;
      }
    }

    .dashboard-input-select {
      .Select-control {
        border-bottom-color: rgba(0, 0, 0, 0.42);
        &:hover {
          border-bottom: 1px solid rgba(0, 0, 0, 0.87);
        }
      }
    }
  }

  .dashboard-card {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    background-color: $white !important;

    .dashboard-card-info {
      display: flex;
      flex-direction: column;
      &.small{
        position: relative;
        height: 100%;
      }

      .dashboard-card-title {
        font-size: 0.8rem !important;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: -25px;
        margin-top: 10px;
        &.small{
          position: absolute;
          top: 10px;
        }
        text-align: center;
      }

      .dashboard-card-number-container{
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .dashboard-card-number {
        font-size: 4.3125rem !important;
        font-weight: bold;
        color: $wcra-blue;
        &.small{
          font-size: 1rem !important;
        }
      }
    }
  }

  .dashboard-report-card {
    height: auto;
    padding: 0px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    .paper-top-tabs {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    .report-tab-panel {
      width: 100%;
    }

    .tableau-embed {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 24px;

      h3 {
        align-self: flex-start;
      }
    }
  }
}

.dashboard-modal {
  .modal-title {
    padding: 12px 24px 12px;
    background-color: $white;

    h6 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem
    }
    button {
      margin-left: 8px;
    }
  }

  .modal-content {
    padding: 0;

    th, td {
      padding-right: 24px;
    }
    td {
      font-size: 1rem;
      text-transform: uppercase;
    }
  }

  .athlete-image {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
}
