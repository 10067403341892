.entries-table-cell-discipline {
  .partner {
    color: $gray-text;
    font-family: $primary-font;
    font-size: 0.8125rem;
    font-weight: 500;

    a {
      color: $wcra-blue;
      text-decoration: none;
    }
  }
}