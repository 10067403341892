.entry-athlete-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .back-button {
    display: flex;
    text-decoration: none;
    align-self: center;
  }

  div {
    display: flex;
    font-size: 15px;
    padding-top: 3px;

    &:focus {
      outline: none;
    }
  }

  svg {
    height: 22px;
  }

  h2 {
    padding-left: 30px;
    text-transform: uppercase;
  }

  *:nth-child(3) {
    margin-left: auto;
    margin-right: 30px;
  }
}

.entry-pending-header {
  display: flex;
  background-color: $unavailable-red;
  justify-content: space-between;
  color: $white;
  padding: 12px 24px;
  height: 3.125rem;

  *:nth-child(1) {
    display: flex;
    align-items: center;

    .warning-icon {
      margin-right: 8px;
    }

    .sub-text {
      font-weight: 300;
      padding-left: 8px;
    }
  }

  &-margin-top {
    margin-top: 1rem;
  };
}

.athlete-entry-form {
  .entry-cta-buttons-container {
    margin-top: 25px;
    text-align: right;

    button {
      width: 100px;
      font-family: $secondary-font;
      font-size: 16px;
      font-weight: 300;
    }

    .cancel-button-cta,
    .save-button-cta {
      color: $white;
    }

    .cancel-button-cta {
      background-color: $wcra-dark-blue;
      &:disabled {
        background-color: rgba(34,48,62,0.5);
      }
    }

    .save-button-cta{
      &:disabled {
        background-color: rgba(16,172,132,0.5);
      }
    }
  }

  .preferences-item {
    .filled-input {
      margin-bottom: 22px;
    }
  }
}
.bulk-container {
  .bulk-profile-merge {
    margin-top: 20px;

    .data-grid-parent {
      display: flex;
      flex-direction: column;
      block-size: 100%;
      gap: 10px;

      .rdg {
        max-height: 70vh;
      }
    }

    .good {
      .common-columns {
        border-bottom: none;
      }

      .checkbox-column {
        border-bottom: none;
      }

      .rdg-cell {
        border-top: 1px solid #000
      }
    }

    .no-good {
      .rdg-checkbox-label {
        display: none;
      }
    }
  }
  .bulk-profile-merge-actions{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
}
