.merge-events-modal-body{
  margin: 0 24px 24px 24px;
  .merge-events-review-nominations-item,.nomination-container{
    margin-top: 16px;
    &:first-child{
      margin-top: 0px;
    }
  }
  .sub-title{
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
    min-width: 500px;
  }
  .info-container{
    display: flex;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: 'Barlow';
    font-style: normal;
    gap: 3px;
    .label{
      font-weight: bold;
    }
    .value{
      font-weight: normal;
    }
  }
  .nomination-class{
    margin-bottom: 19px;
    margin-top: 14px;
  }
  .class-warning{
    padding-bottom: 16px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    max-width: 500px;
  }
}

.merge-modal-actions{
  display: flex;
  justify-content: flex-end;
  .btn-actions{
    margin: 0px 20px 16px 20px;
    display: flex;
    align-content: center;
  }
}

.modal-title{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .go-back-container{
     display: flex;
     gap: 12px;
     margin-bottom: 16px;
     align-items: center;
    .go-back-text{
      color: $wcra-green;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
    .go-back-icon{
      color: $wcra-green;
    }
  }
}
.merge-events-no-entries-nominations{
  .modal-title {
    font-size: 20px;
    margin-bottom: 14px;
  }
  .good-event-container{
    margin-top: 14px;
  }
}

