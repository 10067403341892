.changes-not-saved-modal {
  font-style: normal;

  .changes-card-modal {
    border-radius: 5px;

    #modal-discard-btn,
    #modal-cancel-btn,
    #form-dialog-content {
      font-family: $secondary-font;
    }

    #form-dialog-title {
      padding-bottom: 0;

      h6 {
        font-family: $secondary-font;
        font-size: 22px;
        letter-spacing: 0.97px;
        font-weight: 700;
      }
      margin-bottom: 6px;
      letter-spacing: 0.97px;
    }

    #modal-discard-btn,
    #modal-cancel-btn {
      font-size: 16px;
      width: 100px;
      font-weight: 300;
    }


    .modal-section-content {
      font-size: 16px;
      letter-spacing: 0.7px;
      text-transform: initial;
      margin: 0 25px 20px;
      font-weight: 300;
    }

    .wcra-danger-cta {
      width: 84px;
      margin-left: 20px;
    }
  }
}