.Select,
.Select.is_focused,
.Select.is_open {
  &:hover {
    .Select-placeholder,
    .Select-value {
      cursor: pointer;
    }
  }

  .Select-control {
    background: #f2f4f7 !important;
    border-radius: 0;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: 1px #ddd solid;
    margin-top: 5px !important;

    .Select-input {
      input {
        color: #000;
      }
      &:focus {
        background: transparent !important;
      }
    }
    .Select-value,
    .Select-value-label {
      color: $black !important;
      padding-left: 8px;
    }
  }
  .Select-placeholder,
  .Select-value {
    color: $gray;
    font-size: 20px;
    padding-left: 8px;
  }
  .Select-arrow-zone .Select-arrow,
  .Select-arrow {
    border-color: $green transparent transparent;

    &:hover {
      border-top-color: $green;
    }
  }
  .Select-menu {
    max-height: 315px;
  }
  .Select-menu-outer {
    background: $blue-light;
    border: transparent;
    font-size: 14px;
    max-height: 315px;
    overflow-y: auto;
    z-index: 2;
  }
  .Select-option {
    background: $blue-light;
    color: $black;
    padding: 15px;

    &:hover {
      background: $green;
    }

    &.is-open,
    &.is-focused {
      background: $green;

      &:hover {
        background: $green;
      }
    }
    &.is-selected {
      background: $green;

      &:hover {
        background: $green;
      }
    }
  }
  &.has-value.Select--single {
    .Select-control .Select-value {
      padding-left: 0px;

      .Select-value-label {
        color: $black;
      }
    }
  }
  &.is-focused:not(.is-open) > .Select-control {
    border-color: transparent;
  }
}

.Select.is-disabled {
  .Select-control {
    background: transparent !important;
    opacity: 0.8;
  }
  .Select-placeholder {
    cursor: default !important;
  }
  .Select-value-label {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

.Select--multi .Select-value {
  margin: 0 5px 0 0 !important;
  font-size: 1em !important;
  border: none !important;
  background: $green !important;
}

.Select-value-icon:hover {
  color: $black !important;
}

.inline-select-box {
  display: inline-block;
  width: 100%;
}

.select-with-add-button {
  .add-option-button {
    color: $wcra-green !important;
    font-weight: bold;
    &.is-focused {
      background-color: $white !important;
    }
  }

  .add-new-contestant {
    color: #10ac84;
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 24px;
    min-height: 24px;
    padding: 18px 0;
  }

  .Select-menu-outer {
    -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.59);
    -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.59);
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.59);
    .Select-option {
      color: $wcra-black;
      background-color: $white;
      &.is-focused {
        background-color: $green;
      }
    }
  }
}
