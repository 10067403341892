.title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.input-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

%gift-card-box {
  width: 74px;
  height: 74px;
  margin-right: 1.5em;
  border-radius: 6px;
}

.gift-card-box-1 {
  @extend %gift-card-box;
  left: 452px;
  background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
}

.gift-card-box-2 {
  @extend %gift-card-box;
  left: 548px;
  background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
}

.gift-card-box-3 {
  @extend %gift-card-box;
  left: 644px;
  background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
}

.gift-card-box-4 {
  @extend %gift-card-box;
  left: 740px;
  background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
}

.gift-card-box-5 {
  @extend %gift-card-box;
  left: 836px;
  background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
}

.gift-card-box-6 {
  @extend %gift-card-box;
  left: 836px;
  background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
}

.gift-card-box-7 {
  @extend %gift-card-box;
  left: 836px;
  background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
}

.gift-card-box-8 {
  @extend %gift-card-box;
  left: 836px;
  background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
}

.gift-card-box-9 {
  @extend %gift-card-box;
  left: 836px;
  background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
}

.gift-card-box-10 {
  @extend %gift-card-box;
  left: 836px;
  background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
}

.line {
  border: 0.1px solid #9d9d9d;
}

.gift-card-border {
  border: 2px solid #10ac84;
  border-radius: 6px;
}

.box-svg-wrapper {
  position: relative;
  margin-top: 10px;
  .check-icon {
    position: absolute;
    top: -10px;
    right: 10px;
  }
}

.balance-text {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}

.delete-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 10px;
  width: 82px;
  height: 36px;
  border-style: none;
  margin-left: 10px;
  font-weight: 800;
  background: #ee5253;
  box-shadow: 0px 2px 5px rgba(17, 24, 31, 0.3);
  border-radius: 3px;
}

.delete-button-text {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.delete-dialoge-title {
  width: 552px;
  height: 16px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.484px;
  color: #000000;
}

.gift-card-field-wrapper {
  margin-top: 8px;
  margin: 5px;
}


.gift-preview {
  width: 1440px;

  .gift-preview-item {
    margin-top: 10px;
    margin-bottom: 10px;

    span {
      margin: 5px;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 700;
      line-height: 12px;
      text-align: left;
    }

    .value {
      font-weight: normal;
      text-transform: uppercase;
    }

    p {
      font-family: Barlow;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      text-align: justify;
      margin: 5px;
      text-transform: capitalize;
      width: 550px;
      word-wrap: break-word;
    }
  }
}

.gift-card-dialog-title {
  display: inline-block;
  width: 78%;
  margin-left: 26px;
}

.gift-card-add-balance-dialog-title {
  display: inline-block;
  width: 78%;
  margin-left: 26px;
  padding-top: 18px;
  font-family: Barlow;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}

.gift-card-cross-icon {
  display: inline-block;
  width: 7%;
  cursor: pointer;
  margin-top: 8px;
}

.add-balance-action-content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    position: inherit;
    margin-bottom: 20px;
    margin-left: 10px;

    &:last-of-type {
      margin-right: 30px;
    }
  }
}

.gift-card-dialog-wrapper {
  position: relative;
  margin-top: 10px;

  .gift-card-box-icon {
    position: absolute;
    top: 19px;
    right: 162px;
    opacity: 0.3;
  }

  .gift-card-cart-icon {
    position: absolute;
    top: 316px;
    right: 30px;
  }

  .gift-card-vrq-icon {
    position: absolute;
    top: 22.32px;
    right: 22px;
  }

  .gift-card-name {
    position: absolute;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 140px;
    right: 132px;
    left: 24px;
    width: 90%;
    height: 69px;
    max-height: 62px;
    font-weight: 700;
    font-size: 26px;
    color: #ffffff;
    line-height: 30px;
  }

  .gift-card-balance {
    position: absolute;
    top: 230px;
    right: 62px;
    left: 24px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .gift-card-cost {
    position: absolute;
    top: 318px;
    left: 24px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .line-separator {
    position: absolute;
    border: 1px #ffffff;
    top: 300px;
    right: 166px;
  }

  %gift-card-rectangle {
    box-sizing: border-box;
    width: 285px;
    height: 364px;
    border-radius: 14px;
  }

  .gift-card-rectangle-box1 {
    @extend %gift-card-rectangle;
    background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
  }
  .gift-card-rectangle-box2 {
    @extend %gift-card-rectangle;
    background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
  }
  .gift-card-rectangle-box3 {
    @extend %gift-card-rectangle;
    background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
  }
  .gift-card-rectangle-box4 {
    @extend %gift-card-rectangle;
    background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
  }
  .gift-card-rectangle-box5 {
    @extend %gift-card-rectangle;
    background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
  }
  .gift-card-rectangle-box6 {
    @extend %gift-card-rectangle;
    background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
  }
  .gift-card-rectangle-box7 {
    @extend %gift-card-rectangle;
    background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
  }

  .gift-card-rectangle-box8 {
    @extend %gift-card-rectangle;
    background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
  }

  .gift-card-rectangle-box9 {
    @extend %gift-card-rectangle;
    background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
  }

  .gift-card-rectangle-box10 {
    @extend %gift-card-rectangle;
    background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
  }
}

.gift-card-private-dialog-wrapper {
  position: relative;
  height: 530px;
  margin-top: 10px;

  .gift-card-private-box-icon {
    position: absolute;
    top: 19px;
    right: 162px;
    opacity: 0.3;
  }

  .gift-card-private-vrq-icon {
    position: absolute;
    top: 22.32px;
    right: 22px;
  }

  .gift-card-private-name {
    position: absolute;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 156px;
    right: 132px;
    left: 24px;
    width: 249px;
    height: 69px;
    max-height: 53px;
    font-weight: 700;
    font-size: 26px;
    line-height: 25px;
    color: #ffffff;
  }

  .gift-card-private-balance {
    position: absolute;
    top: 210px;
    right: 62px;
    left: 24px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
  }

  %gift-card-private-dialog-rectangle {
    box-sizing: border-box;
    width: 285px;
    height: 241px;
    border-radius: 14px;
    margin-bottom: -8px;
  }

  .gift-card-private-rectangle-box1 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
  }
  .gift-card-private-rectangle-box2 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
  }
  .gift-card-private-rectangle-box3 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
  }
  .gift-card-private-rectangle-box4 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
  }
  .gift-card-private-rectangle-box5 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
  }

  .gift-card-private-rectangle-box6 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
  }
  .gift-card-private-rectangle-box7 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
  }

  .gift-card-private-rectangle-box8 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
  }

  .gift-card-private-rectangle-box9 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
  }

  .gift-card-private-rectangle-box10 {
    @extend %gift-card-private-dialog-rectangle;
    background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
  }
}

.gift-card-add-balance-dialog-wrapper {
  position: relative;
  width: 602px;
  height: 307px;
  margin-top: 10px;

  hr {
    width: 555px;
    margin-left: 0;
    margin-top: 0px;
    margin-bottom: 16px;

    &:first-of-type {
      margin-top: 10px;
    }
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }

  p {
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.70px;
    text-align: left;
    color: #A2A2A2;
  }

  .prop-and-value-content {
    display: grid;
    padding-left: 0;
    padding-top: 5px;

    .reason-control { 
      width: 550px;

      .addorment {
        margin-top: 22px;
        margin-left: 22px;
        color: #A2A2A2;
      }
  
      div {
        &::before {
          border: none!important;
        }
        &::after {
          border: none;
        }
      }
    }

    .form-input-control {
      width: 520px;
    }

    .form-input-control label {
      font-family: Barlow;
      font-size: 20px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.48px;
      text-align: left; 
    }

    .prop {
      font-family: Barlow;
      font-size: 16px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.48px;
      text-align: left;
    }

    .value {
      padding-left: 10px;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
  }

  .prop-and-value-content-inline {
    display: inline-flex;
    padding-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;

    .square-color {
      margin-right: 12px;
    }
  
    .balance-control label {
      font-family: Barlow;
      font-size: 20px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.48px;
      text-align: left; 
      margin: 0;
      color: black;
    }

    .balance-control {
      width: 520px;

      input {
        margin-top: 20px;
      }

      div {
        &::before {
          border: none!important;
        }
        &::after {
          border: none;
        }
      }
    }

    .prop {
      font-family: Barlow;
      font-size: 16px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.48px;
      text-align: left; 
      margin-top: 7px;
    }

    .value {
      padding-left: 10px;
      font-family: Barlow;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      margin-top: 3px;
    }
  }

  %gift-card-add-balance-dialog-rectangle {
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    border-radius: 6px;
    margin-right: 20px;
  }

  .red {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
  }
  .navyBlue {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
  }
  .goldenPoppy {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
  }
  .lightSlateBlue {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
  }
  .ash {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
  }

  .christi {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
  }
  .lightSeaGreen {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
  }

  .mulberry {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
  }

  .goldDrop {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
  }

  .metallicGold {
    @extend %gift-card-add-balance-dialog-rectangle;
    background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
  }
}

.gift-card-scan-wrapper {
  background-color: #f6f6f6;
  height: 280px;
  position: relative;
  border-radius: 0px 0px 15px 15px;
  border: 1px solid #dddddd;

  .gift-card-scan-code-title {
    position: absolute;
    text-align: center;
    top: 70px;
    left: 55px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  .gift-card-accountNumber {
    position: absolute;
    text-align: center;
    top: 50px;
    left: 85px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;

    color: #000000;
  }
  .gift-card-pin {
    position: absolute;
    text-align: center;
    top: 120px;
    left: 80px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;

    color: #000000;
  }
  .gift-card-accountNumber-title {
    position: absolute;
    text-align: center;
    top: 30px;
    margin-left: -85px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  .gift-card-pin-title {
    position: absolute;
    text-align: center;
    top: 30px;
    left: 40px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
}

.download-button {
  top: -15px;
  left: 85px;
  right: 4px;
}

.cancel-button {
  top: -15px;
  left: 75px;
  right: 5px;
}

%gift-card-download-rectangle {
  box-sizing: border-box;
  width: 285px;
  height: 520px;
  border-radius: 14px;
  margin-bottom: -8px;
}

.gift-card-download-rectangle-box1 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
}
.gift-card-download-rectangle-box2 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
}
.gift-card-download-rectangle-box3 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
}
.gift-card-download-rectangle-box4 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
}
.gift-card-download-rectangle-box5 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
}

.gift-card-download-rectangle-box6 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
}

.gift-card-download-rectangle-box7 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
}

.gift-card-download-rectangle-box8 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
}

.gift-card-download-rectangle-box9 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
}

.gift-card-download-rectangle-box10 {
  @extend %gift-card-download-rectangle;
  background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
}

.download-dialog-wrapper {
  background: #ffffff;
  border-radius: 14px;
  width: 802px;
  height: 560px;
  position: absolute;
  left: -2500px;
}

.gift-card-download-name {
  position: absolute;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  top: 180px;
  right: 132px;
  left: 24px;
  width: 249px;
  height: 63px;
  max-height: 54px;
  font-weight: 700;
  font-size: 26px;
  line-height: 25px;
  color: #ffffff;
}

.gift-card-download-balance {
  position: absolute;
  top: 260px;
  right: 62px;
  left: 24px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}

.download-giftCard-no {
  position: absolute;
  text-align: center;
  top: 340px;
  right: 62px;
  left: 50px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700px;
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}

.download-giftCard-pin {
  position: absolute;
  text-align: center;
  top: 390px;
  right: 62px;
  left: 50px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700px;
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}

.download-gift-card-left-child {
  width: 40%;
  float: left;
  margin-top: -15px;
}

.download-gift-card-description {
  width: 60%;
  float: right;
  padding: 20px;
}

.gift-card-download-balance-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}

.gift-card-download-paragraph {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.terms-condition-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-top: 160px;
}
