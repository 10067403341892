.compressed-table {
  th {
    padding: 4px 10px 4px 10px !important;
  }
}
.default-font {
  font-size: 16px !important;
}
.highlighted {
  background: $selected-orange;
}
.result-loaded {
  background: $cream;
}
.result-audited {
  background: $light-gray-button;
}
.result-public {
  background: $light-cyan;
}
.table-listing-with-scroll {
  text-transform: uppercase;
  letter-spacing: 1px;
  background: $white;

  .performance-draw-content td p {
    color: $muted-gray;
  }
}
.table-listing {
  width: 100%;
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: $white;

  #rank {
    width: auto;
  }

  .cell-no-padding-right {
    padding-right: 0;
  }

  .cell-no-padding-right.borderBot-0 {
    td:nth-child(1) {
      border-bottom: 0;
    }
  }
  .column-padding-left {
    td {
      padding-left: 10px;
    }
  }
}
.fab-circle-btn {
  top: 95px;
  right: 40px;
  position: fixed !important;
}
.add-entry-nom-btn {
  margin-right: 160px !important;
  margin-top: 37px !important;
}
.table-cell-listing {
  padding: 15px 5px !important;

  &.table-pad-left {
    padding-left: 20px !important;
  }
}
.table-cell-content-disp-block {
  display: block !important;
}
a.select-all {
  color: $wcra-green;
  text-decoration: none;
  font-weight: bolder;
}
.label-search-filter {
  display: block;
  padding-left: 15px;
}
.dark-table-head {
  background-color: $dark;
  color: #ffffff !important;
  height: 73px !important;

  th,
  td {
    color: #ffffff;
    font-weight: 900;
  }

  .dark-head-row {
    height: 73px !important;
    color: #ffffff;
  }

  .dark-table-sort-link {
    color: #ffffff;
    &:hover {
      color: #777;
    }
  }
}
.align-column-left {
  flex-direction: row !important;
  text-align: left !important;
}
.align-column-right {
  flex-direction: row-reverse !important;
  text-align: right !important;
}
.align-column-center {
  text-align: center !important;
}
.column-padding-small {
  padding: 0 8px !important;
}
th.bump-left {
  padding-left: 2em !important;
}
