
.select-checkboxes-container  {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  .checkbox-color{
    padding: 0;
    padding-right: 7px;
  }
  .react-select{
    &__menu{
      width: 275px;
    }
    &__option{
     padding: 10px 15px;
      &.child{
        padding-left: 40px;
      }
    }
    &__control {
      border: none;
      border-radius: 0;
      background-color: $white;
      border-color: rgba(0,0,0,0.5);
      border-bottom: 1px solid $gray;
      box-shadow: none;
      &--is-disabled{
        opacity: 0.3;
      }
    }
    &__indicator-separator{
      display: none;
    }
    &__dropdown-indicator{
      svg{
        border-color: $black transparent transparent;
        border-style: solid;
        border-width: 5px 5px 2.5px;
        display: inline-block;
        height: 0;
        width: 0;
        path{
          display: none;
        }
      }

    }
  }
  .has-value{
    .react-select__placeholder{
      color: $black;
    }
  }
}
