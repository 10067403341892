.stock-performance-modal {

   .select-performance-all{
     .react-select__option{
       text-transform: uppercase;
       font-size: 13px;
     }
     .react-select__control{
       font-size: 16px;
     }
   }
  .dashboard-modal {
    width: 1280px;
  }
  .modal-title{
    h6{
      font-weight: bold;
    }
  }
  .form-label {
    font-size: 13px;
    color: rgba(87, 101, 116, 1);
  }
  .performance {
    width: 20vw;
    justify-content: space-between;

    .select-checkboxes-container {
      .react-select__option--is-focused{
        background-color: #D8D8D8!important;
      }

      .react-select__option--is-selected {
        background-color: #D8D8D8!important;
      }
    }
    .react-select__control{
      min-width: 137px;
    }
    .selector-container {
      width: 200px;
    }
   }

   .performance-chips {
    .chips-container {
     display: flex;
     flex-direction: row;

     .chips {
        margin: 2px;
     }
    }
  }
   .actions{
     margin: 0;
     background-color: $blue-light;
     .btn-actions{
       padding: 20px 5px;
       display: flex;
       justify-content: space-between;
       align-items: center;
       width: 100%;
     }
   }

 }
