@charset "UTF-8";
/* barlow-regular - latin */
@import "~react-dates/lib/css/_datepicker.css";
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  src: local("Barlow Light"), local("Barlow-Light"), url("../fonts/barlow-light.woff2") format("woff2"), url("../fonts/barlow-light.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: normal;
  src: local("Barlow Medium"), local("Barlow-Medium"), url("../fonts/barlow-v1-latin-500.woff2") format("woff2"), url("../fonts/barlow-v1-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: bold;
  src: local("Barlow Bold"), local("Barlow-Bold"), url("../fonts/barlow-v1-latin-700.woff2") format("woff2"), url("../fonts/barlow-v1-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBM Plex Sans Light"), url("../fonts/ibmplexsans-light.woff2") format("woff2"), url("../fonts/ibmplexsans-light.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  src: local("IBM Plex Sans Semibold"), local("IBM Plex Sans Semibold"), url("../fonts/ibmplexsans-semibold-webfont.woff2") format("woff2"), url("../fonts/ibmplexsans-semibold-webfont.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  src: local("IBM Plex Sans Bold"), local("IBM Plex Sans Bold"), url("../fonts/ibmplexsans-bold-webfont.woff2") format("woff2"), url("../fonts/ibmplexsans-bold-webfont.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "IBM Plex Sans Regular";
  src: local("IBM Plex Sans Regular"), local("IBM Plex Sans Regular"), url("../fonts/ibmplexsans-regular-webfont.woff2") format("woff2"), url("../fonts/ibmplexsans-regular-webfont.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.menu-icon-svg {
  width: 24px;
  height: 24px;
}

.login-wrp {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

.basic-paper-layout {
  margin-top: 20px;
  padding: 30px 48px;
}

.menu-link.active svg {
  color: white;
}
.menu-link svg {
  color: #C8D6E5;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.wcra-logo {
  width: 140px;
}
.wcra-logo.home-size {
  width: 271px;
}

.normal-align {
  margin-left: 10px !important;
}

.back-button {
  color: #0fb58f !important;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 19px;
  margin-top: 20px;
  border: none;
  text-transform: uppercase;
  background: none;
  margin-left: 15px;
  margin-bottom: 0;
}
.back-button .arrow-left {
  height: 18px;
  vertical-align: middle;
  margin-bottom: 4px;
}

.align-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.password-icon.edit-athlete {
  position: absolute;
  right: 0.8em;
  cursor: pointer;
}

.paper-main-content-bg {
  margin-top: 25px;
  background: transparent !important;
}

.form-input-control label {
  font-weight: bold;
  text-transform: uppercase;
}

.form-label-bold {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 8px;
  margin-left: 10px;
}

.form-input-uppercase {
  text-transform: uppercase;
}
.form-input-uppercase input {
  text-transform: uppercase;
}

.bold {
  font-weight: bold !important;
}

.address-field,
div.Select.address-field div.Select-control div.Select-value span.Select-value-label {
  color: rgba(0, 0, 0, 0.8) !important;
  font-family: "IBM Plex Sans" !important;
  font-size: 16px !important;
}

.filter-button {
  background-color: transparent;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-align: inherit;
  color: inherit;
  text-transform: inherit;
  line-height: inherit;
  padding: inherit;
  margin: inherit;
  opacity: inherit;
}

.select-contestant {
  margin-top: 45px;
}

.color-gold {
  color: #FFC404 !important;
}

.color-grey {
  color: #9D9D9D !important;
}

.marLeftN-1 {
  margin-left: -1em !important;
}

.marLeft-1 {
  margin-left: 0.5em !important;
}

.marLeft-2 {
  margin-left: 0.5em !important;
}

.marLeft-20 {
  margin-left: 20px !important;
}

.marLeft-50 {
  margin-left: 50px !important;
}

.marRight-60 {
  margin-right: 60px;
}

.marRight-1 {
  margin-right: 0.5em !important;
}

.marRight-2 {
  margin-right: 1em !important;
}

.marLeft-auto {
  margin-left: auto !important;
}

.marTop-0 {
  margin-top: 0px !important;
}

.marTop-5 {
  margin-top: 5px !important;
}

.marTop-15 {
  margin-top: 15px !important;
}

.marTop-20 {
  margin-top: 20px !important;
}

.padBot-0 {
  padding-bottom: 0px !important;
}

.padBot-1 {
  padding-bottom: 1em;
}

.padBot-2 {
  padding-bottom: 2em;
}

.padTop-0 {
  padding-top: 0px !important;
}

.padTop-1 {
  padding-top: 1em;
}

.padTop-2 {
  padding-top: 2em;
}

.padLeft-1 {
  padding-left: 1em;
}

.padLeft-0 {
  padding-left: 0px !important;
}

.padRight-1 {
  padding-right: 1em;
}

.borderBot-0 {
  border-bottom: 0;
}

.color-green {
  color: #0fb58f !important;
}

.color-black {
  color: #242424 !important;
}

.weak {
  font-weight: lighter !important;
}

.paper-section-title {
  text-transform: uppercase;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
}

.paper-section-sub-title {
  display: flex;
  text-transform: uppercase;
  font-size: 1.125rem !important;
  font-family: "IBM Plex Sans" !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
  align-content: center;
}

.entry-card-sub-section-title {
  font-size: 1.125rem !important;
  text-transform: uppercase;
  font-family: "IBM Plex Sans" !important;
  margin-bottom: 0.9375rem !important;
  letter-spacing: 0.049375rem !important;
  line-height: 1.5625rem !important;
  color: #242424 !important;
}

.entry-card-sub-section-title-spacing {
  padding-top: 55px;
  padding-bottom: 15px;
}

.entry-card-country-grid-item {
  padding-left: 25px !important;
}

.filled-input {
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: #f4f5f8 !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 27px 0 0 !important;
}
.filled-input.no-padding {
  padding: 3px 0 0 !important;
}
.filled-input.no-padding-select {
  padding: 10px 0 0 !important;
}
.filled-input .form-label {
  margin-top: 8px;
  margin-left: 10px;
}
.filled-input input {
  padding: 10px;
}
.filled-input textarea {
  width: 100%;
  padding: 10px;
}
.filled-input textarea::placeholder {
  line-height: 145px;
}
.filled-input .Select {
  padding-top: 12px !important;
}
.filled-input .Select .Select-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.filled-input .Select .Select-control .Select-value,
.filled-input .Select .Select-control .Select-placeholder,
.filled-input .Select .Select-control .Select-multi-value-wrapper {
  padding-left: 10px !important;
  margin-bottom: 4px !important;
}
.filled-input .custom-select .form-label {
  position: absolute;
  margin-top: -42px;
  font-size: 0.75rem;
  z-index: 1;
}
.filled-input .filled-start-adornment {
  display: inline-block;
  margin-top: -22px;
  margin-left: 5px;
}
.filled-input.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.filled-input .disabled {
  background: white;
}

.filled-input-narrow,
.filled-input-small {
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: #f4f5f8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px 0 0 !important;
  margin-top: 0px !important;
}
.filled-input-narrow.no-padding,
.filled-input-small.no-padding {
  padding: 3px 0 0 !important;
}
.filled-input-narrow.no-padding-select,
.filled-input-small.no-padding-select {
  padding: 10px 0 0 !important;
}
.filled-input-narrow .form-label,
.filled-input-small .form-label {
  margin-top: 8px;
  margin-left: 10px;
}
.filled-input-narrow input,
.filled-input-small input {
  padding: 11px;
  height: 36px;
}
.filled-input-narrow .Select,
.filled-input-small .Select {
  padding-top: 12px !important;
}
.filled-input-narrow .Select .Select-control,
.filled-input-small .Select .Select-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.filled-input-narrow .Select .Select-control .Select-value,
.filled-input-narrow .Select .Select-control .Select-placeholder,
.filled-input-narrow .Select .Select-control .Select-multi-value-wrapper,
.filled-input-small .Select .Select-control .Select-value,
.filled-input-small .Select .Select-control .Select-placeholder,
.filled-input-small .Select .Select-control .Select-multi-value-wrapper {
  padding-left: 10px !important;
  margin-bottom: 4px !important;
}
.filled-input-narrow .filled-start-adornment,
.filled-input-small .filled-start-adornment {
  display: inline-block;
  margin-top: -22px;
  margin-left: 5px;
}
.filled-input-narrow.margin-bottom-20,
.filled-input-small.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.filled-input-narrow.margin-bottom-10,
.filled-input-small.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.filled-input-narrow input {
  height: 41px;
  padding: 22px 10px 0 10px;
}

.form-helper {
  margin-top: 0 !important;
  padding-top: 8px !important;
  background: #FFFFFF !important;
}

.form-counter {
  position: absolute;
  right: 5px;
  bottom: 10px;
  color: #9D9D9D;
}

.filled-input-height {
  margin-top: 50px !important;
}

.toggle-wrp {
  margin-top: 15px !important;
}

.toggle-form-control {
  padding: 27px 0 0 !important;
}
.toggle-form-control .form-label {
  margin-top: 0px;
  margin-left: 0px;
}

.tab-disabled {
  color: #555 !important;
  color: #e2e2e2 !important;
}

.wcra-green-cta {
  background-color: #10AC84 !important;
  color: #FFFFFF !important;
  position: relative;
}
.wcra-green-cta:disabled {
  opacity: 0.3;
}

.wcra-danger-cta {
  background-color: #EE5253 !important;
  color: #FFFFFF !important;
  position: relative;
}
.wcra-danger-cta:disabled {
  opacity: 0.5;
}

.button-indicator {
  position: absolute;
  background-color: #FFFFFF;
  width: 15px;
  height: 15px;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  right: -5px;
  top: -5px;
  font-size: 10px;
  box-shadow: 2px 2px 7px -3px rgba(0, 0, 0, 0.75);
}

.wcra-default-cta {
  background-color: transparent !important;
  color: #0F0F0F !important;
  border-color: #0F0F0F !important;
}
.wcra-default-cta:disabled {
  opacity: 0.3;
}

.reride-button {
  width: 115px;
}

.wcra-dark-blue-cta {
  background-color: #222F3E !important;
  color: #FFFFFF !important;
}
.wcra-dark-blue-cta:disabled {
  opacity: 0.3;
}

.wcra-unavailable-red-cta {
  border-color: #EE5253 !important;
  color: #EE5253 !important;
}

.wcra-muted-grey-cta {
  border-color: #11181F !important;
  color: #11181F !important;
}

.white-text-button {
  color: #FFFFFF !important;
  text-decoration: underline !important;
}
.white-text-button:hover {
  background-color: unset !important;
}

.wcra-white-blue-cta {
  background-color: #FFFFFF !important;
  border: 1px solid #8395A7 !important;
  box-shadow: unset !important;
}
.wcra-white-blue-cta:hover {
  background: #F2F4F7 !important;
}
.wcra-white-blue-cta:disabled {
  background: #F2F4F7 !important;
  cursor: default;
  opacity: 0.5;
}

.main-new-nomination, .main-nomination-refund {
  margin-top: 50px;
}
.main-new-nomination .back-button, .main-nomination-refund .back-button {
  padding-bottom: 20px;
  padding-left: 2px;
}

.back-button {
  color: #2E86DE;
  text-transform: uppercase;
}
.back-button a {
  font-size: 1.2rem;
  text-decoration: none;
  color: #2E86DE;
}

.checkbox-color {
  color: #bcc5ce !important;
}
.checkbox-color.blue {
  color: #2E86DE !important;
}
.checkbox-color.green {
  color: #10AC84 !important;
}
.checkbox-color.no-padding {
  padding: 0px !important;
}

.light-gray-button {
  background-color: #8395A7 !important;
  color: #ffffff !important;
}
.light-gray-button.disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.menu-icon-color {
  color: #C8D6E5 !important;
}

.divider-menu-color {
  background: #C8D6E5 !important;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hidden {
  padding: 0 !important;
  visibility: hidden;
}

.pos-relative {
  position: relative;
}

.full-name-search {
  position: relative;
}
.full-name-search div:first-child {
  position: absolute;
  left: 20px;
  top: 12px;
  right: -100px;
}

.extra-wide-filter div:nth-child(1) {
  width: 13em;
}

.button-dark {
  line-height: 16px;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #222e3e;
}
.button-dark:hover {
  background-color: #242424;
  cursor: pointer;
}
.button-dark:disabled {
  background-color: #9D9D9D;
}
.button-dark:disabled:hover {
  background-color: #9D9D9D;
  cursor: auto;
}

.button-light {
  line-height: 16px;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #222e3e;
  background-color: #F2F4F7;
  border-style: solid;
}
.button-light:hover {
  background-color: #C8D6E5;
  cursor: pointer;
}
.button-light:disabled {
  background-color: #9D9D9D;
}
.button-light:disabled:hover {
  background-color: #9D9D9D;
  cursor: auto;
}

.full-width {
  width: 100%;
}

.medium-width {
  min-width: 160px;
}

.text-center {
  text-align: center;
}

.paper-section-subtitle {
  font-size: 20px !important;
  margin-top: 15px !important;
  margin-bottom: 3px !important;
  text-transform: uppercase;
}

.cloud-icon {
  margin-bottom: -6px;
  margin-left: 8px;
}

.table-cell-filter {
  padding-left: 0 !important;
}
.table-cell-filter label {
  top: -12px;
  line-height: 1.1;
}

.table-data-filters .table-cell-filter > div {
  width: 100%;
}

.modal-with-select-box div:nth-child(2) div:first-child {
  overflow-y: visible;
}
.modal-with-select-box .modal-with-select-box-content {
  overflow-y: visible;
}

.info-icon {
  color: #0F0F0F;
  margin: -5px 0 -5px 5px;
}

.team-shared-label,
.team-discipline {
  position: absolute;
}

.team-shared-label.team-discipline {
  margin-top: 0;
  padding-top: 7px;
}

.team-shared-label {
  margin-top: 0px;
}

.team-shared-label.nomination-description {
  margin-top: 0px;
  display: flex;
}

.team-shared-label .team-elegible-nom {
  display: inline-block;
  line-height: 2em;
}
@media (max-width: 1699px) {
  .team-shared-label .team-elegible-nom {
    width: 81px;
  }
}
@media (max-width: 600px) {
  .team-shared-label .team-elegible-nom {
    width: 100%;
  }
}

.alliance-partner-icon {
  color: #0fb58f;
}

.entries-additional-options {
  text-transform: uppercase;
}

.options-menu ul {
  padding-bottom: 0;
  padding-top: 0;
}
.options-menu ul li {
  width: 154px;
  height: 25px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  text-transform: capitalize;
  color: #0F0F0F;
}
.options-menu ul li:not(:hover) {
  background-color: white;
}
.options-menu div:nth-child(2) {
  box-shadow: 0px 2px 5px rgba(17, 24, 31, 0.3);
  border-radius: 3px;
}

.filter-cell {
  line-height: 35px;
  padding: 0;
}
.filter-cell > div {
  padding-block: 0;
  padding-inline: 8px;
}
.filter-cell > div:first-child {
  border-block-end: 1px solid var(--rdg-border-color);
}

.filter-input {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
}

.data-grid-parent > .rdg {
  flex: 1;
  max-height: 800px;
}

.modal-overflow-visible > div > div {
  overflow-y: visible;
}

.highlighted-row {
  background-color: #e6e6e6;
}
.highlighted-row td {
  color: black;
  font-weight: 900;
}

.scrollable-data-grid-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  block-size: 65vh;
  padding: 8px;
  contain: inline-size;
  overflow: auto;
}

.fill-grid {
  block-size: 100%;
}

.changes-not-saved-modal {
  font-style: normal;
}
.changes-not-saved-modal .changes-card-modal {
  border-radius: 5px;
}
.changes-not-saved-modal .changes-card-modal #modal-discard-btn,
.changes-not-saved-modal .changes-card-modal #modal-cancel-btn,
.changes-not-saved-modal .changes-card-modal #form-dialog-content {
  font-family: "IBM Plex Sans";
}
.changes-not-saved-modal .changes-card-modal #form-dialog-title {
  padding-bottom: 0;
  margin-bottom: 6px;
  letter-spacing: 0.97px;
}
.changes-not-saved-modal .changes-card-modal #form-dialog-title h6 {
  font-family: "IBM Plex Sans";
  font-size: 22px;
  letter-spacing: 0.97px;
  font-weight: 700;
}
.changes-not-saved-modal .changes-card-modal #modal-discard-btn,
.changes-not-saved-modal .changes-card-modal #modal-cancel-btn {
  font-size: 16px;
  width: 100px;
  font-weight: 300;
}
.changes-not-saved-modal .changes-card-modal .modal-section-content {
  font-size: 16px;
  letter-spacing: 0.7px;
  text-transform: initial;
  margin: 0 25px 20px;
  font-weight: 300;
}
.changes-not-saved-modal .changes-card-modal .wcra-danger-cta {
  width: 84px;
  margin-left: 20px;
}

.compressed-table th {
  padding: 4px 10px 4px 10px !important;
}

.default-font {
  font-size: 16px !important;
}

.highlighted {
  background: #FF9F43;
}

.result-loaded {
  background: #FFF5E1;
}

.result-audited {
  background: #8395A7;
}

.result-public {
  background: #CFF6EC;
}

.table-listing-with-scroll {
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #FFFFFF;
}
.table-listing-with-scroll .performance-draw-content td p {
  color: #11181F;
}

.table-listing {
  width: 100%;
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #FFFFFF;
}
.table-listing #rank {
  width: auto;
}
.table-listing .cell-no-padding-right {
  padding-right: 0;
}
.table-listing .cell-no-padding-right.borderBot-0 td:nth-child(1) {
  border-bottom: 0;
}
.table-listing .column-padding-left td {
  padding-left: 10px;
}

.fab-circle-btn {
  top: 95px;
  right: 40px;
  position: fixed !important;
}

.add-entry-nom-btn {
  margin-right: 160px !important;
  margin-top: 37px !important;
}

.table-cell-listing {
  padding: 15px 5px !important;
}
.table-cell-listing.table-pad-left {
  padding-left: 20px !important;
}

.table-cell-content-disp-block {
  display: block !important;
}

a.select-all {
  color: #10AC84;
  text-decoration: none;
  font-weight: bolder;
}

.label-search-filter {
  display: block;
  padding-left: 15px;
}

.dark-table-head {
  background-color: #222e3e;
  color: #ffffff !important;
  height: 73px !important;
}
.dark-table-head th,
.dark-table-head td {
  color: #ffffff;
  font-weight: 900;
}
.dark-table-head .dark-head-row {
  height: 73px !important;
  color: #ffffff;
}
.dark-table-head .dark-table-sort-link {
  color: #ffffff;
}
.dark-table-head .dark-table-sort-link:hover {
  color: #777;
}

.align-column-left {
  flex-direction: row !important;
  text-align: left !important;
}

.align-column-right {
  flex-direction: row-reverse !important;
  text-align: right !important;
}

.align-column-center {
  text-align: center !important;
}

.column-padding-small {
  padding: 0 8px !important;
}

th.bump-left {
  padding-left: 2em !important;
}

.edit-performance-title {
  background-color: #FFFFFF;
}
.edit-performance-title h2 {
  line-height: 25px;
  font-family: "IBM Plex Sans";
  font-weight: 900;
  letter-spacing: 0.06em;
  color: #242424;
  font-size: 1.3em;
  text-transform: uppercase;
}

.performance-details {
  width: 100%;
  margin: auto;
  display: inline-flex;
}
.performance-details.non-managed {
  justify-content: space-around;
}
.performance-details .required-symbol {
  display: block;
  position: absolute;
  right: 0;
  text-align: right;
  margin-right: 12px;
  margin-top: -6px;
  font-size: 15px;
}
.performance-details label {
  display: none;
}
.performance-details div.header-item {
  width: 20%;
}
.performance-details div.date {
  margin-left: 10px;
  width: 95% !important;
}
.performance-details div.time {
  width: 28% !important;
}
.performance-details div.date input, .performance-details div.time input {
  padding-left: 2em;
}
.performance-details div:nth-child(1).header-item {
  width: 35%;
  padding-left: 2%;
}
.performance-details div:nth-child(1).header-item.non-managed {
  width: 40%;
  padding-left: unset;
}
.performance-details div:last-child.header-item {
  width: 15%;
}
.performance-details div:last-child.header-item.non-managed {
  width: 40%;
  padding-left: 7.5%;
}
.performance-details .header-item {
  color: #222F3E;
  font-family: "IBM Plex Sans";
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 15px;
}
.performance-details .competition-level {
  width: 206px;
  padding: 0;
  margin-left: 6px;
  margin-right: 0;
}
.performance-details .competition-level select {
  margin-bottom: 15px;
  padding-left: 15px;
}

.custom-error {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
  margin-bottom: 5px;
  font-family: Barlow, sans-serif;
}

.show-error {
  border-bottom: 1px solid #f44336 !important;
}
.show-error::before {
  border-bottom: 0 !important;
}
.show-error::after {
  border-bottom: 0 !important;
}
.show-error:hover {
  border-bottom: 1px solid #f44336 !important;
}

.performance-details-classes {
  width: 100%;
  margin: auto;
  display: inline-flex;
}
.performance-details-classes .required-symbol {
  display: block;
  position: absolute;
  right: 0;
  text-align: right;
  margin-right: 16px;
  margin-top: -6px;
  font-size: 15px;
}
.performance-details-classes label {
  display: none;
}
.performance-details-classes div.header-item {
  width: 20%;
}
.performance-details-classes div.date {
  margin-left: 10px;
  width: 95% !important;
}
.performance-details-classes div.time {
  width: 28% !important;
}
.performance-details-classes div.date input, .performance-details-classes div.time input {
  padding-left: 2em;
}
.performance-details-classes div:nth-child(1).header-item {
  width: 30%;
  padding-left: 2%;
}
.performance-details-classes div:nth-child(1).header-item.non-managed {
  width: 40%;
  padding-left: 5%;
}
.performance-details-classes div:nth-child(2).header-item {
  width: 35%;
  padding-left: 2%;
}
.performance-details-classes div:nth-child(2).header-item.non-managed {
  width: 40%;
}
.performance-details-classes div:last-child.header-item {
  width: 15%;
}
.performance-details-classes .header-item {
  color: #222F3E;
  font-family: "IBM Plex Sans";
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 15px;
}
.performance-details-classes .competition-level {
  width: 206px;
  padding: 0;
  margin-left: 6px;
  margin-right: 0;
}
.performance-details-classes .competition-level select {
  margin-bottom: 15px;
  padding-left: 15px;
}

.performance-details-discipline {
  width: 100%;
  margin: auto;
  display: inline-flex;
  border-top: #C8D6E5 solid 1px;
  border-bottom: #C8D6E5 solid 1px;
}
.performance-details-discipline label {
  display: none;
}
.performance-details-discipline div {
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.edit-performance-content {
  background-color: #FFFFFF;
}
.edit-performance-content div,
.edit-performance-content label {
  color: #242424;
}
.edit-performance-content.no-padding {
  padding: 0px !important;
}
.edit-performance-content .control-date {
  width: 100%;
}
.edit-performance-content .symbol {
  display: block;
  position: absolute;
  right: 0;
  text-align: right;
  margin-right: 12px;
  margin-top: -6px;
  font-size: 15px;
}

.add-performance-row button {
  margin-left: 0;
  margin-right: auto;
  margin-top: 5px;
}

.edit-performance-input-form-field {
  height: 4em;
  border-radius: 5px 5px 0 0;
  background-color: #F2F4F7;
  margin-top: 0 !important;
  border-bottom: 1px solid #C8D6E5;
}
.edit-performance-input-form-field select,
.edit-performance-input-form-field input {
  position: relative;
  top: 1em;
  text-transform: uppercase;
}
.edit-performance-input-form-field svg {
  top: 0.6em;
  color: #10AC84;
}
.edit-performance-input-form-field.date-icon {
  padding-bottom: 20px;
  padding-left: 15px;
}
.edit-performance-input-form-field.date-icon svg {
  position: relative;
  top: 0.6em;
}

.performance-sub-checkbox {
  display: flex;
  width: 70% !important;
  align-items: flex-start;
  justify-content: center;
}
.performance-sub-checkbox.non-managed {
  align-items: center;
}
.performance-sub-checkbox.non-managed.with-classes {
  width: 25% !important;
}

.performance-sub-field {
  border-bottom: 1px solid #E9E9E9;
  margin-top: 7px !important;
}
.performance-sub-field.long {
  width: 175%;
}
.performance-sub-field.long.non-managed {
  width: 50%;
}
.performance-sub-field label + div {
  margin-top: 0px !important;
}
.performance-sub-field select {
  text-transform: uppercase;
}
.performance-sub-field .performance-sub-field-helper-text {
  display: flex;
  color: #222F3E;
  font-size: 0.55em;
  margin-top: 4px;
  margin-bottom: 4px;
  align-items: center;
}
.performance-sub-field .performance-sub-field-helper-text svg {
  margin-right: 2px;
  width: 10px;
  height: 10px;
}

.delete-performance-discipline {
  margin-top: 12px;
}

.input-border-bottom {
  height: 1px;
  width: 120px;
  background-color: #E9E9E9;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.performance-actions {
  background-color: #FFFFFF;
  margin: 0 !important;
  padding-right: 1em;
  padding-bottom: 1em;
}

.page-title {
  font-family: "IBM Plex Sans" !important;
  text-transform: uppercase;
  font-size: 3.3125rem !important;
  font-weight: 700 !important;
}

.page-subtitle {
  margin-top: 20px !important;
  margin-bottom: -15px !important;
  text-transform: uppercase;
  font-size: 2.3125rem !important;
  font-weight: 500 !important;
  color: #8394a7 !important;
}

.head-padding {
  padding: 23px 10px;
}

.Select:hover .Select-placeholder,
.Select:hover .Select-value,
.Select.is_focused:hover .Select-placeholder,
.Select.is_focused:hover .Select-value,
.Select.is_open:hover .Select-placeholder,
.Select.is_open:hover .Select-value {
  cursor: pointer;
}
.Select .Select-control,
.Select.is_focused .Select-control,
.Select.is_open .Select-control {
  background: #f2f4f7 !important;
  border-radius: 0;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: 1px #ddd solid;
  margin-top: 5px !important;
}
.Select .Select-control .Select-input input,
.Select.is_focused .Select-control .Select-input input,
.Select.is_open .Select-control .Select-input input {
  color: #000;
}
.Select .Select-control .Select-input:focus,
.Select.is_focused .Select-control .Select-input:focus,
.Select.is_open .Select-control .Select-input:focus {
  background: transparent !important;
}
.Select .Select-control .Select-value,
.Select .Select-control .Select-value-label,
.Select.is_focused .Select-control .Select-value,
.Select.is_focused .Select-control .Select-value-label,
.Select.is_open .Select-control .Select-value,
.Select.is_open .Select-control .Select-value-label {
  color: #0F0F0F !important;
  padding-left: 8px;
}
.Select .Select-placeholder,
.Select .Select-value,
.Select.is_focused .Select-placeholder,
.Select.is_focused .Select-value,
.Select.is_open .Select-placeholder,
.Select.is_open .Select-value {
  color: #9D9D9D;
  font-size: 20px;
  padding-left: 8px;
}
.Select .Select-arrow-zone .Select-arrow,
.Select .Select-arrow,
.Select.is_focused .Select-arrow-zone .Select-arrow,
.Select.is_focused .Select-arrow,
.Select.is_open .Select-arrow-zone .Select-arrow,
.Select.is_open .Select-arrow {
  border-color: #0fb58f transparent transparent;
}
.Select .Select-arrow-zone .Select-arrow:hover,
.Select .Select-arrow:hover,
.Select.is_focused .Select-arrow-zone .Select-arrow:hover,
.Select.is_focused .Select-arrow:hover,
.Select.is_open .Select-arrow-zone .Select-arrow:hover,
.Select.is_open .Select-arrow:hover {
  border-top-color: #0fb58f;
}
.Select .Select-menu,
.Select.is_focused .Select-menu,
.Select.is_open .Select-menu {
  max-height: 315px;
}
.Select .Select-menu-outer,
.Select.is_focused .Select-menu-outer,
.Select.is_open .Select-menu-outer {
  background: #f4f5f8;
  border: transparent;
  font-size: 14px;
  max-height: 315px;
  overflow-y: auto;
  z-index: 2;
}
.Select .Select-option,
.Select.is_focused .Select-option,
.Select.is_open .Select-option {
  background: #f4f5f8;
  color: #0F0F0F;
  padding: 15px;
}
.Select .Select-option:hover,
.Select.is_focused .Select-option:hover,
.Select.is_open .Select-option:hover {
  background: #0fb58f;
}
.Select .Select-option.is-open, .Select .Select-option.is-focused,
.Select.is_focused .Select-option.is-open,
.Select.is_focused .Select-option.is-focused,
.Select.is_open .Select-option.is-open,
.Select.is_open .Select-option.is-focused {
  background: #0fb58f;
}
.Select .Select-option.is-open:hover, .Select .Select-option.is-focused:hover,
.Select.is_focused .Select-option.is-open:hover,
.Select.is_focused .Select-option.is-focused:hover,
.Select.is_open .Select-option.is-open:hover,
.Select.is_open .Select-option.is-focused:hover {
  background: #0fb58f;
}
.Select .Select-option.is-selected,
.Select.is_focused .Select-option.is-selected,
.Select.is_open .Select-option.is-selected {
  background: #0fb58f;
}
.Select .Select-option.is-selected:hover,
.Select.is_focused .Select-option.is-selected:hover,
.Select.is_open .Select-option.is-selected:hover {
  background: #0fb58f;
}
.Select.has-value.Select--single .Select-control .Select-value,
.Select.is_focused.has-value.Select--single .Select-control .Select-value,
.Select.is_open.has-value.Select--single .Select-control .Select-value {
  padding-left: 0px;
}
.Select.has-value.Select--single .Select-control .Select-value .Select-value-label,
.Select.is_focused.has-value.Select--single .Select-control .Select-value .Select-value-label,
.Select.is_open.has-value.Select--single .Select-control .Select-value .Select-value-label {
  color: #0F0F0F;
}
.Select.is-focused:not(.is-open) > .Select-control,
.Select.is_focused.is-focused:not(.is-open) > .Select-control,
.Select.is_open.is-focused:not(.is-open) > .Select-control {
  border-color: transparent;
}

.Select.is-disabled .Select-control {
  background: transparent !important;
  opacity: 0.8;
}
.Select.is-disabled .Select-placeholder {
  cursor: default !important;
}
.Select.is-disabled .Select-value-label {
  color: rgba(0, 0, 0, 0.38) !important;
}

.Select--multi .Select-value {
  margin: 0 5px 0 0 !important;
  font-size: 1em !important;
  border: none !important;
  background: #0fb58f !important;
}

.Select-value-icon:hover {
  color: #0F0F0F !important;
}

.inline-select-box {
  display: inline-block;
  width: 100%;
}

.select-with-add-button .add-option-button {
  color: #10AC84 !important;
  font-weight: bold;
}
.select-with-add-button .add-option-button.is-focused {
  background-color: #FFFFFF !important;
}
.select-with-add-button .add-new-contestant {
  color: #10ac84;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 24px;
  min-height: 24px;
  padding: 18px 0;
}
.select-with-add-button .Select-menu-outer {
  -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.59);
}
.select-with-add-button .Select-menu-outer .Select-option {
  color: #242424;
  background-color: #FFFFFF;
}
.select-with-add-button .Select-menu-outer .Select-option.is-focused {
  background-color: #0fb58f;
}

.spinner-container {
  display: none;
  background-color: rgba(255, 255, 255, 0.5);
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 1301;
}
.spinner-container.modal {
  position: absolute;
}
.spinner-container.show {
  display: block;
}
.spinner-container .spinner {
  color: #0fb58f;
  position: fixed;
  z-index: 1302;
  top: 50%;
  left: 50%;
  width: 84px;
  height: 84px;
  margin: -42px;
  animation: spin 1s linear infinite;
}

.component-spinner-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  opacity: 0.8;
}
.component-spinner-container.show {
  display: flex;
}
.component-spinner-container .spinner {
  color: #0fb58f;
  z-index: 1302;
  margin: auto;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.overlayed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loadingRotation {
  animation: spin 1s linear infinite;
}

.chip-alliance-level .gold-chip-container {
  width: 86px;
  height: 31px;
  background: linear-gradient(98.94deg, #ECC335 8.54%, #E0A630 93.47%);
  border-radius: 22px;
}
.chip-alliance-level .silver-chip-container {
  width: 86px;
  height: 31px;
  background: linear-gradient(97.52deg, #F8F8F8 0%, #D0D0CE 106.59%);
  border-radius: 22px;
}
.chip-alliance-level .bronze-chip-container {
  width: 86px;
  height: 31px;
  background: linear-gradient(97.52deg, #E3C9A6 0%, #A78E6F 106.59%);
  border-radius: 22px;
}

.notification-container {
  background-color: #461e1e;
  border-radius: 0;
  padding: 12px;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  letter-spacing: 0;
  color: #fff;
}
.notification-container.success {
  background-color: transparent;
}

.notification-column {
  display: inline-block;
  max-width: 80%;
}

.notification-heading {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.notification-heading.success {
  color: #8CE04A;
}

.notification-message {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.notification-message.success {
  color: #8CE04A;
}

.notification-icon {
  height: 24px;
  width: 24px;
  color: #fff;
  float: left;
  padding-right: 35px;
}
.notification-icon.success {
  color: #8CE04A;
}

.alert-snackbar {
  z-index: 3;
  position: fixed;
  text-align: center;
  right: 50%;
  transform: translate(50%, 0%);
}

.alert-notification.success {
  background-color: #10AC84;
  max-width: 800px;
}
.alert-notification.fail {
  background-color: #FF5349;
  max-width: 800px;
}
.alert-notification.warn {
  color: #0F0F0F;
  background-color: #FFC404;
  max-width: 800px;
}
.alert-notification.right {
  left: 78%;
}
.alert-notification.content-centered div:nth-child(1) {
  margin: auto;
}

.csv-file-upload input[type=file] {
  display: none;
}

.csv-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.merge-event-field {
  height: 180px;
}

.merge-event-info-text {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 12px;
  margin-bottom: 7px;
}

.merge-event-field {
  margin-top: 50px;
}

p.merge-event-error-message {
  font-size: 14px;
  color: #FF5349;
}

.text-message-dialog {
  width: 600px;
}
.text-message-dialog h3 {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 23px;
}
.text-message-dialog .notification-container.success {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.character-count {
  margin-top: 12px;
  float: right;
}

.message-confirm {
  text-align: right;
  margin-right: 4px;
  padding-bottom: 10px;
  padding-top: 40px;
}

.text-submit-test {
  justify-content: flex-start !important;
  margin-left: -5px !important;
}
.text-submit-test button {
  border: 1px solid !important;
  margin-top: 14px !important;
}
.text-submit-test button:disabled, .text-submit-test button[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
  border: none !important;
}

.rodeo-logistics-logo {
  margin: 0 40px;
}
.rodeo-logistics-logo.rodeo-logistics-logo-large {
  width: 100%;
}
.rodeo-logistics-logo.rodeo-logistics-logo-small {
  width: 150px;
}

.splash-screen {
  background-color: #fff;
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.splash-screen.show {
  z-index: 9999;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.CalendarDay__selected_span {
  background: #10AC84 !important;
  border: 1px double #10AC84 !important;
  color: #FFFFFF !important;
  opacity: 0.6;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #10AC84 !important;
  border: 1px double #10AC84 !important;
  color: #FFFFFF !important;
  opacity: 1;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #10AC84 !important;
  border: 1px double #10AC84 !important;
  color: #FFFFFF !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #10AC84 !important;
  opacity: 0.4 !important;
  border: 1px double #10AC84 !important;
  color: #FFFFFF !important;
}

.CalendarDay__hovered_span:active {
  background: #10AC84 !important;
  opacity: 0.4 !important;
  border: 1px double #10AC84 !important;
  color: #FFFFFF !important;
}

.DayPickerNavigation_button__default {
  border: none !important;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: none !important;
}

.DayPickerNavigation_svg__horizontal {
  fill: #10AC84 !important;
}

.DateInput {
  width: 100% !important;
  background-color: transparent !important;
}
.DateInput:nth-child(3) {
  display: none !important;
}

.DateInput_input {
  padding-left: 0;
  font-size: 14px !important;
  white-space: nowrap;
  line-height: 34px !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  height: 36px !important;
  border-bottom: 1px solid rgba(90, 90, 90, 0.6) !important;
  background-color: transparent !important;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  color: #9D9D9D;
}
.DateInput_input:hover {
  border-bottom: 1px solid #5A5A5A !important;
}
.DateInput_input::-webkit-input-placeholder {
  opacity: 0.5 !important;
}
.DateInput_input::-moz-placeholder {
  opacity: 0.5 !important;
}
.DateInput_input:-ms-input-placeholder {
  opacity: 0.5 !important;
}
.DateInput_input:-moz-placeholder {
  opacity: 0.5 !important;
}

.DateInput_input__focused {
  border-bottom: 1px solid #5A5A5A !important;
}

.DateInput_input__disabled {
  background: #FFFFFF !important;
  font-style: normal !important;
  border-color: #ADADAD !important;
  box-shadow: none !important;
  opacity: 0.5;
  cursor: default;
}

.DateInput__disabled {
  background: #FFFFFF !important;
  box-shadow: none;
  cursor: default;
}
.DateInput__disabled input:hover {
  box-shadow: none !important;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  background-color: transparent;
  border: none;
  position: relative;
}

.DateRangePickerInput_arrow_svg {
  width: 16px;
  height: 16px;
  margin: 0 10px;
}

.DateRangePickerInput_calendarIcon {
  position: absolute;
  right: 0px;
  margin: 0 auto;
  color: #10AC84;
}

.DateRangePickerInput_calendarIcon > svg {
  font-size: 16px;
}

.disciplines-modal .list-disciplines {
  max-height: 500px;
  overflow-y: auto;
}
.disciplines-modal .actions-disciplines {
  padding-top: 15px;
}

.merge-events-modal-body {
  margin: 0 24px 24px 24px;
}
.merge-events-modal-body .merge-events-review-nominations-item, .merge-events-modal-body .nomination-container {
  margin-top: 16px;
}
.merge-events-modal-body .merge-events-review-nominations-item:first-child, .merge-events-modal-body .nomination-container:first-child {
  margin-top: 0px;
}
.merge-events-modal-body .sub-title {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
  min-width: 500px;
}
.merge-events-modal-body .info-container {
  display: flex;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: "Barlow";
  font-style: normal;
  gap: 3px;
}
.merge-events-modal-body .info-container .label {
  font-weight: bold;
}
.merge-events-modal-body .info-container .value {
  font-weight: normal;
}
.merge-events-modal-body .nomination-class {
  margin-bottom: 19px;
  margin-top: 14px;
}
.merge-events-modal-body .class-warning {
  padding-bottom: 16px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  max-width: 500px;
}

.merge-modal-actions {
  display: flex;
  justify-content: flex-end;
}
.merge-modal-actions .btn-actions {
  margin: 0px 20px 16px 20px;
  display: flex;
  align-content: center;
}

.modal-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.modal-title .go-back-container {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;
}
.modal-title .go-back-container .go-back-text {
  color: #10AC84;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.modal-title .go-back-container .go-back-icon {
  color: #10AC84;
}

.merge-events-no-entries-nominations .modal-title {
  font-size: 20px;
  margin-bottom: 14px;
}
.merge-events-no-entries-nominations .good-event-container {
  margin-top: 14px;
}

.filter-text-field {
  background-color: #f9fcff;
  width: 100%;
}

.entries-table-cell-discipline .partner {
  color: #8E9FB0;
  font-family: "Barlow", sans-serif;
  font-size: 0.8125rem;
  font-weight: 500;
}
.entries-table-cell-discipline .partner a {
  color: #2E86DE;
  text-decoration: none;
}

.select-checkboxes-container {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}
.select-checkboxes-container .checkbox-color {
  padding: 0;
  padding-right: 7px;
}
.select-checkboxes-container .react-select__menu {
  width: 275px;
}
.select-checkboxes-container .react-select__option {
  padding: 10px 15px;
}
.select-checkboxes-container .react-select__option.child {
  padding-left: 40px;
}
.select-checkboxes-container .react-select__control {
  border: none;
  border-radius: 0;
  background-color: #FFFFFF;
  border-color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #9D9D9D;
  box-shadow: none;
}
.select-checkboxes-container .react-select__control--is-disabled {
  opacity: 0.3;
}
.select-checkboxes-container .react-select__indicator-separator {
  display: none;
}
.select-checkboxes-container .react-select__dropdown-indicator svg {
  border-color: #0F0F0F transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
}
.select-checkboxes-container .react-select__dropdown-indicator svg path {
  display: none;
}
.select-checkboxes-container .has-value .react-select__placeholder {
  color: #0F0F0F;
}

div .chip-container {
  border-radius: 3px;
  background-color: #D8D8D8;
}

.entries-table-cell-status .container {
  display: flex;
  align-items: center;
}
.entries-table-cell-status .container .unpaid,
.entries-table-cell-status .container .pending {
  padding-right: 0.375rem;
  display: flex;
  align-items: center;
}
.entries-table-cell-status .container .unpaid .warning-icon,
.entries-table-cell-status .container .pending .warning-icon {
  fill: #EE5253;
  stroke: white;
}
.entries-table-cell-status .container .unpaid .alert-icon,
.entries-table-cell-status .container .pending .alert-icon {
  fill: #FF9F43;
  stroke: white;
}

.entry-count-letter {
  text-align: center;
  line-height: 12px;
  height: 13px;
  width: 13px;
  font-size: 10px;
  letter-spacing: 0.5px;
  background-color: #2E86DE;
  color: #FFFFFF;
  display: inline-block;
  margin-left: 7px;
}

@media (min-width: 1000px) {
  .entry-count-letter-container {
    white-space: nowrap;
  }
}

.entry-add-partner-modal {
  overflow: visible;
}
.entry-add-partner-modal .title {
  padding: 1.75rem 2rem 0.6875rem;
}
.entry-add-partner-modal .title h6 {
  font-size: 1.375rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.5625rem;
}
.entry-add-partner-modal .description {
  font-size: 1rem !important;
  letter-spacing: 0.7px !important;
  line-height: 1.5625rem !important;
  font-weight: lighter !important;
  height: 1.5625rem !important;
  font-family: "IBM Plex Sans" !important;
}
.entry-add-partner-modal .content {
  padding: 0 2rem 1.5rem !important;
  overflow: visible;
}
.entry-add-partner-modal .hidden {
  visibility: hidden;
  display: none;
}
.entry-add-partner-modal .partner-option-primary-line {
  text-transform: capitalize;
}
.entry-add-partner-modal .Select-value-label {
  text-transform: capitalize;
}
.entry-add-partner-modal .radio {
  margin-bottom: 1.5625rem;
}
.entry-add-partner-modal .label {
  font-family: "IBM Plex Sans";
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.79px;
  line-height: 1.4375rem;
  text-transform: uppercase;
}
.entry-add-partner-modal .radio-content {
  margin-left: 2.125rem;
  margin-bottom: 2rem;
}
.entry-add-partner-modal .select-unmatched {
  min-width: 400px;
  z-index: 2;
  margin-bottom: 1rem;
  z-index: 3;
  margin-bottom: 1rem;
}
.entry-add-partner-modal .select-unmatched .Select-control {
  height: 3.5rem;
  margin-top: 0 !important;
}
.entry-add-partner-modal .select-unmatched .Select-control .Select-placeholder {
  line-height: 3.5rem;
}
.entry-add-partner-modal .select-unmatched .Select-control .Select-value {
  line-height: 3.5rem !important;
  font-size: 1rem;
}
.entry-add-partner-modal .select-unmatched .Select-control .Select-value .Select-value-label {
  line-height: 3.5rem;
  font-size: 1rem;
}
.entry-add-partner-modal .select-unmatched .Select-control .Select-input {
  height: 3.5rem;
}
.entry-add-partner-modal .select-unmatched .Select-control .Select-input input {
  height: 3.5rem;
  vertical-align: middle;
  padding: 0 !important;
}
.entry-add-partner-modal .select-unmatched .Select-menu-outer {
  overflow: visible;
}
.entry-add-partner-modal .select-unmatched .Select-option:hover {
  color: white;
}
.entry-add-partner-modal .select-unmatched .Select-option {
  line-height: initial;
}
.entry-add-partner-modal .select-unmatched .Select-option.is-selected {
  color: white;
}
.entry-add-partner-modal .select-unmatched .Select-option.is-focused {
  color: white;
}
.entry-add-partner-modal .select-matched {
  min-width: 400px;
  z-index: 2;
  margin-bottom: 1rem;
}
.entry-add-partner-modal .select-matched .Select-option:first-child {
  color: #10AC84;
  font-weight: bold;
}
.entry-add-partner-modal .select-matched .Select-control {
  height: 3.5rem;
  margin-top: 0 !important;
}
.entry-add-partner-modal .select-matched .Select-control .Select-placeholder {
  line-height: 3.5rem;
}
.entry-add-partner-modal .select-matched .Select-control .Select-value {
  line-height: 3.5rem !important;
  font-size: 1rem;
}
.entry-add-partner-modal .select-matched .Select-control .Select-value .Select-value-label {
  line-height: 3.5rem;
  font-size: 1rem;
}
.entry-add-partner-modal .select-matched .Select-control .Select-input {
  height: 3.5rem;
}
.entry-add-partner-modal .select-matched .Select-control .Select-input input {
  height: 3.5rem;
  vertical-align: middle;
  padding: 0 !important;
}
.entry-add-partner-modal .select-matched .Select-menu-outer {
  overflow: visible;
}
.entry-add-partner-modal .select-matched .Select-option:hover {
  color: white;
}
.entry-add-partner-modal .select-matched .Select-option {
  line-height: initial;
}
.entry-add-partner-modal .select-matched .Select-option.is-selected {
  color: white;
}
.entry-add-partner-modal .select-matched .Select-option.is-focused {
  color: white;
}
.entry-add-partner-modal .actions {
  margin: 0 !important;
  padding-bottom: 1rem;
  padding-right: 1.375rem;
}
.entry-add-partner-modal .actions .submit {
  margin-right: 0;
}
.entry-add-partner-modal .partner-form {
  display: flex;
  margin-top: 1rem;
}
.entry-add-partner-modal .partner-form > *:last-child {
  margin-left: 1rem;
  width: auto;
}
.entry-add-partner-modal .partner-athlete-option {
  display: "flex";
  flex-direction: "column";
  font-weight: normal;
  font-size: 1rem;
}
.entry-add-partner-modal .partner-athlete-option .partner-option-secondary-line {
  font-weight: 100;
}

.chip-popover-container .chips-container {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  padding: 18px;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 10px;
}

.inline-alert-container {
  margin-top: 20px;
}
.inline-alert-container.hidden {
  display: none;
}

.inline-alert-notification {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.inline-alert-notification .message {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  vertical-align: middle;
  align-items: center;
  font-size: 24px;
  padding: 10px 0 10px 10px;
}
.inline-alert-notification .message .message-icon-error {
  color: #FF5349;
}
.inline-alert-notification .message .message-icon-warn {
  color: #c59803;
}
.inline-alert-notification .message .message-icon-success {
  color: #10AC84;
}
.inline-alert-notification .message .message-text {
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  color: #0F0F0F;
}
.inline-alert-notification .dismiss {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 20px;
}
.inline-alert-notification.success {
  color: #10AC84;
  background-color: rgba(16, 172, 132, 0.1);
  border: 1px solid #10AC84;
  border-radius: 5px;
}
.inline-alert-notification.fail {
  color: #FF5349;
  background-color: #fceeee;
  border: 1px solid #FF5349;
  border-radius: 5px;
}
.inline-alert-notification.warn {
  color: #c59803;
  background-color: rgba(255, 196, 4, 0.1);
  border: 1px solid #c59803;
  border-radius: 5px;
}

.stripe-input {
  padding: 0 10px !important;
}
.stripe-input-font {
  font-family: sans-serif !important;
}

.stock-performance-modal .select-performance-all .react-select__option {
  text-transform: uppercase;
  font-size: 13px;
}
.stock-performance-modal .select-performance-all .react-select__control {
  font-size: 16px;
}
.stock-performance-modal .dashboard-modal {
  width: 1280px;
}
.stock-performance-modal .modal-title h6 {
  font-weight: bold;
}
.stock-performance-modal .form-label {
  font-size: 13px;
  color: rgb(87, 101, 116);
}
.stock-performance-modal .performance {
  width: 20vw;
  justify-content: space-between;
}
.stock-performance-modal .performance .select-checkboxes-container .react-select__option--is-focused {
  background-color: #D8D8D8 !important;
}
.stock-performance-modal .performance .select-checkboxes-container .react-select__option--is-selected {
  background-color: #D8D8D8 !important;
}
.stock-performance-modal .performance .react-select__control {
  min-width: 137px;
}
.stock-performance-modal .performance .selector-container {
  width: 200px;
}
.stock-performance-modal .performance-chips .chips-container {
  display: flex;
  flex-direction: row;
}
.stock-performance-modal .performance-chips .chips-container .chips {
  margin: 2px;
}
.stock-performance-modal .actions {
  margin: 0;
  background-color: #f4f5f8;
}
.stock-performance-modal .actions .btn-actions {
  padding: 20px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.transaction-history-card .line-items-link {
  color: #2E86DE;
  font-family: "IBM Plex Sans Regular";
  font-size: 1rem;
  letter-spacing: 1.05px;
}
.transaction-history-card .line-items-link-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.transaction-history-card .line-items-link:link, .transaction-history-card .line-items-link:hover {
  cursor: pointer;
  text-decoration: none;
}
.transaction-history-card .line-items-link .chevron {
  cursor: pointer;
  height: 1rem;
  padding-left: 0.375rem;
}
.transaction-history-card .line-items-link-text {
  cursor: pointer;
  text-transform: uppercase;
}
.transaction-history-card .date {
  color: #8E9FB0;
  font-family: "Barlow", sans-serif;
  font-size: 0.8125rem;
  font-weight: 500;
}
.transaction-history-card .entry-items {
  display: flex;
  flex-direction: column;
}
.transaction-history-card .entry-item {
  color: #11181F;
  font-family: "IBM Plex Sans Regular";
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 1rem;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
.transaction-history-card .entry-item-discipline {
  padding-left: 1rem;
  padding-bottom: 0.75rem;
}
.transaction-history-card .entry-item-fee {
  padding-bottom: 0.625rem;
}
.transaction-history-card .entry-item-total {
  font-family: "IBM Plex Sans";
  font-weight: bold;
}
.transaction-history-card .entry-item .discipline {
  display: flex;
  align-content: center;
}
.transaction-history-card .entry-item .payment-text {
  display: flex;
  align-items: center;
}
.transaction-history-card .entry-item .payment-text svg {
  margin-left: 8px;
  stroke: #FFFFFF;
  fill: #222F3E;
}
.transaction-history-card .entry-item.bold {
  color: #242424;
  font-weight: 700;
}
.transaction-history-card .external-link {
  cursor: pointer;
  align-self: center;
  padding-left: 0.375rem;
  height: 0.75rem;
  color: #2E86DE;
}
.transaction-history-card .external-link-large {
  height: 0.875rem;
}

.summary-add-go-round-row {
  margin-top: 15px;
  color: #242424 !important;
}
.summary-add-go-round-row .add-row-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.summary-add-go-round-row .add-row-container .add-row-button {
  margin-left: 15px;
  padding: 8px 16px !important;
}
.summary-add-go-round-row .add-row-container .add-row-button:disabled {
  opacity: 0.4;
}
.summary-add-go-round-row .add-row-container .add-row-input {
  max-width: 80px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #222F3E;
  padding: 10px;
  font-size: 16px;
}

.nominated {
  display: flex;
  align-items: center;
}
.nominated .nominated-icon {
  width: 10px;
  height: 10px;
  border: 1px solid #10AC84;
  background-color: #10AC84;
  border-radius: 10px;
  margin-right: 10px;
}

.summary-view-go-round-table .go-round-table-head th:first-child {
  padding-left: 15px !important;
}
.summary-view-go-round-table .go-round-field-array {
  height: 58px !important;
}
.summary-view-go-round-table .go-round-field-array td:first-child {
  padding-left: 15px !important;
}
.summary-view-go-round-table .go-round-field-array th:first-child {
  padding-left: 15px !important;
}
.summary-view-go-round-table .go-round-field-array .done-icon {
  color: #10AC84;
}
.summary-view-go-round-table .go-round-field-array .done-icon, .summary-view-go-round-table .go-round-field-array .clear-icon, .summary-view-go-round-table .go-round-field-array .delete-icon, .summary-view-go-round-table .go-round-field-array .edit-icon {
  cursor: pointer;
}
.summary-view-go-round-table .go-round-field-array .done-icon.disabled, .summary-view-go-round-table .go-round-field-array .clear-icon.disabled, .summary-view-go-round-table .go-round-field-array .delete-icon.disabled, .summary-view-go-round-table .go-round-field-array .edit-icon.disabled {
  cursor: default;
  opacity: 0.2;
}
.summary-view-go-round-table .go-round-field-array .input-cell {
  padding: 0px 20px 0px 5px !important;
}
.summary-view-go-round-table .go-round-field-array .input-cell.input-number {
  padding: 0px 20px 0px 20px !important;
}
.summary-view-go-round-table .go-round-field-array .input-cell.input-number .no-label input {
  text-align: center;
}
.summary-view-go-round-table .go-round-field-array .input-cell .no-label {
  margin-top: 0px !important;
  position: relative;
}
.summary-view-go-round-table .go-round-field-array .input-cell .no-label .Select-control {
  margin-top: 0px !important;
  height: unset !important;
  border-bottom-color: rgba(0, 0, 0, 0.42) !important;
}
.summary-view-go-round-table .go-round-field-array .input-cell .no-label .Select-control .Select-input {
  max-height: 31px !important;
}
.summary-view-go-round-table .go-round-field-array .input-cell .no-label .Select-control .Select-placeholder, .summary-view-go-round-table .go-round-field-array .input-cell .no-label .Select-control .Select-value {
  letter-spacing: normal !important;
  word-spacing: normal !important;
  color: #242424 !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
}
.summary-view-go-round-table .go-round-field-array .input-cell .no-label .Select-control .Select-placeholder {
  opacity: 0.6 !important;
}
.summary-view-go-round-table .go-round-field-array .input-cell .no-label input {
  font-size: 14px !important;
  height: unset;
}
.summary-view-go-round-table .go-round-field-array .input-cell .no-label input::placeholder {
  color: #242424 !important;
  text-transform: uppercase !important;
  opacity: 0.6 !important;
}

.nominationLogsTable td {
  font-size: 14px;
}

.card-info-label, .total-to-pay-label {
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0 10px 0;
  display: block;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.8em;
  font-family: Barlow, sans-serif;
  line-height: 1;
}

.card-info-wrp {
  display: block;
  padding-bottom: 15px;
}

.total-fee-wrp {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}

.athlete-dropdown-from-control, .event-dropdown-from-control, .discipline-dropdown-from-control, .segment-dropdown-from-control, .performance-dropdown-from-control, .coupon-dropdown-from-control {
  display: block;
  padding-bottom: 15px !important;
}

.nomination-v2 {
  padding-top: 20px;
  padding-left: 0;
}
.nomination-v2 .action-content {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.nomination-v2 .action-content button {
  margin: 5px;
}
.nomination-v2 .action-content a {
  text-decoration: none;
}
.nomination-v2 .form-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 53px;
  line-height: 64px;
}
.nomination-v2 .main-grid {
  padding: 3px;
}
.nomination-v2 .card {
  padding: 20px;
}
.nomination-v2 .card h3 {
  font-weight: 700 !important;
  font-size: 23px !important;
  padding-bottom: 20px;
  letter-spacing: 2px;
}
.nomination-v2 .athlete-dropdown-from-control, .nomination-v2 .event-dropdown-from-control, .nomination-v2 .discipline-dropdown-from-control, .nomination-v2 .segment-dropdown-from-control, .nomination-v2 .performance-dropdown-from-control, .nomination-v2 .coupon-dropdown-from-control, .nomination-v2 .card-dropdown-from-control, .nomination-v2 .type-amount-control {
  display: block;
  padding-bottom: 15px !important;
}
.nomination-v2 .athlete-dropdown-from-control label, .nomination-v2 .event-dropdown-from-control label, .nomination-v2 .discipline-dropdown-from-control label, .nomination-v2 .segment-dropdown-from-control label, .nomination-v2 .performance-dropdown-from-control label, .nomination-v2 .coupon-dropdown-from-control label, .nomination-v2 .card-dropdown-from-control label, .nomination-v2 .type-amount-control label {
  z-index: 1;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574 !important;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control, .nomination-v2 .type-amount-control .Select--single .Select-control {
  height: 58px;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper, .nomination-v2 .type-amount-control .Select--single .Select-control .Select-multi-value-wrapper {
  padding-top: 20px;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value, .nomination-v2 .type-amount-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value {
  padding-top: 25px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #242424;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder, .nomination-v2 .type-amount-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder {
  padding-top: 23px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.704px;
  text-transform: uppercase;
  color: #9D9D9D;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input, .nomination-v2 .type-amount-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input {
  position: absolute;
  width: 100%;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input, .nomination-v2 .type-amount-control .Select--single .Select-control .Select-multi-value-wrapper .Select-input input {
  width: -moz-available !important;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control .Select-clear, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control .Select-clear, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control .Select-clear, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control .Select-clear, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control .Select-clear, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control .Select-clear, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control .Select-clear, .nomination-v2 .type-amount-control .Select--single .Select-control .Select-clear {
  margin-top: 16px;
}
.nomination-v2 .athlete-dropdown-from-control .Select--single .Select-control .Select-arrow, .nomination-v2 .event-dropdown-from-control .Select--single .Select-control .Select-arrow, .nomination-v2 .discipline-dropdown-from-control .Select--single .Select-control .Select-arrow, .nomination-v2 .segment-dropdown-from-control .Select--single .Select-control .Select-arrow, .nomination-v2 .performance-dropdown-from-control .Select--single .Select-control .Select-arrow, .nomination-v2 .coupon-dropdown-from-control .Select--single .Select-control .Select-arrow, .nomination-v2 .card-dropdown-from-control .Select--single .Select-control .Select-arrow, .nomination-v2 .type-amount-control .Select--single .Select-control .Select-arrow {
  margin-top: 25px;
}
.nomination-v2 .athlete-dropdown-from-control label {
  padding-top: 10px;
}
.nomination-v2 .type-amount-control div div:before {
  border-bottom: 1px #ddd solid !important;
}
.nomination-v2 .type-amount-control div .div:hover {
  border-bottom: 1px #ddd solid !important;
}
.nomination-v2 .fieldset-checkbox {
  margin-bottom: 10px;
}
.nomination-v2 .fieldset-checkbox span {
  padding-top: 0;
  padding-bottom: 0;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}
.nomination-v2 .type-amount-control {
  margin-top: 5px;
}
.nomination-v2 .type-amount-control label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
  font-size: 1rem;
  position: absolute;
  z-index: 2;
}
.nomination-v2 .type-amount-control input {
  margin-left: 10px;
  margin-top: 13px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  text-transform: capitalize;
  color: #242424;
}
.nomination-v2 .type-amount-control div {
  height: 60px;
  background: #f2f4f7;
}
.nomination-v2 .card-info-wrp .brand-card {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  margin-top: 20px;
  margin-left: 11px;
}
.nomination-v2 .card-info-wrp .brand-card.mastercard {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/MasterCard.png");
}
.nomination-v2 .card-info-wrp .brand-card.americanexpress, .nomination-v2 .card-info-wrp .brand-card.amex {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/AmericanExpress.png");
}
.nomination-v2 .card-info-wrp .brand-card.dinersclub, .nomination-v2 .card-info-wrp .brand-card.diners {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/DinersClub.png");
}
.nomination-v2 .card-info-wrp .brand-card.discover {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/Discover.png");
}
.nomination-v2 .card-info-wrp .brand-card.jcb {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/JCB.png");
}
.nomination-v2 .card-info-wrp .brand-card.unionpay {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/UnionPay.png");
}
.nomination-v2 .card-info-wrp .brand-card.visa {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/Visa.png");
}
.nomination-v2 .card-info-wrp .card-form-control {
  padding-top: 0 0 5px 0;
  background: #f2f4f7;
  padding-bottom: 10px;
  border-radius: 5px 5px 0px 0px;
}
.nomination-v2 .card-info-wrp .card-form-control .form-label-bold {
  margin-top: 4px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
}
.nomination-v2 .card-info-wrp .card-form-control .card-input {
  margin-top: 25px;
  margin-left: 8px;
}
.nomination-v2 .card-info-wrp .card-form-control .card-input.show-brand {
  margin-left: 46px;
}
.nomination-v2 .nomination-count-container {
  font-size: 20px;
  font-weight: 700;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.nomination-v2 .nomination-count-container .nomination-count-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  color: #222F3E;
  align-items: center;
  letter-spacing: 2px;
}
.nomination-v2 .nomination-count-container .nomination-count-input-container {
  align-items: center;
}
.nomination-v2 .nomination-count-container .nomination-count-input-container button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.nomination-v2 .nomination-count-container .nomination-count-input-container button span {
  font-size: 24px;
  letter-spacing: 2px;
}
.nomination-v2 .nomination-count-container .nomination-count-input-container button svg {
  font-size: 16px;
}
.nomination-v2 .nomination-index-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 2px;
  color: #222F3E;
}
.nomination-v2 .summary h3 {
  margin: 0;
  padding: 5px 0 5px 0;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #222F3E;
}
.nomination-v2 .summary .summary-item {
  display: flex;
  align-items: stretch;
  width: 100%;
  color: #000;
  flex-wrap: wrap;
  padding: 1px 0 1px 0;
}
.nomination-v2 .summary .summary-item span {
  padding: 0;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.4px;
  color: #11181F;
}
.nomination-v2 .summary .summary-item span.item-label {
  flex: 1 1;
}
.nomination-v2 .summary .summary-item span:nth-of-type(2) {
  text-align: right;
}
.nomination-v2 .total-fee-wrp {
  display: flex;
  align-items: stretch;
  width: 100%;
  color: #000;
  flex-wrap: wrap;
  padding: 0;
}
.nomination-v2 .total-fee-wrp hr {
  width: 100%;
  color: black;
  flex: auto;
  height: 1px;
  margin: 0;
}
.nomination-v2 .total-fee-wrp span {
  flex: 1;
  padding: 0;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.968px;
  text-transform: uppercase;
  color: #11181F;
}
.nomination-v2 .total-fee-wrp span:nth-of-type(2) {
  text-align: right;
}

.nomination-refund {
  padding-top: 20px;
  padding-left: 0;
}
.nomination-refund .action-content {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.nomination-refund .action-content button {
  margin: 5px;
}
.nomination-refund .action-content a {
  text-decoration: none;
}
.nomination-refund .form-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 53px;
  line-height: 64px;
}
.nomination-refund .main-grid {
  padding: 3px;
}
.nomination-refund .card {
  padding: 20px;
}
.nomination-refund .card h3 {
  font-family: "IBM Plex Sans" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 29px;
  text-transform: uppercase;
  color: #000000;
  padding-bottom: 14px;
}
.nomination-refund .payment-detail-card {
  margin-top: 16px;
}
.nomination-refund .payment-detail-card .nomination-field {
  margin-bottom: 5px;
}
.nomination-refund .payment-detail-card .nomination-field .label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  margin-right: 5px;
}
.nomination-refund .payment-detail-card .nomination-field .value {
  font-family: "Barlow";
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}
.nomination-refund .nomination-details-card .nomination-field {
  margin-bottom: 5px;
}
.nomination-refund .nomination-details-card .nomination-field .label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  margin-right: 5px;
}
.nomination-refund .nomination-details-card .nomination-field .value {
  font-family: "Barlow";
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}
.nomination-refund .refund-details-card .card-subtitle {
  width: 408px;
  height: 25px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
  margin-bottom: 5px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control {
  margin-top: 5px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .Select-control {
  height: 58px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .Select-control .Select-placeholder {
  margin-top: 15px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .Select-control .Select-value {
  margin-top: 20px;
  position: absolute;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .Select-control .Select-value span {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  text-transform: capitalize;
  color: #242424 !important;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .Select-control .Select-clear {
  margin-top: 3px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .form-label-bold {
  position: absolute;
  z-index: 1;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
  margin-top: 10px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options {
  margin-top: 10px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .radio-group-label {
  display: inline-block;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.704px;
  text-transform: uppercase;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options label span:first-of-type {
  color: #2E86DE;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-method-item {
  display: inline-block;
  padding: 5px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-method-item .form-input-control div::before {
  border-bottom-style: solid;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-method-item .form-input-control label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division-item {
  padding: 5px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division-item .form-input-control div::before {
  border-bottom-style: solid;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division-item .form-input-control label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division-item span {
  margin-top: 15px;
  margin-bottom: 25px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-to-cc {
  width: 15%;
  height: 59px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-to-cc div {
  margin-top: 8px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-to-cc div::before {
  border-bottom-style: solid;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-to-cc label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
  margin-top: 5px;
  margin-left: 5px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-to-cc span {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  text-transform: capitalize;
  color: #242424;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division {
  width: 20%;
  height: 59px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division div {
  margin-top: 8px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division div::before {
  border-bottom-style: solid;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
  margin-top: 5px;
  margin-left: 5px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .refund-division span {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  text-transform: capitalize;
  color: #242424;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .reason-to-refund {
  width: 85%;
  background: #F4F5F8;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 25px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .reason-to-refund.division {
  width: 80%;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .reason-to-refund input {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.704px;
  color: #242424;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .reason-to-refund input::placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.704px;
  color: #242424;
  opacity: 0.5;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .reason-to-refund input:-ms-input-placeholder, .nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .reason-to-refund input::-ms-input-placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.704px;
  color: #242424;
  opacity: 0.5;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .coupon-info {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #242424;
  text-transform: capitalize;
  background: #F4F5F8;
  border-radius: 5px;
  padding: 10px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .info {
  background: #8395A7;
  font-style: normal;
  font-family: Barlow;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #FFFFFF;
  border-radius: 5px;
  height: 81px;
  line-height: 25px;
  text-align: left;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .info .span {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.968000114px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .info p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.7039999962px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .details {
  padding-bottom: 30px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .details fieldset span {
  color: #000000;
  font-family: IBM Plex Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .details fieldset svg {
  color: #2e86de;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .details .nomination-field .label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  margin-right: 5px;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container .multi-nomination .details .nomination-field .value {
  font-family: "Barlow";
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}
.nomination-refund .refund-details-card .refund-dropdown-form-control .refund-options .refund-container p {
  margin-left: 5px;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  height: 21px;
  opacity: 0.4;
}

.nominations-form-control-switch span {
  font-size: 1.3em !important;
  text-transform: uppercase;
}
.nominations-form-control-switch span:nth-child(2) {
  padding-left: 0.7em;
}

.nominations-switch {
  width: 84px;
}
.nominations-switch span.MuiSwitch-icon-146 {
  height: 30px;
  width: 30px;
}
.nominations-switch span.MuiSwitch-bar-153 {
  width: 48px;
  height: 20px;
  border-radius: 14px;
  top: 46%;
}
.nominations-switch .MuiSwitch-checked-149 {
  transform: translateX(27px);
}

.action-buttons {
  display: flex;
  align-items: center;
}
.action-buttons .link-refund {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebeb;
  background-color: #ebebeb;
  border-radius: 30px;
  margin-right: 20px;
  margin-left: 5px;
  cursor: pointer;
}
.action-buttons .link-refund .refund-icon {
  padding: 6px;
  border-radius: 50px;
  background-color: white;
}
.action-buttons .link-refund .refund-label {
  margin-left: 10px;
  margin-right: 20px;
}

.confirm-modal div:nth-child(2) div:first-child {
  overflow-y: visible;
}
.confirm-modal div:nth-child(2) h6 {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.968px;
  color: #242424;
}
.confirm-modal div:nth-child(2) .sub-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}
.confirm-modal div:nth-child(2) .data .title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}
.confirm-modal div:nth-child(2) .data .value {
  font-family: "Barlow";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}
.confirm-modal div:nth-child(2) .note {
  margin-top: 20px;
}
.confirm-modal div:nth-child(2) .note .title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}
.confirm-modal div:nth-child(2) .note .value {
  font-family: "Barlow";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #000000;
}

.confirm-refund-modal div:nth-child(2) div:first-child {
  overflow-y: visible;
}
.confirm-refund-modal .filled-default-input {
  padding-top: 7px;
}
.confirm-refund-modal .filled-default-input .Select-control {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.confirm-remove-modal .reason-content {
  background: #f4f5f8;
  border-radius: 5px 5px 0 0;
  margin-top: 25px;
}
.confirm-remove-modal .reason-content label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
  margin-left: 5px;
}
.confirm-remove-modal .reason-content .form-input-control input {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.704px;
  color: #242424;
  height: 25px;
  margin-left: 5px;
  margin-top: 4px;
}

.nominations-header-buttons {
  height: 40px;
  margin-top: 20px;
  display: inline-block;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.nominations-header-buttons button:focus {
  outline: none;
}
.nominations-header-buttons .export-nominations-btn {
  position: absolute;
  right: 25px;
}

.table-nomination .dark-table-head td {
  padding-left: 15px;
}
.table-nomination tbody tr {
  padding-left: 15px !important;
}
.table-nomination tbody tr th {
  padding-left: 15px !important;
}
.table-nomination tbody tr td p {
  padding-left: 10px;
}
.table-nomination tbody tr td p .column-no-border-botton {
  padding-left: 0;
  border: 0;
}
.table-nomination .table-cell-filter label {
  width: 120px;
}

.valid-between-column {
  width: 350px;
  text-align: right;
}

.table-first-column {
  max-width: 300px;
}

.table-last-column {
  min-width: 400px;
  padding-right: 40px;
}

.invalid-percentage {
  color: red;
  font-family: "IBM Plex Sans Regular";
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 1rem;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.refund-data {
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: #F4F5F8;
  border-radius: 5px;
  padding: 0;
  margin: 0;
}
.refund-data p {
  text-align: right;
  padding: 5px;
  padding-top: 0;
  padding-bottom: 0;
  color: #242424;
  font-size: 13px;
  font-family: Barlow;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.968px;
}
.refund-data p span {
  font-size: 18px;
  font-weight: bolder;
}

.full-name-refund legend {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-size: 16px;
  font-family: Barlow;
  line-height: 25px;
  letter-spacing: 0.704px;
  color: #242424;
}

.btn-performance-draw {
  height: 38px;
  width: 38px;
  text-transform: uppercase;
  border: 2px solid #E9E9E9;
  border-radius: 3em;
  cursor: pointer;
  font-size: 21px;
  color: #8395A7;
  font-family: "IBM Plex Sans";
  font-weight: bold;
  text-align: center;
}
.btn-performance-draw.not {
  border: 2px dotted #C8D6E5;
  font-size: 0.8em;
}
.btn-performance-draw.selected {
  background-color: #8395A7;
  color: #FFFFFF;
  font-size: 16px;
}
.btn-performance-draw.selected.first {
  background-color: #32936F;
}
.btn-performance-draw.selected.second {
  background-color: #2E86DE;
}
.btn-performance-draw.selected.third {
  background-color: #FF9F43;
}
.btn-performance-draw.selected:hover {
  background-color: #EE5253 !important;
}
.btn-performance-draw.selected:hover span {
  display: none;
}
.btn-performance-draw.selected:hover:before {
  content: "✕";
}
.btn-performance-draw.unavailable {
  background-color: #EE5253;
  color: #FFFFFF;
  font-size: 16px;
}

.perf-table-row .selected p {
  border-bottom: 0.2em solid #8395A7;
}
.perf-table-row .selected.first {
  border-bottom: 0.2em solid #32936F;
}
.perf-table-row .selected.second {
  border-bottom: 0.2em solid #2E86DE;
}
.perf-table-row .selected.third {
  border-bottom: 0.2em solid #FF9F43;
}

.table-cell-listing.pad-right-10 {
  padding-right: 10px;
}

.performance-draw-guide .btn-performance-draw {
  height: 32px !important;
  width: 32px !important;
}
.performance-draw-guide button {
  pointer-events: none;
  border: none;
  margin-bottom: 0.3em;
  margin-top: 0.3em;
  margin-right: 0.3em;
}

.title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.input-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gift-card-box-10, .gift-card-box-9, .gift-card-box-8, .gift-card-box-7, .gift-card-box-6, .gift-card-box-5, .gift-card-box-4, .gift-card-box-3, .gift-card-box-2, .gift-card-box-1 {
  width: 74px;
  height: 74px;
  margin-right: 1.5em;
  border-radius: 6px;
}

.gift-card-box-1 {
  left: 452px;
  background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
}

.gift-card-box-2 {
  left: 548px;
  background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
}

.gift-card-box-3 {
  left: 644px;
  background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
}

.gift-card-box-4 {
  left: 740px;
  background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
}

.gift-card-box-5 {
  left: 836px;
  background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
}

.gift-card-box-6 {
  left: 836px;
  background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
}

.gift-card-box-7 {
  left: 836px;
  background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
}

.gift-card-box-8 {
  left: 836px;
  background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
}

.gift-card-box-9 {
  left: 836px;
  background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
}

.gift-card-box-10 {
  left: 836px;
  background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
}

.line {
  border: 0.1px solid #9d9d9d;
}

.gift-card-border {
  border: 2px solid #10ac84;
  border-radius: 6px;
}

.box-svg-wrapper {
  position: relative;
  margin-top: 10px;
}
.box-svg-wrapper .check-icon {
  position: absolute;
  top: -10px;
  right: 10px;
}

.balance-text {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}

.delete-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 10px;
  width: 82px;
  height: 36px;
  border-style: none;
  margin-left: 10px;
  font-weight: 800;
  background: #ee5253;
  box-shadow: 0px 2px 5px rgba(17, 24, 31, 0.3);
  border-radius: 3px;
}

.delete-button-text {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.delete-dialoge-title {
  width: 552px;
  height: 16px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.484px;
  color: #000000;
}

.gift-card-field-wrapper {
  margin-top: 8px;
  margin: 5px;
}

.gift-preview {
  width: 1440px;
}
.gift-preview .gift-preview-item {
  margin-top: 10px;
  margin-bottom: 10px;
}
.gift-preview .gift-preview-item span {
  margin: 5px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
}
.gift-preview .gift-preview-item .value {
  font-weight: normal;
  text-transform: uppercase;
}
.gift-preview .gift-preview-item p {
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: justify;
  margin: 5px;
  text-transform: capitalize;
  width: 550px;
  word-wrap: break-word;
}

.gift-card-dialog-title {
  display: inline-block;
  width: 78%;
  margin-left: 26px;
}

.gift-card-add-balance-dialog-title {
  display: inline-block;
  width: 78%;
  margin-left: 26px;
  padding-top: 18px;
  font-family: Barlow;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}

.gift-card-cross-icon {
  display: inline-block;
  width: 7%;
  cursor: pointer;
  margin-top: 8px;
}

.add-balance-action-content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.add-balance-action-content button {
  position: inherit;
  margin-bottom: 20px;
  margin-left: 10px;
}
.add-balance-action-content button:last-of-type {
  margin-right: 30px;
}

.gift-card-dialog-wrapper {
  position: relative;
  margin-top: 10px;
}
.gift-card-dialog-wrapper .gift-card-box-icon {
  position: absolute;
  top: 19px;
  right: 162px;
  opacity: 0.3;
}
.gift-card-dialog-wrapper .gift-card-cart-icon {
  position: absolute;
  top: 316px;
  right: 30px;
}
.gift-card-dialog-wrapper .gift-card-vrq-icon {
  position: absolute;
  top: 22.32px;
  right: 22px;
}
.gift-card-dialog-wrapper .gift-card-name {
  position: absolute;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 140px;
  right: 132px;
  left: 24px;
  width: 90%;
  height: 69px;
  max-height: 62px;
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
  line-height: 30px;
}
.gift-card-dialog-wrapper .gift-card-balance {
  position: absolute;
  top: 230px;
  right: 62px;
  left: 24px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}
.gift-card-dialog-wrapper .gift-card-cost {
  position: absolute;
  top: 318px;
  left: 24px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}
.gift-card-dialog-wrapper .line-separator {
  position: absolute;
  border: 1px #ffffff;
  top: 300px;
  right: 166px;
}
.gift-card-dialog-wrapper .gift-card-rectangle-box10, .gift-card-dialog-wrapper .gift-card-rectangle-box9, .gift-card-dialog-wrapper .gift-card-rectangle-box8, .gift-card-dialog-wrapper .gift-card-rectangle-box7, .gift-card-dialog-wrapper .gift-card-rectangle-box6, .gift-card-dialog-wrapper .gift-card-rectangle-box5, .gift-card-dialog-wrapper .gift-card-rectangle-box4, .gift-card-dialog-wrapper .gift-card-rectangle-box3, .gift-card-dialog-wrapper .gift-card-rectangle-box2, .gift-card-dialog-wrapper .gift-card-rectangle-box1 {
  box-sizing: border-box;
  width: 285px;
  height: 364px;
  border-radius: 14px;
}
.gift-card-dialog-wrapper .gift-card-rectangle-box1 {
  background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box2 {
  background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box3 {
  background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box4 {
  background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box5 {
  background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box6 {
  background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box7 {
  background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box8 {
  background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box9 {
  background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
}
.gift-card-dialog-wrapper .gift-card-rectangle-box10 {
  background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
}

.gift-card-private-dialog-wrapper {
  position: relative;
  height: 530px;
  margin-top: 10px;
}
.gift-card-private-dialog-wrapper .gift-card-private-box-icon {
  position: absolute;
  top: 19px;
  right: 162px;
  opacity: 0.3;
}
.gift-card-private-dialog-wrapper .gift-card-private-vrq-icon {
  position: absolute;
  top: 22.32px;
  right: 22px;
}
.gift-card-private-dialog-wrapper .gift-card-private-name {
  position: absolute;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 156px;
  right: 132px;
  left: 24px;
  width: 249px;
  height: 69px;
  max-height: 53px;
  font-weight: 700;
  font-size: 26px;
  line-height: 25px;
  color: #ffffff;
}
.gift-card-private-dialog-wrapper .gift-card-private-balance {
  position: absolute;
  top: 210px;
  right: 62px;
  left: 24px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box10, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box9, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box8, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box7, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box6, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box5, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box4, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box3, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box2, .gift-card-private-dialog-wrapper .gift-card-private-rectangle-box1 {
  box-sizing: border-box;
  width: 285px;
  height: 241px;
  border-radius: 14px;
  margin-bottom: -8px;
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box1 {
  background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box2 {
  background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box3 {
  background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box4 {
  background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box5 {
  background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box6 {
  background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box7 {
  background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box8 {
  background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box9 {
  background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
}
.gift-card-private-dialog-wrapper .gift-card-private-rectangle-box10 {
  background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
}

.gift-card-add-balance-dialog-wrapper {
  position: relative;
  width: 602px;
  height: 307px;
  margin-top: 10px;
}
.gift-card-add-balance-dialog-wrapper hr {
  width: 555px;
  margin-left: 0;
  margin-top: 0px;
  margin-bottom: 16px;
}
.gift-card-add-balance-dialog-wrapper hr:first-of-type {
  margin-top: 10px;
}
.gift-card-add-balance-dialog-wrapper hr:nth-last-of-type(2) {
  margin-bottom: 0;
}
.gift-card-add-balance-dialog-wrapper p {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.7px;
  text-align: left;
  color: #A2A2A2;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content {
  display: grid;
  padding-left: 0;
  padding-top: 5px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .reason-control {
  width: 550px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .reason-control .addorment {
  margin-top: 22px;
  margin-left: 22px;
  color: #A2A2A2;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .reason-control div::before {
  border: none !important;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .reason-control div::after {
  border: none;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .form-input-control {
  width: 520px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .form-input-control label {
  font-family: Barlow;
  font-size: 20px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.48px;
  text-align: left;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .prop {
  font-family: Barlow;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.48px;
  text-align: left;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content .value {
  padding-left: 10px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline {
  display: inline-flex;
  padding-left: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .square-color {
  margin-right: 12px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .balance-control label {
  font-family: Barlow;
  font-size: 20px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.48px;
  text-align: left;
  margin: 0;
  color: black;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .balance-control {
  width: 520px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .balance-control input {
  margin-top: 20px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .balance-control div::before {
  border: none !important;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .balance-control div::after {
  border: none;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .prop {
  font-family: Barlow;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.48px;
  text-align: left;
  margin-top: 7px;
}
.gift-card-add-balance-dialog-wrapper .prop-and-value-content-inline .value {
  padding-left: 10px;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-top: 3px;
}
.gift-card-add-balance-dialog-wrapper .metallicGold, .gift-card-add-balance-dialog-wrapper .goldDrop, .gift-card-add-balance-dialog-wrapper .mulberry, .gift-card-add-balance-dialog-wrapper .lightSeaGreen, .gift-card-add-balance-dialog-wrapper .christi, .gift-card-add-balance-dialog-wrapper .ash, .gift-card-add-balance-dialog-wrapper .lightSlateBlue, .gift-card-add-balance-dialog-wrapper .goldenPoppy, .gift-card-add-balance-dialog-wrapper .navyBlue, .gift-card-add-balance-dialog-wrapper .red {
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  margin-right: 20px;
}
.gift-card-add-balance-dialog-wrapper .red {
  background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
}
.gift-card-add-balance-dialog-wrapper .navyBlue {
  background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
}
.gift-card-add-balance-dialog-wrapper .goldenPoppy {
  background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
}
.gift-card-add-balance-dialog-wrapper .lightSlateBlue {
  background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
}
.gift-card-add-balance-dialog-wrapper .ash {
  background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
}
.gift-card-add-balance-dialog-wrapper .christi {
  background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
}
.gift-card-add-balance-dialog-wrapper .lightSeaGreen {
  background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
}
.gift-card-add-balance-dialog-wrapper .mulberry {
  background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
}
.gift-card-add-balance-dialog-wrapper .goldDrop {
  background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
}
.gift-card-add-balance-dialog-wrapper .metallicGold {
  background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
}

.gift-card-scan-wrapper {
  background-color: #f6f6f6;
  height: 280px;
  position: relative;
  border-radius: 0px 0px 15px 15px;
  border: 1px solid #dddddd;
}
.gift-card-scan-wrapper .gift-card-scan-code-title {
  position: absolute;
  text-align: center;
  top: 70px;
  left: 55px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.gift-card-scan-wrapper .gift-card-accountNumber {
  position: absolute;
  text-align: center;
  top: 50px;
  left: 85px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.gift-card-scan-wrapper .gift-card-pin {
  position: absolute;
  text-align: center;
  top: 120px;
  left: 80px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.gift-card-scan-wrapper .gift-card-accountNumber-title {
  position: absolute;
  text-align: center;
  top: 30px;
  margin-left: -85px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.gift-card-scan-wrapper .gift-card-pin-title {
  position: absolute;
  text-align: center;
  top: 30px;
  left: 40px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.download-button {
  top: -15px;
  left: 85px;
  right: 4px;
}

.cancel-button {
  top: -15px;
  left: 75px;
  right: 5px;
}

.gift-card-download-rectangle-box10, .gift-card-download-rectangle-box9, .gift-card-download-rectangle-box8, .gift-card-download-rectangle-box7, .gift-card-download-rectangle-box6, .gift-card-download-rectangle-box5, .gift-card-download-rectangle-box4, .gift-card-download-rectangle-box3, .gift-card-download-rectangle-box2, .gift-card-download-rectangle-box1 {
  box-sizing: border-box;
  width: 285px;
  height: 520px;
  border-radius: 14px;
  margin-bottom: -8px;
}

.gift-card-download-rectangle-box1 {
  background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
}

.gift-card-download-rectangle-box2 {
  background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
}

.gift-card-download-rectangle-box3 {
  background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
}

.gift-card-download-rectangle-box4 {
  background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
}

.gift-card-download-rectangle-box5 {
  background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
}

.gift-card-download-rectangle-box6 {
  background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
}

.gift-card-download-rectangle-box7 {
  background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
}

.gift-card-download-rectangle-box8 {
  background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
}

.gift-card-download-rectangle-box9 {
  background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
}

.gift-card-download-rectangle-box10 {
  background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
}

.download-dialog-wrapper {
  background: #ffffff;
  border-radius: 14px;
  width: 802px;
  height: 560px;
  position: absolute;
  left: -2500px;
}

.gift-card-download-name {
  position: absolute;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  top: 180px;
  right: 132px;
  left: 24px;
  width: 249px;
  height: 63px;
  max-height: 54px;
  font-weight: 700;
  font-size: 26px;
  line-height: 25px;
  color: #ffffff;
}

.gift-card-download-balance {
  position: absolute;
  top: 260px;
  right: 62px;
  left: 24px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}

.download-giftCard-no {
  position: absolute;
  text-align: center;
  top: 340px;
  right: 62px;
  left: 50px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700px;
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}

.download-giftCard-pin {
  position: absolute;
  text-align: center;
  top: 390px;
  right: 62px;
  left: 50px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700px;
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}

.download-gift-card-left-child {
  width: 40%;
  float: left;
  margin-top: -15px;
}

.download-gift-card-description {
  width: 60%;
  float: right;
  padding: 20px;
}

.gift-card-download-balance-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}

.gift-card-download-paragraph {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.terms-condition-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-top: 160px;
}

.stock-action-button {
  margin: 25px 25px 25px 15px !important;
}

.performance-collection {
  padding: 1em;
  border: 0.5px solid #F2F4F7;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 82%;
  z-index: 3;
  width: 16em;
  font-family: "IBM Plex Sans";
  color: #242424;
  font-size: 16px;
  letter-spacing: 0.7px;
}

.animal-event-down-arrow {
  position: relative;
  font-size: 9px;
  padding-left: 0.3em;
  color: #10AC84;
  cursor: pointer;
  border: 0px;
  background: transparent;
}

.table-cell-listing.no-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.modal-section-title {
  font-size: 1.2em;
  text-transform: uppercase;
}

.modal-section-subtitle {
  text-transform: uppercase;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
}

.event-creation-type {
  flex-direction: row !important;
  column-gap: 24px;
  padding-bottom: 14px;
}

.modal-subsection-title {
  font-size: 0.9em;
  font-weight: 100;
  text-transform: uppercase;
}

.saved-cards-section {
  color: #9D9D9D;
}
.saved-cards-section .card-info-wrp {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}
.saved-cards-section .card-description {
  padding-left: 10px;
  color: #333;
  display: inline-block;
  width: 87%;
}
.saved-cards-section .brand-card {
  margin-left: 0;
  width: 50px;
  height: 50px;
  display: inline-block;
}
.saved-cards-section .brand-card.mastercard {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/MasterCard.png");
}
.saved-cards-section .brand-card.americanexpress {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/AmericanExpress.png");
}
.saved-cards-section .brand-card.dinersclub {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/DinersClub.png");
}
.saved-cards-section .brand-card.discover {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/Discover.png");
}
.saved-cards-section .brand-card.jcb {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/JCB.png");
}
.saved-cards-section .brand-card.unionpay {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/UnionPay.png");
}
.saved-cards-section .brand-card.visa {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/cards/Visa.png");
}
.saved-cards-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.action-buttons {
  display: flex;
  align-items: center;
}
.action-buttons .link-refund {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebeb;
  background-color: #ebebeb;
  border-radius: 30px;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
}
.action-buttons .link-refund .refund-icon {
  padding: 6px;
  border-radius: 50px;
  background-color: white;
}
.action-buttons .link-refund .refund-label {
  margin-left: 10px;
  margin-right: 20px;
  color: #000000;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}
.action-buttons .deleted {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebeb;
  background: #F8CECE;
  border-radius: 22px;
  width: 79px;
  height: 28px;
  cursor: none;
}
.action-buttons .deleted .label {
  color: #000000;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}

.remove-entry-modal div:nth-child(2) div:first-child {
  overflow-y: visible;
}
.remove-entry-modal .title {
  font-size: 1.375rem;
  font-weight: bold;
  text-transform: uppercase;
}
.remove-entry-modal .filled-default-input {
  padding-top: 7px;
}
.remove-entry-modal .filled-default-input .Select-control {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.athlete-form-title h2 {
  font-size: 1.4em !important;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.97px;
}

.athlete-modal-content {
  max-height: 60vh;
}

.form-row {
  display: inline-flex;
  width: 100%;
}
.form-row div:nth-child(1) {
  margin-right: 1em !important;
}
.form-row div:nth-child(2) {
  margin-right: 1em !important;
}
.form-row .athlete-select .Select-value-label {
  font-size: 0.8em;
}
.form-row input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  text-transform: uppercase !important;
}
.form-row input::-moz-placeholder { /* Firefox 19+ */
  text-transform: uppercase !important;
}
.form-row input:-ms-input-placeholder { /* IE 10+ */
  text-transform: uppercase !important;
}
.form-row input:-moz-placeholder { /* Firefox 18- */
  text-transform: uppercase !important;
}
.form-row label {
  font-family: "IBM Plex Sans";
  font-weight: 100;
}
.form-row .athlete-photo {
  position: relative;
  top: 1.3em;
}
.form-row .athlete-photo div:nth-child(1) {
  background-color: #2E86DE;
  height: 2.7em;
  width: 2.7em;
}

.athlete-photo-circle {
  margin-top: -5px;
  height: 50px !important;
  width: 50px !important;
  border: 3px #f4f5f8 solid;
}

.btn-upload-event-image {
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  margin-top: 60px;
  margin-left: 15px !important;
  text-transform: uppercase;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.btn-upload-event-image .btn-upload-photo-icon {
  height: 18px;
  margin-bottom: 3px;
  vertical-align: middle;
}

.form-row-field:before {
  border-bottom: #C8D6E5 solid 1px !important;
}
.form-row-field.id-numbers {
  padding-top: 34px !important;
}

.edit-icon {
  cursor: pointer;
}

.athletes-layout-wrapper {
  position: relative;
}
.athletes-layout-wrapper .athletes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.athletes-layout-wrapper .athletes-header-buttons {
  height: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.athletes-layout-wrapper .athletes-header-buttons .text-athletes button:focus {
  outline: none;
}
.athletes-layout-wrapper .athletes-header-buttons button:focus {
  outline: none;
}

.merge-select-all {
  background: none;
  border: none;
  display: block;
  font-size: 1em;
  padding: 10px 0;
  text-transform: uppercase;
}
.merge-select-all:enabled {
  color: #2E86DE !important;
}
.merge-select-all:enabled:hover {
  opacity: 0.8;
  cursor: pointer;
}

.merge-icon {
  margin-bottom: 4px;
  margin-left: 12px;
  margin-right: 12px;
}

.merge-details, .merge-details-disabled {
  display: block;
  font-size: 0.9em;
  margin-top: -5px;
  padding-bottom: 10px;
  padding-left: 34px;
}

.merge-details-disabled {
  opacity: 0.38;
}

.merge-title {
  font-size: 1.25em;
  font-weight: bold;
  display: block;
}

.merge-row-title {
  padding-left: 12px;
}

.merge-different-field-container {
  font-size: 0.9em;
  font-weight: 300;
  margin: 12px;
  text-transform: uppercase;
}

.merge-selection-circle {
  color: #2E86DE !important;
}

.merge-buttons-container {
  float: right;
}

.merge-search-box {
  display: inline-block;
  max-width: 300px;
  width: 100%;
}

.athlete-details {
  margin-top: 64px;
}
.athlete-details h6 {
  height: 84px;
}

.admin-notes textarea {
  height: auto;
}

.grid-athlete-edit {
  margin-top: 20px !important;
}
.grid-athlete-edit .athlete-form-section {
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.grid-athlete-edit .athlete-form-section.title {
  font-size: 1.4em;
  padding-bottom: 20px;
  font-weight: 400;
}
.grid-athlete-edit .athlete-form-section.subtitle {
  padding-bottom: 20px;
  font-size: 1.1em;
}
.grid-athlete-edit .athlete-form-section.with-margin {
  margin-top: 40px;
}
.grid-athlete-edit .paper-content {
  padding: 22px 30px 25px 30px;
}
.grid-athlete-edit .paper-content.margin-bottom-20 {
  margin-bottom: 20px;
}
.grid-athlete-edit .paper-content.margin-top-20 {
  margin-top: 20px;
}
.grid-athlete-edit .paper-content .linked-accounts-cell {
  font-weight: bold;
}
.grid-athlete-edit .paper-content .nav-to-youth {
  color: black;
}
.grid-athlete-edit .buttons-control {
  padding-top: 25px;
  float: right;
}
.grid-athlete-edit .buttons-control button {
  margin-left: 5px;
}

.field-icon {
  margin-right: 8px;
  margin-left: 5px;
}

.table-cell-listing.bump-up {
  padding-bottom: 12px !important;
}
.table-cell-listing.short {
  width: 12%;
}
.table-cell-listing.shorter {
  width: 7%;
}
.table-cell-listing.shorter div:nth-child(1) {
  width: 100px;
}
.table-cell-listing.center div:nth-child(1) {
  align-items: center;
  margin-right: 1em;
}
.table-cell-listing.shorter {
  width: 7%;
}
.table-cell-listing.shorter div:nth-child(1) {
  width: 100px;
}
.table-cell-listing.center div:nth-child(1) {
  align-items: center;
  margin-right: 1em;
}

.Select-placeholder,
.Select-value {
  font-size: 14px !important;
}

.penalized {
  border: 2px dotted rgba(238, 82, 83, 0.5);
  background-color: rgba(238, 82, 83, 0.1);
}

.reride-row {
  background-color: #f2f4f7;
}

.reride-title {
  margin-left: 0.5rem;
}

.cancelled-reride th p,
.cancelled-reride th strike {
  color: rgba(0, 0, 0, 0.38) !important;
}

.advance-athlete-modal .subtitle {
  margin-top: 15px;
}
.advance-athlete-modal .athletes-container {
  margin-top: 35px;
  margin-bottom: 25px;
}
.advance-athlete-modal .athletes-container .athlete-line {
  border-bottom: 1px solid #C8D6E5;
  padding: 20px 5px 20px 5px;
}
.advance-athlete-modal .athletes-container .athlete-line p {
  text-transform: uppercase;
  font-size: 13px !important;
}
.advance-athlete-modal .athletes-container .athlete-line.header {
  padding: 5px;
  font-weight: bold;
}
.advance-athlete-modal .select-round .filled-input {
  padding-top: 0px !important;
}

.strikethrough-result {
  color: #ADADAD !important;
  text-decoration: line-through;
}

.nominated-athlete {
  line-height: 12px;
  padding-right: 5px;
  color: green;
  font-size: 2em;
}

.event-dates-column {
  width: 244px;
  text-align: right;
}

.tabs-paper {
  margin-top: 25px;
}
.tabs-paper.no-background {
  background-color: transparent;
  box-shadow: none;
}

.photo-upload-wrapper {
  width: 100px;
  height: 100px;
  float: left;
  padding: 2px;
  margin-right: 10px;
  border: 1px solid #e9e9e9;
}
.photo-upload-wrapper .avatar-wrp {
  border-radius: unset;
  width: 100%;
  height: 100%;
}

.btn-save-trade-performances {
  margin: 25px !important;
  width: 160px;
}

.menu-disciplines-trade-performances {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.menu-disciplines-trade-performances h3 {
  padding-left: 16px;
}

.disciplines-menu-form-control {
  margin-top: 5px !important;
  padding-left: 0 !important;
  width: 100% !important;
}
.disciplines-menu-form-control label {
  display: block !important;
  margin: 0;
}
.disciplines-menu-form-control label span {
  border-bottom: 1px solid #C8D6E5;
  height: auto !important;
  padding-left: 16px;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  color: #242424;
  font-size: 14px;
}
.disciplines-menu-form-control label.selected span {
  color: #10AC84;
  font-weight: bold;
}
.disciplines-menu-form-control label:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.grid-entry-pool-listing {
  width: 686px;
}
.grid-entry-pool-listing .paper-entry-pool-listing {
  padding: 0;
}
.grid-entry-pool-listing .paper-entry-pool-listing .gray-head-row {
  height: 50px !important;
  background-color: rgb(239, 239, 239);
}
.grid-entry-pool-listing .paper-entry-pool-listing .gray-head-row span {
  color: rgba(0, 0, 0, 0.54);
  padding: 20px;
  font-weight: normal;
  font-size: 11px;
}
.grid-entry-pool-listing .paper-entry-pool-listing .performance-row .perfomance-cell {
  display: flex;
  justify-content: space-between;
}
.grid-entry-pool-listing .paper-entry-pool-listing .performance-row .perfomance-cell .performance-name {
  font-weight: 700;
  font-size: 14px;
  color: #0F0F0F;
  padding: 0;
  margin: 0;
}
.grid-entry-pool-listing .paper-entry-pool-listing .performance-row .perfomance-cell .performance-detail {
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
}
.grid-entry-pool-listing .paper-entry-pool-listing .performance-row .perfomance-cell .performance-stock-counter {
  font-weight: 700;
  font-size: 14px;
  color: #0F0F0F;
  text-align: left;
  padding: 0;
  margin: 0;
}
.grid-entry-pool-listing .paper-entry-pool-listing .empty-stock-row p {
  text-align: center;
  color: #0F0F0F;
}
.grid-entry-pool-listing .paper-entry-pool-listing .footer-detail {
  display: flex;
  justify-content: space-between;
  padding: 0;
  vertical-align: middle;
  align-items: center;
}
.grid-entry-pool-listing .paper-entry-pool-listing .footer-detail p {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-right: 20px;
  margin: 0;
  font-family: Barlow, sans-serif;
}
.grid-entry-pool-listing .table-listing-with-scroll {
  position: relative;
}
.grid-entry-pool-listing .table-listing-with-scroll .table-body-with-scroll {
  display: block !important;
  max-height: 498px;
  overflow-y: scroll;
  display: table;
  width: 100%;
  table-layout: fixed;
}
.grid-entry-pool-listing .table-listing-with-scroll thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.grid-entry-pool-listing .table-listing-with-scroll tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.grid-entry-pool-listing .table-listing-with-scroll .filter-row {
  background-color: white;
  font-weight: normal;
}
.grid-entry-pool-listing .table-listing-with-scroll .filter-row.sticky {
  position: sticky;
  top: 0;
}
.grid-entry-pool-listing .table-listing-with-scroll .filter-row .input-container {
  width: 100%;
  padding: 10px 0 9px 26px;
}
.grid-entry-pool-listing .table-listing-with-scroll .filter-row .input-select {
  width: 150px;
}
.grid-entry-pool-listing .table-listing-with-scroll .filter-row input,
.grid-entry-pool-listing .table-listing-with-scroll .filter-row select {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
}
.grid-entry-pool-listing .table-listing-with-scroll .filter-row .filter-label {
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  margin-bottom: 5px;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #FFFFFF;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .table-body-with-scroll {
  height: 400px;
  overflow: auto;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .table-body-with-scroll .fixed {
  table-layout: fixed;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .filter-row {
  background-color: white;
  font-weight: normal;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .filter-row.sticky {
  position: sticky;
  top: 0;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .filter-row .input-select {
  width: 149px;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .filter-row input,
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .filter-row select {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .filter-row .filter-label {
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  margin-bottom: 5px;
}
.grid-entry-pool-listing .table-listing-with-scroll-event-animal .column-head {
  position: sticky;
  top: 0;
  z-index: 1;
}
.grid-entry-pool-listing .performance-container {
  min-width: 300px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.paper-stock-draw-form {
  padding: 0 !important;
}
.paper-stock-draw-form .Select-placeholder,
.paper-stock-draw-form .Select-value-label {
  color: #242424;
  font-family: "IBM Plex Sans";
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 100;
}
.paper-stock-draw-form .dark-table-head th {
  height: 17px;
  width: 60px;
  color: #FFFFFF;
  font-family: "IBM Plex Sans";
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 17px;
}
.paper-stock-draw-form .dark-table-head th:nth-child(1) {
  padding-left: 4em;
}
.paper-stock-draw-form .stock-draw-title {
  font-size: 26px;
  width: 50em;
  letter-spacing: 1.1px;
  font-family: "IBM Plex Sans";
  text-transform: uppercase;
  display: inline-flex;
  position: relative;
  top: 1em;
}
.paper-stock-draw-form .stock-draw-title .title-text {
  padding-left: 0.5em;
}
.paper-stock-draw-form .stock-draw-title .date {
  background-color: #F2F4F7;
  padding: 0.5em;
  margin-left: 1em;
  font-size: 18px;
  width: 18%;
  height: 2.5em;
}
.paper-stock-draw-form .stock-draw-title .stock-draw-discipline {
  background-color: #8395A7;
  color: #FFFFFF;
  padding: 0.5em;
  font-size: 13px;
  letter-spacing: 0.65px;
  border-radius: 1.3em;
  margin-left: 1.3em;
  height: fit-content;
  text-align: center;
  border-radius: 3em;
  align-self: center;
}
.paper-stock-draw-form .stock-draw-title .stock-draw-top-actions {
  text-align: right;
  width: auto;
  margin: auto;
  display: flex;
  align-items: center;
}
.paper-stock-draw-form .stock-draw-title .stock-draw-top-actions button {
  margin-right: 1em;
  background-color: #222e3e;
  height: 2.5em;
  width: 11em;
  border-radius: 3px;
  border-width: 0px;
  color: #FFFFFF;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: 100;
  cursor: pointer;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px -7px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  border-style: solid;
}
.paper-stock-draw-form .stock-draw-table-actions {
  display: flex;
  align-items: center;
  height: 6em;
}
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-actions {
  padding-left: 2em;
  display: inline-flex;
  line-height: 6em;
  padding-left: 2em;
}
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-actions button {
  background-color: #10AC84;
  height: 2.5em;
  width: 7em;
  border-radius: 3px;
  border-width: 0px;
  color: #FFFFFF;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: 100;
  cursor: pointer;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px -7px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  border-style: solid;
}
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-actions button:disabled {
  background-color: #8395A7 !important;
}
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-actions button:nth-child(1) {
  background-color: #8395A7 !important;
}
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-actions button:nth-child(2),
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-actions button:nth-child(3) {
  margin-left: 1em;
}
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-actions button:nth-child(3) {
  width: 17em;
  background-color: #222e3e;
}
.paper-stock-draw-form .stock-draw-table-actions .stock-draw-submission-date {
  padding-left: 2em;
  display: inline-flex;
  line-height: 6em;
  padding-left: 2em;
}
.paper-stock-draw-form .stock-draw-table-row {
  height: 6em;
}
.paper-stock-draw-form .stock-draw-table-row.dark {
  background-color: #C8D6E5;
}
.paper-stock-draw-form .stock-draw-table-row td:nth-child(1) {
  width: 12%;
  padding-left: 4em;
}
.paper-stock-draw-form .stock-draw-table-row td:nth-child(2),
.paper-stock-draw-form .stock-draw-table-row td:nth-child(3) {
  width: 27%;
}
.paper-stock-draw-form .stock-draw-table-row .reride-divider {
  font-size: 1.7em !important;
  font-family: "IBM Plex Sans";
  padding-left: 2.8em;
}
.paper-stock-draw-form .stock-draw-table-row .up-down-actions {
  position: absolute;
  right: 6em;
  margin-top: -1em;
}
.paper-stock-draw-form .stock-draw-table-row .up-down-actions button {
  width: 1.3em;
  height: 1.3em;
  color: #2E86DE;
}
.paper-stock-draw-form .stock-draw-table-row .up-down-actions button:nth-child(1) {
  margin-left: 0.8em;
}
.paper-stock-draw-form .stock-draw-field {
  margin-top: 0 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #F2F4F7;
  padding-top: 30px !important;
}
.paper-stock-draw-form .stock-draw-field div:nth-child(1):before, .paper-stock-draw-form .stock-draw-field div:nth-child(1):after {
  border-bottom: 1px solid #C8D6E5 !important;
}
.paper-stock-draw-form .stock-draw-field.long {
  width: 100%;
}
.paper-stock-draw-form .stock-draw-field.selected select {
  color: #0F0F0F;
}
.paper-stock-draw-form .stock-draw-field select {
  text-transform: uppercase;
  padding-left: 1em;
}
.paper-stock-draw-form .stock-draw-field svg {
  color: #10AC84;
}

.trade-performance-submission-date-wrp {
  display: block;
  padding: 25px;
  color: #9D9D9D;
  font-size: 0.9em;
}

.trade-saved-icon {
  margin-bottom: -6px;
  text-align: right;
}

.stock-draw,
.performance-results {
  text-transform: uppercase;
}
.stock-draw div,
.performance-results div {
  padding-bottom: 0.5em;
}
.stock-draw .btn-stock-draw,
.performance-results .btn-stock-draw {
  background: #FFFFFF;
  border-color: #8395A7;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 11px;
  margin-bottom: 1.5em;
  margin-right: 2em;
  margin-top: 1.5em;
  padding: 12px 15px;
  text-transform: uppercase;
}
.stock-draw .btn-stock-draw:hover,
.performance-results .btn-stock-draw:hover {
  background: #F2F4F7;
  cursor: pointer;
}
.stock-draw .btn-stock-draw:disabled,
.performance-results .btn-stock-draw:disabled {
  background: #F2F4F7;
  cursor: default;
  opacity: 0.5;
}
.stock-draw .btn-stock-draw.bg-dark-blue,
.performance-results .btn-stock-draw.bg-dark-blue {
  background-color: #222F3E;
  color: #FFFFFF;
}
.stock-draw .btn-stock-draw.state-1,
.performance-results .btn-stock-draw.state-1 {
  background-color: #FFFFFF;
}
.stock-draw .btn-stock-draw.state-2,
.performance-results .btn-stock-draw.state-2 {
  border: 1px solid #ff9f43;
}
.stock-draw .btn-stock-draw.state-3,
.performance-results .btn-stock-draw.state-3 {
  background-color: #10AC84;
  color: #FFFFFF;
  font-family: "IBM Plex Sans";
}
.stock-draw div:nth-child(1),
.performance-results div:nth-child(1) {
  padding-top: 1.1em;
}
.stock-draw h4,
.performance-results h4 {
  font-family: "IBM Plex Sans" !important;
  font-weight: 100 !important;
  font-size: 1.5em;
  letter-spacing: 0.97px !important;
  line-height: 25px;
  font-weight: lighter;
}
.stock-draw h5,
.performance-results h5 {
  font-family: "IBM Plex Sans" !important;
  font-size: 18px;
  letter-spacing: 0.79px !important;
  line-height: 25px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.stock-draw .shaded,
.stock-draw .shaded-dark,
.performance-results .shaded,
.performance-results .shaded-dark {
  background-color: #F2F4F7;
  padding: 0.4em;
  margin-right: 0.4em;
}
.stock-draw .shaded-dark,
.performance-results .shaded-dark {
  background-color: #576574;
  color: #FFFFFF;
}

.performance-status-guide .guide-state-3, .performance-status-guide .guide-state-2, .performance-status-guide .guide-state-1 {
  width: 30px;
  height: 30px;
  display: inline-flex;
  margin-right: 5px;
  border-radius: 3px;
  vertical-align: middle;
}

.performance-status-guide {
  display: grid;
  padding-top: 21px;
  font-size: 11px;
  font-weight: 500;
  text-transform: lowercase;
  vertical-align: top;
  margin-right: 30px;
}
.performance-status-guide .padBot-1 {
  padding-top: 10px;
  padding-left: 24px;
}
.performance-status-guide .content {
  padding: 10px;
  padding-left: 24px;
}
.performance-status-guide .content span {
  display: inline-flex;
  font-weight: 500;
  text-transform: uppercase;
  width: 85%;
  vertical-align: middle;
  margin-left: 5px;
}
.performance-status-guide .guide-state-1 {
  border: 1px solid black;
  background-color: #FFFFFF;
}
.performance-status-guide .guide-state-2 {
  border: 1px solid #ff9f43;
}
.performance-status-guide .guide-state-3 {
  background-color: #10AC84;
  font-family: "IBM Plex Sans";
}

.manage-results-button {
  padding: 10px;
}

.manage-results-buttons {
  display: flex;
  align-items: center;
}
.manage-results-buttons .wcra-green-cta {
  height: 30px;
}

.back-to-performances {
  border: none;
  background-color: inherit;
  margin-top: 2em;
  height: 17px;
  color: #2E86DE;
  font-family: "IBM Plex Sans";
  font-size: 15px;
  letter-spacing: 1.05px;
  line-height: 17px;
  cursor: pointer;
}

.checkmark,
.preference-eligible-check {
  color: #10AC84;
}

.disable-mark {
  color: #242424;
  margin-right: 5px;
  margin-bottom: -8px;
}

.disciplines-container hr {
  margin: 15px 0;
  border-top: 1px solid #C8D6E5;
  border-bottom: transparent;
}
.disciplines-container label {
  z-index: 1;
}
.disciplines-container .filled-input {
  height: 68px;
}
.disciplines-container .filled-input input {
  padding: 11px 10px;
}
.disciplines-container .dark-tooltip {
  color: #222F3E;
  display: flex;
  float: right;
  width: 18px;
  margin-left: 5px;
  margin-top: 0;
}
@media (max-width: 1440px) {
  .disciplines-container .dark-tooltip {
    margin-left: 5px;
    margin-top: 0px;
  }
}

.disciplines-container .is-disabled .event-discipline-field:before {
  border-bottom-style: solid;
}
.disciplines-container .is-disabled .filled-input-narrow,
.disciplines-container .is-disabled .filled-input {
  background: transparent !important;
}
.disciplines-container .is-disabled .filled-input-narrow:hover .Select-control,
.disciplines-container .is-disabled .filled-input:hover .Select-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.disciplines-container .manual-arrow::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.event-discipline-item {
  flex-wrap: nowrap !important;
  justify-content: space-between;
  padding-bottom: 8px;
}
.event-discipline-item.non-managed-no-entries {
  justify-content: flex-start;
}
.event-discipline-item .event-discipline-label {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.75px;
}
.event-discipline-item .event-discipline-label.non-managed-no-entries {
  padding: 34px 12px;
}
.event-discipline-item .add-class-button {
  white-space: nowrap;
  justify-content: flex-end;
}
.event-discipline-item .event-discipline-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.event-discipline-item .event-discipline-close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  max-width: 115px;
}
.event-discipline-item .event-discipline-close svg {
  cursor: pointer;
}
.event-discipline-item .event-discipline-close svg.disabled {
  cursor: default;
  opacity: 0.2;
}
.event-discipline-item .event-max-entries-field,
.event-discipline-item .event-discipline-field {
  margin-top: 0;
}
.event-discipline-item .event-max-entries-field {
  display: block;
  width: 100%;
}
.event-discipline-item .event-discipline-select {
  margin-top: -15px;
  text-transform: uppercase;
}
.event-discipline-item .event-discipline-select .Select-control {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.event-discipline-item .event-discipline-select .Select-control:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}
.event-discipline-item .event-discipline-select .is-disabled {
  background: none;
}
.event-discipline-item .event-discipline-select .is-disabled .Select-control .Select-value {
  pointer-events: none;
}
.event-discipline-item .event-discipline-elegible-nomination {
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1699px) {
  .event-discipline-item .event-discipline-elegible-nomination {
    justify-content: flex-start;
  }
}
.event-discipline-item .inline-checkbox {
  text-transform: uppercase;
}
.event-discipline-item .inline-checkbox > label span:first-child {
  padding: 3px 5px;
}
.event-discipline-item .checkmark {
  margin-right: 5px;
  margin-bottom: -8px;
}
.event-discipline-item .checkmark svg {
  fill: #10AC84;
}
@media (max-width: 1440px) {
  .event-discipline-item .checkmark {
    margin-bottom: 10px;
  }
}
.event-discipline-item .cell-description {
  font-weight: normal;
  padding-bottom: 3px;
}
.event-discipline-item .cell-description.finals {
  margin-left: 20px;
}
@media (max-width: 1440px) {
  .event-discipline-item .cell-description.semi-finals,
  .event-discipline-item .cell-description.finals {
    padding: 0;
  }
}
.event-discipline-item .sort-items {
  width: 65px;
  flex-basis: auto;
}
.event-discipline-item .sort-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-performance-button {
  margin-left: 5px !important;
  background-color: #FF5349 !important;
  color: #FFFFFF !important;
}

td.judge-selector-cell {
  padding: 4px 0;
}

.buttons-wrp {
  display: flex;
  align-items: center;
  gap: 10px;
}

.performances-header-non-managed.disciplines-with-classes {
  width: 26%;
}
.performances-header-non-managed.disciplines-without-classes {
  width: 68%;
}
.performances-header-non-managed.disciplines-without-classes.without-entries {
  width: 92%;
}
.performances-header-non-managed.preferences-with-classes {
  width: 24%;
}
.performances-header-non-managed.preferences-without-classes {
  width: 24%;
}
.performances-header-non-managed.classes {
  width: 42%;
}
.performances-header-non-managed.classes.without-entries {
  width: 66%;
}

.table-logs {
  text-transform: none;
}
.table-logs td span {
  color: rgba(0, 0, 0, 0.87);
}
.table-logs .log-table-cell-result span {
  text-transform: inherit;
}
.table-logs .log-table-cell-result .result-title {
  font-weight: bold;
  text-transform: capitalize;
}
.table-logs .log-table-cell-result ul {
  padding: 5px;
  padding-left: 15px;
  list-style-type: none;
}
.table-logs .log-table-cell-result ul li {
  font-family: Barlow, sans-serif;
  font-weight: 400;
  list-style-type: none;
  padding-bottom: 10px;
  line-height: 1.5;
}

.export-entries-dialog {
  width: 600px;
}
.export-entries-dialog h3 {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 23px;
}
.export-entries-dialog .notification-container.success {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.export-entries-title {
  padding-bottom: 10px !important;
}

.export-entries-header {
  text-transform: uppercase;
  font-weight: bold !important;
}

.export-entries-instructions {
  padding: 0px 24px 24px;
  margin-top: 0px;
  font-weight: lighter;
}

.export-entries-actions-container {
  padding-top: 36px;
}

.export-entries-content-container {
  overflow: visible !important;
}

.export-entries-paper {
  overflow: visible !important;
}

.purse-management .main-grid, .configure .main-grid {
  padding: 16px 24px;
}
.purse-management .main-grid.colored, .configure .main-grid.colored {
  background-color: #C9D6E7;
}
.purse-management .main-grid .item, .configure .main-grid .item {
  margin-bottom: 12px;
}
.purse-management .main-grid .item .centered, .configure .main-grid .item .centered {
  display: flex;
  justify-content: center;
  align-content: center;
}
.purse-management .main-grid .item.bordered, .configure .main-grid .item.bordered {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f2f4f7;
}
.purse-management .main-grid .item .title, .configure .main-grid .item .title {
  display: flex;
  align-items: center;
}
.purse-management .main-grid .item .title .remove-custom-icon, .configure .main-grid .item .title .remove-custom-icon {
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  cursor: pointer;
}
.purse-management .main-grid .item .input-item, .configure .main-grid .item .input-item {
  background-color: #C9D6E7;
  border: 1px solid #576475;
  border-radius: 5px;
  padding: 5px 10px;
}
.purse-management .main-grid .item .input-item::after, .purse-management .main-grid .item .input-item::before, .configure .main-grid .item .input-item::after, .configure .main-grid .item .input-item::before {
  content: unset;
}
.purse-management .main-grid .item .input-item.not-disabled, .configure .main-grid .item .input-item.not-disabled {
  background-color: white;
}

.configure .purse {
  padding-bottom: 0px;
}
.configure .purse .go-rounds {
  margin-top: 10px;
  margin-bottom: 20px;
}
.configure .purse .go-rounds select {
  font-size: 14px;
}
.configure .day-money {
  margin-top: -10px;
}
.configure .day-money .go-rounds {
  margin-top: 10px;
  margin-bottom: 20px;
}
.configure .day-money .go-rounds select {
  font-size: 14px;
}

.purse-management .payouts {
  padding-bottom: 0px;
  border-bottom: 1px solid #c9d6e7;
}
.purse-management .payouts .go-rounds {
  margin-top: 5px;
}
.purse-management .payouts .go-rounds select {
  font-size: 14px;
}
.purse-management .payouts .bordered {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #c9d6e7;
}
.purse-management .payouts .input-item-colored {
  background-color: #C9D6E7;
}
.purse-management .payouts .places {
  margin-top: 15px;
}
.purse-management .payouts .places .place-row {
  padding: 5px 12px;
}
.purse-management .payouts .places .place-row:nth-child(odd) {
  background-color: #f2f4f7;
}
.purse-management .payouts .places .place-row .place-cell {
  font-weight: lighter !important;
}
.purse-management .money-payouts .performances {
  margin-top: 15px;
}

.dashboard .segment-days-container {
  display: flex;
}
.dashboard .segment-days-container .segment-days-left {
  background: #8395A7;
  padding: 20px 25px;
  color: white;
  font-size: 0.9em;
  font-weight: normal;
  border-radius: 5px;
}
.dashboard .paper-bg-white {
  background-color: #FFFFFF !important;
}
.dashboard .dashboard-search {
  margin-top: -15px;
}
.dashboard .dashboard-search .dashboard-input-field {
  margin-top: 0px;
}
.dashboard .dashboard-search .dashboard-bt-run-search {
  margin-left: auto;
  margin-right: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dashboard .dashboard-search .dashboard-bt-run-search button {
  padding-left: 35px;
  padding-right: 35px;
}
.dashboard .dashboard-search .dashboard-input-select .Select-control {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.dashboard .dashboard-search .dashboard-input-select .Select-control:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}
.dashboard .dashboard-card {
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  background-color: #FFFFFF !important;
}
.dashboard .dashboard-card .dashboard-card-info {
  display: flex;
  flex-direction: column;
}
.dashboard .dashboard-card .dashboard-card-info.small {
  position: relative;
  height: 100%;
}
.dashboard .dashboard-card .dashboard-card-info .dashboard-card-title {
  font-size: 0.8rem !important;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: -25px;
  margin-top: 10px;
  text-align: center;
}
.dashboard .dashboard-card .dashboard-card-info .dashboard-card-title.small {
  position: absolute;
  top: 10px;
}
.dashboard .dashboard-card .dashboard-card-info .dashboard-card-number-container {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dashboard .dashboard-card .dashboard-card-info .dashboard-card-number {
  font-size: 4.3125rem !important;
  font-weight: bold;
  color: #2E86DE;
}
.dashboard .dashboard-card .dashboard-card-info .dashboard-card-number.small {
  font-size: 1rem !important;
}
.dashboard .dashboard-report-card {
  height: auto;
  padding: 0px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.dashboard .dashboard-report-card .paper-top-tabs {
  border-bottom: 1px solid rgb(224, 224, 224);
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.dashboard .dashboard-report-card .report-tab-panel {
  width: 100%;
}
.dashboard .dashboard-report-card .tableau-embed {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
}
.dashboard .dashboard-report-card .tableau-embed h3 {
  align-self: flex-start;
}

.dashboard-modal .modal-title {
  padding: 12px 24px 12px;
  background-color: #FFFFFF;
}
.dashboard-modal .modal-title h6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
}
.dashboard-modal .modal-title button {
  margin-left: 8px;
}
.dashboard-modal .modal-content {
  padding: 0;
}
.dashboard-modal .modal-content th, .dashboard-modal .modal-content td {
  padding-right: 24px;
}
.dashboard-modal .modal-content td {
  font-size: 1rem;
  text-transform: uppercase;
}
.dashboard-modal .athlete-image {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.add-association-main-modal .add-associations-modal {
  min-width: 600px;
}
.add-association-main-modal .add-associations-modal-v2 {
  padding: 20px;
  min-width: 700px;
}
.add-association-main-modal .add-associations-modal-v2 .alliance-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.add-association-main-modal .add-associations-modal-v2 .alliance-container .radios {
  padding-left: 7px;
  display: flex;
  flex-direction: row;
}
.add-association-main-modal .add-associations-modal .subtitle {
  font-size: 15px;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0px;
  line-height: 0px;
  display: block;
  margin-top: 15px;
}
.add-association-main-modal .add-associations-modal .add-new-association-modal-content {
  margin-top: 10px;
}
.add-association-main-modal .add-associations-modal .add-new-association-modal-content .add-association-input label {
  color: rgba(0, 0, 0, 0.54) !important;
}
.add-association-main-modal .add-associations-modal .add-new-association-modal-content .add-association-input .Select-control {
  margin-top: 12px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content {
  background-color: #FFFFFF;
  overflow-y: visible;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content div {
  color: #242424;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content.no-padding {
  padding: 0px !important;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content .event-dropdown-from-control {
  margin-top: 12px;
  display: block;
  padding-bottom: unset !important;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content .event-dropdown-from-control label {
  z-index: 1;
  margin-left: 8px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content .event-dropdown-from-control .Select--single .Select-control {
  border: 1px solid #9D9D9D;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper {
  padding-top: 20px;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-value {
  padding-top: 20px;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content .event-dropdown-from-control .Select--single .Select-control .Select-multi-value-wrapper .Select-placeholder {
  padding-top: 20px;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px !important;
}
.add-association-main-modal .add-associations-modal .add-association-modal-content .event-dropdown-from-control .Select--single .Select-control .Select-arrow-zone {
  padding-top: 20px;
}

.association-list {
  text-transform: uppercase;
}
.association-list .cell-result-level {
  min-width: 130px;
}
.association-list.in-modal {
  margin-top: 15px;
}
.association-list thead tr, .association-list tbody tr {
  height: 40px;
}
.association-list thead tr th, .association-list tbody tr th {
  color: #0F0F0F;
  font-weight: bold;
  padding: 15px 15px 4px 10px !important;
}
.association-list thead tr td, .association-list tbody tr td {
  padding: 4px 15px 4px 10px !important;
}
.association-list thead tr td .alliance-icon, .association-list tbody tr td .alliance-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #10AC84;
  color: #FFFFFF;
  font-weight: bold;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  margin-left: 25%;
}

.entry-athlete-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.entry-athlete-header .back-button {
  display: flex;
  text-decoration: none;
  align-self: center;
}
.entry-athlete-header div {
  display: flex;
  font-size: 15px;
  padding-top: 3px;
}
.entry-athlete-header div:focus {
  outline: none;
}
.entry-athlete-header svg {
  height: 22px;
}
.entry-athlete-header h2 {
  padding-left: 30px;
  text-transform: uppercase;
}
.entry-athlete-header *:nth-child(3) {
  margin-left: auto;
  margin-right: 30px;
}

.entry-pending-header {
  display: flex;
  background-color: #EE5253;
  justify-content: space-between;
  color: #FFFFFF;
  padding: 12px 24px;
  height: 3.125rem;
}
.entry-pending-header *:nth-child(1) {
  display: flex;
  align-items: center;
}
.entry-pending-header *:nth-child(1) .warning-icon {
  margin-right: 8px;
}
.entry-pending-header *:nth-child(1) .sub-text {
  font-weight: 300;
  padding-left: 8px;
}
.entry-pending-header-margin-top {
  margin-top: 1rem;
}

.athlete-entry-form .entry-cta-buttons-container {
  margin-top: 25px;
  text-align: right;
}
.athlete-entry-form .entry-cta-buttons-container button {
  width: 100px;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 300;
}
.athlete-entry-form .entry-cta-buttons-container .cancel-button-cta,
.athlete-entry-form .entry-cta-buttons-container .save-button-cta {
  color: #FFFFFF;
}
.athlete-entry-form .entry-cta-buttons-container .cancel-button-cta {
  background-color: #222F3E;
}
.athlete-entry-form .entry-cta-buttons-container .cancel-button-cta:disabled {
  background-color: rgba(34, 48, 62, 0.5);
}
.athlete-entry-form .entry-cta-buttons-container .save-button-cta:disabled {
  background-color: rgba(16, 172, 132, 0.5);
}
.athlete-entry-form .preferences-item .filled-input {
  margin-bottom: 22px;
}

.bulk-container .bulk-profile-merge {
  margin-top: 20px;
}
.bulk-container .bulk-profile-merge .data-grid-parent {
  display: flex;
  flex-direction: column;
  block-size: 100%;
  gap: 10px;
}
.bulk-container .bulk-profile-merge .data-grid-parent .rdg {
  max-height: 70vh;
}
.bulk-container .bulk-profile-merge .good .common-columns {
  border-bottom: none;
}
.bulk-container .bulk-profile-merge .good .checkbox-column {
  border-bottom: none;
}
.bulk-container .bulk-profile-merge .good .rdg-cell {
  border-top: 1px solid #000;
}
.bulk-container .bulk-profile-merge .no-good .rdg-checkbox-label {
  display: none;
}
.bulk-container .bulk-profile-merge-actions {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}

.entries-header-buttons {
  height: 40px;
  margin-top: 20px;
  display: inline-block;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.entries-header-buttons .text-athletes button:focus {
  outline: none;
}
.entries-header-buttons button:focus {
  outline: none;
}
.entries-header-buttons .export-entries-btn {
  position: absolute;
  right: 200px;
}
.entries-header-buttons .text-athletes {
  display: inline-block;
  position: absolute;
  right: 24px;
}

.table-classifications .option-icon {
  fill: #9D9D9D;
}

.add-classification-modal {
  width: 664px;
}

.edit-classification-title h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.968px;
  text-transform: uppercase;
  color: #0F0F0F;
}

.classification-modal-content label {
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
  color: #576574;
  font-weight: bold;
}

.classification-table-cell-verified svg {
  fill: #10AC84;
  font-size: 26px;
}

.associations-promoters .main-page .table-cell-filter > div {
  width: 100%;
}
.associations-promoters .main-page .header-container {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
}
.associations-promoters .main-page .header-container .button-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.associations-promoters .main-page .header-container .button-container .export {
  background-color: #222F3E;
  padding-left: 30px;
  padding-right: 30px;
}
.associations-promoters .form {
  padding-top: 20px;
}
.associations-promoters .form .export-entries-btn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.associations-promoters .form .container {
  margin-bottom: 24px;
  padding: 24px;
}
.associations-promoters .form .details-container {
  margin-top: 24px;
}
.associations-promoters .form .sub-title {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.associations-promoters .form .radios {
  flex-direction: row;
}
.associations-promoters .form .alliance-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.associations-promoters .form .alliance-container .form-label {
  transform: none;
  transform-origin: unset;
  height: auto;
  margin: 0;
  margin-right: 10px;
  transition: none;
  font-size: 13px;
}
.associations-promoters .form .association-logo {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.associations-promoters .form .button-container {
  margin-top: 24px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}
.associations-promoters .form .btn-upload-event-image {
  margin-left: 0 !important;
}
.associations-promoters .form .section {
  margin-bottom: 24px;
}
.associations-promoters .form .form-helper {
  color: #f44336;
}
.associations-promoters .form .checkbox-color {
  color: #10ac84 !important;
}
.associations-promoters .form .outlined {
  height: 81px;
}
.associations-promoters .form .outlined input {
  padding-top: 60px;
  padding-left: 8px;
}
.associations-promoters .form .select-checkboxes-container .react-select__control {
  font-size: 16px;
  height: 81px;
  font-weight: bold;
  background-color: #f4f5f8;
}
.associations-promoters .form .association-membership-details .btn-upload-event-image {
  background-color: #222F3E;
}
.associations-promoters .gold-chip-container {
  width: 86px;
  height: 31px;
  background: linear-gradient(98.94deg, #ECC335 8.54%, #E0A630 93.47%);
  border-radius: 22px;
}
.associations-promoters .silver-chip-container {
  width: 86px;
  height: 31px;
  background: linear-gradient(97.52deg, #F8F8F8 0%, #D0D0CE 106.59%);
  border-radius: 22px;
}
.associations-promoters .bronze-chip-container {
  width: 86px;
  height: 31px;
  background: linear-gradient(97.52deg, #E3C9A6 0%, #A78E6F 106.59%);
  border-radius: 22px;
}
.associations-promoters .cell-center {
  text-align: center;
}
.associations-promoters .associations-promoters-event-list {
  padding-top: 20px;
}
.associations-promoters .associations-promoters-event-list .export-entries-btn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.associations-promoters .associations-promoters-event-list thead td > span {
  white-space: nowrap;
}
.associations-promoters .associations-promoters-tabs button {
  max-width: inherit;
}

.membership-no-season {
  margin-top: 100px;
  text-align: center;
}
.membership-no-season .paper-no-season {
  margin-bottom: 20px;
}
.membership-no-season h3 {
  padding: 24px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.704px;
}
.membership-no-season .create-new-season-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.membership-no-season .create-new-season-button span {
  color: white;
}

.new-season-container {
  margin-top: 24px;
}
.new-season-container .section-title {
  margin-bottom: 24px;
}
.new-season-container .button-container {
  margin-bottom: 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}
.new-season-container .container {
  padding: 24px;
}
.new-season-container .disciplines-text {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 24px;
}
.new-season-container .disciplines-text.error {
  color: #EE5253;
}

.season-info .dates-containers {
  margin-top: 24px;
}
.season-info .dates-containers .date-title {
  font-size: 13px;
  margin-bottom: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.disciplines-seasons .checkbox-component {
  padding: 6px;
}
.disciplines-seasons .checkbox-label {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.484px;
  text-transform: uppercase;
}
.disciplines-seasons .discipline-gird {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-auto-flow: column;
}

.membership-cards .subtitle {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}
.membership-cards .details {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.membership-cards .age-fields {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.membership-cards .toggle-wrap {
  margin-top: 24px;
}
.membership-cards .toggle-wrap .toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.membership-cards .toggle-wrap .toggle .form-label {
  text-transform: uppercase;
  position: relative;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  transform: none;
}

.modal-membership .modal-title {
  font-size: 20px;
}
.modal-membership .body-modal {
  padding: 24px;
}
.modal-membership .membership-cards .details .subtitle {
  font-size: 16px;
}
.modal-membership .actions .btn-actions {
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.error-bundary {
  font-family: "Barlow";
  font-style: normal;
  color: #576574 !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-bundary h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 2px;
  color: #0F0F0F;
  margin-bottom: 20px;
}
.error-bundary p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1.4px;
  color: #313131;
  margin-top: 0;
  margin-bottom: 70px;
}
.error-bundary a {
  text-decoration: none;
}
.error-bundary button {
  font-size: 14px;
  margin-top: 0px;
}

body {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  margin: 0;
  padding: 0;
  background-color: #f5f6f9;
}