.btn-performance-draw {
  height: 38px;
  width: 38px;
  text-transform: uppercase;
  border: 2px solid $light-blue;
  border-radius: 3em;
  cursor: pointer;
  &.not {
    border: 2px dotted $light-gray;
    font-size: .8em;
  }
  &.selected {
    background-color: $medium-gray;
    color: $white;
    &.first {
      background-color: $selected-green;
    }
    &.second {
      background-color: $selected-blue;
    }
    &.third {
      background-color: $selected-orange;
    }
    font-size: 16px;
    &:hover {
      background-color: $unavailable-red !important;
      span {
        display: none;
      }
      &:before {
        content: '✕';
      }
    }
  }
  &.unavailable {
    background-color: $unavailable-red;
    color: $white;
    font-size: 16px;
  }
  font-size: 21px;
  color: $medium-gray;
  font-family: $secondary-font;
  font-weight: bold;
  text-align: center;
}

.perf-table-row {
  .selected {
    p {
      border-bottom: .2em solid $medium-gray;
    }
    &.first {
      border-bottom: .2em solid $selected-green;
    }
    &.second {
      border-bottom: .2em solid $selected-blue;
    }
    &.third {
      border-bottom: .2em solid $selected-orange;
    }
  }

}

.table-cell-listing {
  &.pad-right-10 {
    padding-right: 10px;
  }
}

.performance-draw-guide {
  .btn-performance-draw {
    height: 32px !important;
    width: 32px !important;
  }
  button {
    pointer-events: none;
    border: none;
    margin-bottom: .3em;
    margin-top: .3em;
    margin-right: .3em;
  }
}
