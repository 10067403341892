.card-info-label, .total-to-pay-label {
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0 10px 0;
  display: block;

  color: rgba(0, 0, 0, 0.7);

  font-size: 0.8em;
  font-family: Barlow,sans-serif;
  line-height: 1;
}

.card-info-wrp {
  display: block;
  padding-bottom: 15px;
}

.total-fee-wrp {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}

.athlete-dropdown-from-control, .event-dropdown-from-control, .discipline-dropdown-from-control, .segment-dropdown-from-control, .performance-dropdown-from-control, .coupon-dropdown-from-control {
  display: block;
  padding-bottom: 15px !important;
}

.nomination-v2{
  padding-top: 20px;
  padding-left: 0;

  .action-content {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      margin: 5px;
    }

    a {
      text-decoration: none;
    }
  }

  .form-title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 53px;
    line-height: 64px;
  }

  .main-grid {
    padding: 3px;
  }

  .card {
    padding: 20px;

    h3 {
      font-weight: 700!important;
      font-size: 23px!important;
      padding-bottom: 20px;
      letter-spacing: 2px;
    }
  }

  .athlete-dropdown-from-control, .event-dropdown-from-control, .discipline-dropdown-from-control, .segment-dropdown-from-control, .performance-dropdown-from-control, .coupon-dropdown-from-control, .card-dropdown-from-control, .type-amount-control {
    display: block;
    padding-bottom: 15px !important;

    label {
      z-index: 1;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 13px!important;
      line-height: 12px;
      letter-spacing: 0.484px;
      text-transform: uppercase;
      color: #576574!important;
    }

    .Select--single {
     .Select-control {
      height: 58px;

        .Select-multi-value-wrapper {
          padding-top: 20px;

          .Select-value {
            padding-top: 25px;
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 500;
            font-size: 16px!important;
            line-height: 25px;
            letter-spacing: 0.704px;
            color: #242424;
          }

          .Select-placeholder {
            padding-top: 23px;
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.704px;
            text-transform: uppercase;
            color: #9D9D9D;
          }

          .Select-input {
            position: absolute;
            width: 100%;

            input {
              width: -moz-available!important;
            }
          }
        }

        .Select-clear {
          margin-top: 16px;
        }

        .Select-arrow {
          margin-top: 25px;
        }
      }
    }
  }

  .athlete-dropdown-from-control {
    label {
      padding-top: 10px;
    }
  }

  .type-amount-control {
    div {
      div {
        &:before {
          border-bottom: 1px #ddd solid!important;
        }
      }
      .div:hover {
        border-bottom: 1px #ddd solid!important;
      }
    }

  }

  .fieldset-checkbox {
    margin-bottom: 10px;

    span {
      padding-top: 0;
      padding-bottom: 0;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
    }
  }

  .type-amount-control {
    margin-top: 5px;

    label {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.484px;
      text-transform: uppercase;
      color: #576574;
      font-size: 1rem;
      position: absolute;
      z-index: 2;
    }

    input  {
      margin-left: 10px;
      margin-top: 13px;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.704px;
      text-transform: capitalize;
      color: #242424;
    }

    div {
      height: 60px;
      background: #f2f4f7
    }
  }

  .card-info-wrp {
    .brand-card {
      margin-left: 15px;
      width: 30px;
      height: 30px;
      display: inline-block;
      position: absolute;
      margin-top: 20px;
      margin-left: 11px;

      &.mastercard {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/MasterCard.png');
      }

      &.americanexpress, &.amex {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/AmericanExpress.png');
      }

      &.dinersclub, &.diners {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/DinersClub.png');
      }

      &.discover {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/Discover.png');
      }

      &.jcb {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/JCB.png');
      }

      &.unionpay {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/UnionPay.png');
      }

      &.visa {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/Visa.png');
      }
    }
    .card-form-control {
      padding-top: 0 0 5px 0;
      background: #f2f4f7;
      padding-bottom: 10px;
      border-radius: 5px 5px 0px 0px;

      .form-label-bold {
        margin-top: 4px;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 12px;
        letter-spacing: 0.484px;
      }

      .card-input {
        margin-top: 25px;
        margin-left: 8px;

        &.show-brand {
          margin-left: 46px;
        }
      }
    }
  }

  .nomination-count-container {
    font-size: 20px;
    font-weight: 700;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .nomination-count-title {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      text-transform: uppercase;
      color: #222F3E;
      align-items: center;
      letter-spacing: 2px;
    }

    .nomination-count-input-container {
      align-items: center;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        span {
          font-size: 24px;
          letter-spacing: 2px;
        }

        svg {
          font-size: 16px;
        }
      }
    }
  }

  .nomination-index-title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 2px;
    color: #222F3E;
  }

  .summary {
    h3 {
      margin: 0;
      padding: 5px 0 5px 0;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 2px;
      color: #222F3E;
    }

    .summary-item {
      display: flex;
      align-items: stretch;
      width: 100%;
      color: #000;
      flex-wrap: wrap;
      padding: 1px 0 1px 0;

      span {
        padding: 0;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1.4px;
        color: #11181F;

        &.item-label {
          flex: 1 1;
        }

        &:nth-of-type(2) {
          text-align: right;
        }
      }
    }
  }

  .total-fee-wrp {
    display: flex;
    align-items: stretch;
    width: 100%;
    color: #000;
    flex-wrap: wrap;
    padding: 0;

    hr {
      width: 100%;
      color: black;
      flex: auto;
      height: 1px;
      margin: 0;
    }

    span {
      flex: 1;
      padding: 0;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.968px;
      text-transform: uppercase;
      color: #11181F;
  
      &:nth-of-type(2) {
        text-align: right;
      }
    }
  }
}

@mixin nomination-field {
  .label {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.704px;
    margin-right: 5px;
  }
  .value {
    font-family: 'Barlow';
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.704px;
    color: #000000;
  }
}

.nomination-refund {
  padding-top: 20px;
  padding-left: 0;

  .action-content {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      margin: 5px;
    }

    a {
      text-decoration: none;
    }
  }

  .form-title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 53px;
    line-height: 64px;
  }

  .main-grid {
    padding: 3px;
  }

  .card {
    padding: 20px;

    h3 {
      font-family: 'IBM Plex Sans'!important;;
      font-style: normal;
      font-weight: 600!important;
      font-size: 22px!important;;
      line-height: 29px;
      text-transform: uppercase;
      color: #000000;
      padding-bottom: 14px;
    }
  }

  .payment-detail-card {
    margin-top: 16px;

    .nomination-field {
      margin-bottom: 5px;
      @include nomination-field;
    }
  }

  .nomination-details-card {
    .nomination-field {
      margin-bottom: 5px;
      @include nomination-field;
    }
  }

  .refund-details-card {
    .card-subtitle {
      width: 408px;
      height: 25px;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.704px;
      color: #000000;
      margin-bottom: 5px;
    }

    .refund-dropdown-form-control {
      margin-top: 5px;

      .Select-control {
        height: 58px;

        .Select-placeholder {
          margin-top: 15px;
        }
        .Select-value {
          margin-top: 20px;
          position: absolute;

          span {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.704px;
            text-transform: capitalize;
            color: #242424!important;
          }
        }

        .Select-clear {
          margin-top: 3px;
        }
      }

      .form-label-bold {
        position: absolute;
        z-index: 1;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 12px;
        letter-spacing: 0.484px;
        text-transform: uppercase;
        color: #576574;
        margin-top: 10px;
      }

      .refund-options {
        margin-top: 10px;

        .radio-group-label {
          display: inline-block;
        }

        label {
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.704px;
          text-transform: uppercase;

          span:first-of-type {
            color: #2E86DE;
          }
        }

        .refund-container {
          .refund-method-item {
            display: inline-block;
            padding: 5px;

            .form-input-control{
              div {
                &::before {
                  border-bottom-style: solid;
                }
              }

              label {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 12px;
                letter-spacing: 0.484px;
                text-transform: uppercase;
                color: #576574;
              }
            }
          }

          .refund-division-item {
            padding: 5px;

            .form-input-control{
              div {
                &::before {
                  border-bottom-style: solid;
                }
              }

              label {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 12px;
                letter-spacing: 0.484px;
                text-transform: uppercase;
                color: #576574;
              }
            }

            span {
              margin-top: 15px;
              margin-bottom: 25px;
            }
          }
          .refund-to-cc {
            width: 15%;
            height: 59px;

            div {
              margin-top: 8px;

              &::before {
                border-bottom-style: solid;
              }
            }

            label {
              font-family: 'Barlow';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 12px;
              letter-spacing: 0.484px;
              text-transform: uppercase;
              color: #576574;
              margin-top: 5px;
              margin-left: 5px;
            }

            span {
              font-family: 'Barlow';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0.704px;
              text-transform: capitalize;
              color: #242424;
            }
          }

          .refund-division {
            width: 20%;
            height: 59px;

            div {
              margin-top: 8px;

              &::before {
                border-bottom-style: solid;
              }
            }

            label {
              font-family: 'Barlow';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 12px;
              letter-spacing: 0.484px;
              text-transform: uppercase;
              color: #576574;
              margin-top: 5px;
              margin-left: 5px;
            }

            span {
              font-family: 'Barlow';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0.704px;
              text-transform: capitalize;
              color: #242424;
            }
          }

          .reason-to-refund {
            width: 85%;
            &.division {
              width: 80%;
            }
            background: #F4F5F8;
            border-radius: 5px 5px 0px 0px;
            margin-bottom: 25px;

            input {
              font-family: 'Barlow';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 0.704px;
              color: #242424;

              &::placeholder {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0.704px;
                color: #242424;
                opacity: 0.5;
              }
              &:-ms-input-placeholder,
              &::-ms-input-placeholder {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0.704px;
                color: #242424;
                opacity: 0.5;
              }
            }
          }

          .coupon-info {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.704px;
            color: #242424;
            text-transform: capitalize;
            background: #F4F5F8;
            border-radius: 5px;
            padding: 10px;
          }

          .multi-nomination {
            .info {
              background: #8395A7;
              font-style: normal;
              font-family: Barlow;
              padding-left: 10px;
              padding-right: 10px;
              padding-top: 5px;
              padding-bottom: 5px;
              color: #FFFFFF;
              border-radius: 5px;
              height: 81px;
              line-height: 25px;
              text-align: left;

              .span {
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0.9680001139640808px;
              }

              p {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.7039999961853027px;
              }
            }
            .details {
              fieldset {
                span {
                  color: #000000;
                  font-family: IBM Plex Sans;
                  font-size: 22px;
                  font-weight: 600;
                  line-height: 29px;
                  letter-spacing: 0px;
                  text-align: left;
                }
                svg {
                  color: #2e86de;
                }
              }
              padding-bottom: 30px;
              .nomination-field {
                @include nomination-field;
              }
            }
          }

          p {
            margin-left: 5px;
            font-family: Barlow;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            height: 21px;
            opacity: 0.4;
          }
        }
      }
    }
  }
}

.nominations-form-control-switch {
  span {
    font-size: 1.3em !important;
    text-transform: uppercase;
  }
  span:nth-child(2) {
    padding-left: .7em;
  }
}

.nominations-switch {
  width: 84px;
  span.MuiSwitch-icon-146 {
    height: 30px;
    width: 30px;
  }
  span.MuiSwitch-bar-153 {
    width: 48px;
    height: 20px;
    border-radius: 14px;
    top: 46%;
  }
  .MuiSwitch-checked-149 {
    transform: translateX(27px);
  }
}

.action-buttons {
  display: flex;
  align-items: center;

  .link-refund {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ebebeb;
    background-color: #ebebeb;
    border-radius: 30px;
    margin-right: 20px;
    margin-left: 5px;
    cursor: pointer;

    .refund-icon {
      padding: 6px;
      border-radius: 50px;
      background-color: white;
    }

    .refund-label {
      margin-left: 10px;
      margin-right: 20px;
    }
  }
}

.confirm-modal {
  div:nth-child(2) {
    div:first-child {
      overflow-y: visible;
    }

    h6 {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.968px;
      color: #242424;
    }
    .sub-title {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.704px;
      color: #000000;
    }

    .data {
      .title {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.704px;
        color: #000000;
      }
      .value {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.704px;
        color: #000000;
      }
    }

    .note {
      margin-top: 20px;

      .title{
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.704px;
        color: #000000;
      }
      .value {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.704px;
        color: #000000;
      }
    }
  }
}

.confirm-refund-modal {
  div:nth-child(2) {
    div:first-child {
      overflow-y: visible;
    }
  }

  .filled-default-input {
    padding-top: 7px;

    .Select-control {
      border-bottom-color: rgba(0, 0, 0, 0.42)
    }
  }
}

.confirm-remove-modal {
  .reason-content {
    background: #f4f5f8;
    border-radius: 5px 5px 0 0;
    margin-top: 25px;

    label {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.484px;
      text-transform: uppercase;
      color: #576574;
      margin-left: 5px;
    }

    .form-input-control {
      input {
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: .704px;
        color: #242424;
        height: 25px;
        margin-left: 5px;
        margin-top: 4px;
      }
    }
  }
}

.nominations-header-buttons {
  height: 40px;
  margin-top: 20px;
  display: inline-block;
  flex-direction: row-reverse;
  justify-content: space-between;

  button {
    &:focus {
      outline: none;
    }
  }

  .export-nominations-btn {
    position: absolute;
    right: 25px;
  }
}

.table-nomination {
  .dark-table-head {
    td {
      padding-left: 15px;
    }
  }

  tbody {
    tr {
      padding-left: 15px!important;

      th {
        padding-left: 15px!important;
      }
      
      td {
        p { 
          padding-left: 10px;

          .column-no-border-botton {
            padding-left: 0;
            border: 0;
          }
        }
      }
    }
  }

  .table-cell-filter label {
    width: 120px;
  }
}
