.table-cell-listing {
  &.bump-up {
    padding-bottom: 12px !important;
  }
  &.short {
    width: 12%;
  }
  &.shorter {
    width: 7%;
    div:nth-child(1) {
      width: 100px;
    }
  }
  &.center {
    div:nth-child(1) {
      align-items: center;
      margin-right: 1em;
    }
  }
  &.shorter {
    width: 7%;
    div:nth-child(1) {
      width: 100px;
    }
  }
  &.center {
    div:nth-child(1) {
      align-items: center;
      margin-right: 1em;
    }
  }
}

.Select-placeholder,
.Select-value {
  font-size: 14px !important;
}

.penalized {
  border: 2px dotted rgba(238, 82, 83, 0.5);
  background-color: rgba(238, 82, 83, 0.1);
}

.reride-row {
  background-color: #f2f4f7;
}

.reride-title {
 margin-left: 0.5rem;;
}

.cancelled-reride {
  th {
    p,
    strike {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}

.advance-athlete-modal {

  .subtitle {
    margin-top: 15px;
  }

  .athletes-container {
    margin-top: 35px;
    margin-bottom: 25px;

    .athlete-line {
      border-bottom: 1px solid $light-gray;
      padding: 20px 5px 20px 5px;

      p {
        text-transform: uppercase;
        font-size: 13px !important;
      }

      &.header {
        padding: 5px;
        font-weight: bold;
      }
    }
  }
  .select-round {
    .filled-input {
      padding-top: 0px !important;
    }
  }
}

.strikethrough-result {
  color: $mid-gray !important;
  text-decoration: line-through;
}

.nominated-athlete {
  line-height: 12px;
  padding-right: 5px;
  color: green;
  font-size: 2em;
}
