.transaction-history-card {
  .line-items-link {
    color: $wcra-blue;
    font-family: $secondary-font-regular;
    font-size: 1rem;
    letter-spacing: 1.05px;

    &-text {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:link,
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    .chevron {
      cursor: pointer;
      height: 1rem;
      padding-left: 0.375rem;
    }
  }

  .line-items-link-text {
    cursor: pointer;
    text-transform: uppercase;
  }

  .date {
    color: $gray-text;
    font-family: $primary-font;
    font-size: 0.8125rem;
    font-weight: 500;
  }

  .entry-items {
    display: flex;
    flex-direction: column;
  }

  .entry-item {
    color: $muted-gray;
    font-family: $secondary-font-regular;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    font-weight: normal;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 1rem;
    letter-spacing: 0.7px;
    text-transform: uppercase;

    &-discipline {
      padding-left: 1rem;
      padding-bottom: .75rem;
    }

    &-fee {
      padding-bottom: 0.625rem;
    }

    &-total {
      font-family: $secondary-font;
      font-weight: bold;
    }

    .discipline {
      display: flex;
      align-content: center;
    }

    .payment-text {
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
        stroke: $white;
        fill: $wcra-dark-blue;
      }
    }

    &.bold {
      color: $wcra-black;
      font-weight: 700;
    }
  }

  .external-link {
    cursor: pointer;
    align-self: center;
    padding-left: 0.375rem;
    height: 0.75rem;
    color: $wcra-blue;

    &-large {
      height: 0.875rem;
    }
  }
}
