.entry-count-letter {
  text-align: center;
  line-height: 12px;
  height: 13px;
  width: 13px;
  font-size: 10px;
  letter-spacing: 0.5px;
  background-color: $wcra-blue;
  color: $white;
  display: inline-block;
  margin-left: 7px;
}


.entry-count-letter-container {
  @media(min-width: 1000px) {
    white-space: nowrap;
  }
}