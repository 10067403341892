.menu-icon-svg {
  width: 24px;
  height: 24px;
}
.login-wrp {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
.basic-paper-layout {
  margin-top: 20px;
  padding: 30px 48px;
}

.menu-link {
  &.active {
    svg {
      color: white;
    }
  }

  svg {
    color: $light-gray;
  }
}
.hidden {
  opacity: 0;
  visibility: hidden;
}
