.entries-header-buttons {
  height: 40px;
  margin-top: 20px;
  display: inline-block;
  flex-direction: row-reverse;
  justify-content: space-between;

  .text-athletes button:focus {
    outline: none;
  }

  button {
    &:focus {
      outline: none;
    }
  }

  .export-entries-btn {
    position: absolute;
    right: 200px;
  }

  .text-athletes {
    display: inline-block;
    position: absolute;
    right: 24px;
  }
}
