.event-dates-column {
  width: 244px;
  text-align: right;
}

.tabs-paper {
  margin-top: 25px;

  &.no-background {
    background-color: transparent;
    box-shadow: none;
  }
}

.photo-upload-wrapper {
  width: 100px;
  height: 100px;
  float: left;
  padding: 2px;
  margin-right: 10px;
  border: 1px solid #e9e9e9;

  .avatar-wrp {
    border-radius: unset;
    width: 100%;
    height: 100%;
  }
}

.btn-save-trade-performances {
  margin: 25px !important;
  width: 160px;
}

.menu-disciplines-trade-performances {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;

  h3 {
    padding-left: 16px;
  }
}

.disciplines-menu-form-control {
  margin-top: 5px !important;
  padding-left: 0 !important;
  width: 100% !important;

  label {
    display: block !important;
    margin: 0;

    span {
      border-bottom: 1px solid $light-gray;
      height: auto !important;
      padding-left: 16px;
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      color: $wcra-black;
      font-size: 14px;
    }

    &.selected {
      span {
        color: $wcra-green;
        font-weight: bold;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
}

.grid-entry-pool-listing {
  width: 686px;

  .paper-entry-pool-listing {
    padding: 0;

    .gray-head-row {
      height: 50px !important;
      background-color: rgb(239 239 239);

      span {
        color: rgba(0, 0, 0, 0.54);
        padding: 20px;
        font-weight: normal;
        font-size: 11px;
      }
    }

    .performance-row {
      .perfomance-cell {
        display: flex;
        justify-content: space-between;

        .performance-name {
          font-weight: 700;
          font-size: 14px;
          color: $black;
          padding: 0;
          margin: 0;
        }

        .performance-detail {
          padding: 0;
          margin: 0;
          font-weight: 700;
          font-size: 14px;
        }

        .performance-stock-counter {
          font-weight: 700;
          font-size: 14px;
          color: $black;
          text-align: left;
          padding: 0;
          margin: 0;
        }
      }
    }

    .empty-stock-row {
      p {
        text-align: center;
        color: $black;
      }
    }

    .footer-detail {
      display: flex;
      justify-content: space-between;
      padding: 0;
      vertical-align: middle;
      align-items: center;

      p {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        padding-right: 20px;
        margin: 0;
        font-family: Barlow, sans-serif;
      }
    }
  }

  .table-listing-with-scroll {
    position: relative;

    .table-body-with-scroll {
      display: block !important;
      max-height: 498px;
      overflow-y: scroll;
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .filter-row {
      background-color: white;
      font-weight: normal;

      &.sticky {
        position: sticky;
        top: 0;
      }

      .input-container {
        width: 100%;
        padding: 10px 0 9px 26px;
      }

      .input-select {
        width: 150px;
      }

      input,
      select {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
      }

      .filter-label {
        display: flex;
        color: rgba(0, 0, 0, 0.87);
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }

  .table-listing-with-scroll-event-animal {
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: $white;

    .table-body-with-scroll {
      height: 400px;
      overflow: auto;

      .fixed {
        table-layout: fixed;
      }
    }

    .filter-row {
      background-color: white;
      font-weight: normal;

      &.sticky {
        position: sticky;
        top: 0;
      }

      .input-select {
        width: 149px;
      }

      input,
      select {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
      }

      .filter-label {
        display: flex;
        color: rgba(0, 0, 0, 0.87);
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    .column-head {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .performance-container {
    min-width: 300px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
}

.paper-stock-draw-form {
  padding: 0 !important;
  .Select-placeholder,
  .Select-value-label {
    color: $wcra-black;
    font-family: $secondary-font;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 100;
  }

  .dark-table-head {
    th {
      height: 17px;
      width: 60px;
      color: $white;
      font-family: $secondary-font;
      font-size: 13px;
      letter-spacing: 0.65px;
      line-height: 17px;
    }
    th:nth-child(1) {
      padding-left: 4em;
    }
  }

  .stock-draw-title {
    font-size: 26px;
    width: 50em;
    letter-spacing: 1.1px;
    font-family: $secondary-font;
    text-transform: uppercase;
    display: inline-flex;
    position: relative;
    top: 1em;
    .title-text {
      padding-left: 0.5em;
    }
    .date {
      background-color: $wcra-gray;
      padding: 0.5em;
      margin-left: 1em;
      font-size: 18px;
      width: 18%;
      height: 2.5em;
    }
    .stock-draw-discipline {
      background-color: $light-gray-button;
      color: $white;
      padding: 0.5em;
      font-size: 13px;
      letter-spacing: 0.65px;
      border-radius: 1.3em;
      margin-left: 1.3em;
      height: fit-content;
      text-align: center;
      border-radius: 3em;
      align-self: center;
    }
    .stock-draw-top-actions {
      text-align: right;
      width: auto;
      margin: auto;
      display: flex;
      align-items: center;
      button {
        margin-right: 1em;
        background-color: $dark;
        height: 2.5em;
        width: 11em;
        border-radius: 3px;
        border-width: 0px;
        color: $white;
        font-family: $secondary-font;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        font-weight: 100;
        cursor: pointer;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
          0px 8px 10px -7px rgba(0, 0, 0, 0.14),
          0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        text-transform: uppercase;
        border-style: solid;
      }
    }
  }

  .stock-draw-table-actions {
    display: flex;
    align-items: center;
    height: 6em;

    .stock-draw-actions {
      padding-left: 2em;
      display: inline-flex;
      line-height: 6em;
      padding-left: 2em;
      button {
        background-color: $wcra-green;
        height: 2.5em;
        width: 7em;
        border-radius: 3px;
        border-width: 0px;
        color: $white;
        font-family: $secondary-font;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        font-weight: 100;
        cursor: pointer;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
          0px 8px 10px -7px rgba(0, 0, 0, 0.14),
          0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        text-transform: uppercase;
        &:disabled {
          background-color: $light-gray-button !important;
        }
        border-style: solid;
      }
      button:nth-child(1) {
        background-color: $light-gray-button !important;
      }
      button:nth-child(2),
      button:nth-child(3) {
        margin-left: 1em;
      }
      button:nth-child(3) {
        width: 17em;
        background-color: $dark;
      }
    }
    .stock-draw-submission-date {
      padding-left: 2em;
      display: inline-flex;
      line-height: 6em;
      padding-left: 2em;
    }
  }

  .stock-draw-table-row {
    &.dark {
      background-color: $light-gray;
    }
    height: 6em;
    td:nth-child(1) {
      width: 12%;
      padding-left: 4em;
    }
    td:nth-child(2),
    td:nth-child(3) {
      width: 27%;
    }
    .reride-divider {
      font-size: 1.7em !important;
      font-family: $secondary-font;
      padding-left: 2.8em;
    }
    .up-down-actions {
      position: absolute;
      right: 6em;
      margin-top: -1em;
      button {
        width: 1.3em;
        height: 1.3em;
        color: $wcra-blue;
      }
      button:nth-child(1) {
        margin-left: 0.8em;
      }
    }
  }

  .stock-draw-field {
    margin-top: 0 !important;
    div:nth-child(1) {
      &:before,
      &:after {
        border-bottom: 1px solid $light-gray !important;
      }
    }
    &.long {
      width: 100%;
    }
    &.selected {
      select {
        color: $black;
      }
    }
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $wcra-gray;
    padding-top: 30px !important;
    select {
      text-transform: uppercase;
      padding-left: 1em;
    }
    svg {
      color: $wcra-green;
    }
  }
}

.trade-performance-submission-date-wrp {
  display: block;
  padding: 25px;
  color: $gray;
  font-size: 0.9em;
}

.trade-saved-icon {
  margin-bottom: -6px;
  text-align: right;
}

.stock-draw,
.performance-results {
  text-transform: uppercase;

  div {
    padding-bottom: 0.5em;
  }

  .btn-stock-draw {
    background: $white;
    border-color: $light-gray-button;
    border-style: solid;
    border-radius: 3px;
    border-width: 1px;
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.48px;
    line-height: 11px;
    margin-bottom: 1.5em;
    margin-right: 2em;
    margin-top: 1.5em;
    padding: 12px 15px;
    text-transform: uppercase;

    &:hover {
      background: $wcra-gray;
      cursor: pointer;
    }
    &:disabled {
      background: $wcra-gray;
      cursor: default;
      opacity: 0.5;
    }

    &.bg-dark-blue {
      background-color: $wcra-dark-blue;
      color: $white;
    }

    &.state-1 {
      background-color: $white;
    }

    &.state-2 {
      border: 1px solid #ff9f43;
    }

    &.state-3 {
      background-color: $wcra-green;
      color: $white;
      font-family: $secondary-font;
    }
  }

  div:nth-child(1) {
    padding-top: 1.1em;
  }

  h4 {
    font-family: $secondary-font !important;
    font-weight: 100 !important;
    font-size: 1.5em;
    letter-spacing: 0.97px !important;
    line-height: 25px;
    font-weight: lighter;
  }
  h5 {
    font-family: $secondary-font !important;
    font-size: 18px;
    letter-spacing: 0.79px !important;
    line-height: 25px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .shaded,
  .shaded-dark {
    background-color: $wcra-gray;
    padding: 0.4em;
    margin-right: 0.4em;
  }
  .shaded-dark {
    background-color: $wcra-gray-dark;
    color: $white;
  }
}

%guide-state {
  width: 30px;
  height: 30px;
  display: inline-flex;
  margin-right: 5px;
  border-radius: 3px;
  vertical-align: middle;
}

.performance-status-guide {
  display: grid;
  padding-top: 21px;
  font-size: 11px;
  font-weight: 500;
  text-transform: lowercase;
  vertical-align: top;
  margin-right: 30px;

  .padBot-1 {
    padding-top: 10px;
    padding-left: 24px;
  }

  .content {
    padding: 10px;
    padding-left: 24px;

    span {
      display: inline-flex;
      font-weight: 500;
      text-transform: uppercase;
      width: 85%;
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  .guide-state-1 {
    @extend %guide-state;
    border: 1px solid black;
    background-color: $white;
  }

  .guide-state-2 {
    @extend %guide-state;
    border: 1px solid #ff9f43;
  }

  .guide-state-3 {
    @extend %guide-state;
    background-color: $wcra-green;
    font-family: $secondary-font;
  }
}

.manage-results-button {
  padding: 10px;
}

.manage-results-buttons {
  display: flex;
  align-items: center;
  .wcra-green-cta {
    height: 30px;
  }
}

.back-to-performances {
  border: none;
  background-color: inherit;
  margin-top: 2em;
  height: 17px;
  color: $wcra-blue;
  font-family: $secondary-font;
  font-size: 15px;
  letter-spacing: 1.05px;
  line-height: 17px;
  cursor: pointer;
}

.checkmark,
.preference-eligible-check {
  color: $wcra-green;
}

.disable-mark {
  color: $wcra-black;
  margin-right: 5px;
  margin-bottom: -8px;
}

.disciplines-container {
  hr {
    margin: 15px 0;
    border-top: 1px solid $light-gray;
    border-bottom: transparent;
  }

  label {
    z-index: 1;
  }

  .filled-input {
    height: 68px;

    input {
      padding: 11px 10px;
    }
  }

  .dark-tooltip {
    color: $wcra-dark-blue;
    display: flex;
    float: right;
    width: 18px;
    margin-left: 5px;
    margin-top: 0;

    @media (max-width: 1440px) {
      margin-left: 5px;
      margin-top: 0px;
    }
  }
}

.disciplines-container {
  .is-disabled {
    .event-discipline-field:before {
      border-bottom-style: solid;
    }

    .filled-input-narrow,
    .filled-input {
      background: transparent !important;

      &:hover {
        .Select-control {
          border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        }
      }
    }
  }

  .manual-arrow::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
}

.event-discipline-item {
  flex-wrap: nowrap !important;
  justify-content: space-between;
  padding-bottom: 8px;
  &.non-managed-no-entries {
    justify-content: flex-start;
  }
  .event-discipline-label {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.75px;
    &.non-managed-no-entries {
        padding: 34px 12px;
      }
  }
  .add-class-button {
    white-space: nowrap;
    justify-content: flex-end;
  }
  .event-discipline-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .event-discipline-close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    max-width: 115px;

    svg {
      cursor: pointer;
      &.class-item-trash-icon {
        // margin-right: 34%;
      }
      &.disabled {
        cursor: default;
        opacity: 0.2;
      }
    }
  }

  .event-max-entries-field,
  .event-discipline-field {
    margin-top: 0;
  }

  .event-max-entries-field {
    display: block;
    width: 100%;
  }

  .event-discipline-select {
    margin-top: -15px;
    text-transform: uppercase;

    .Select-control {
      border-bottom-color: rgba(0, 0, 0, 0.42);
      &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
      }
    }
  }

  .event-discipline-select .is-disabled {
    background: none;

    .Select-control .Select-value {
      pointer-events: none;
    }
  }

  .event-discipline-elegible-nomination {
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    align-items: center;
    // white-space: nowrap;
    justify-content: flex-end;

    @media (max-width: 1699px) {
      justify-content: flex-start;
    }
  }
  .inline-checkbox {
    text-transform: uppercase;
    > label span:first-child {
      padding: 3px 5px;
    }
  }

  .checkmark {
    margin-right: 5px;
    margin-bottom: -8px;

    svg {
      fill: $wcra-green;
    }

    @media (max-width: 1440px) {
      margin-bottom: 10px;
    }
  }

  .cell-description {
    font-weight: normal;
    padding-bottom: 3px;
  }

  .cell-description.finals {
    margin-left: 20px;
  }

  .cell-description.semi-finals,
  .cell-description.finals {
    @media (max-width: 1440px) {
      padding: 0;
    }
  }
  .sort-items {
    width: 65px;
    flex-basis: auto;
  }
  .sort-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.delete-performance-button {
  margin-left: 5px !important;
  background-color: $red !important;
  color: $white !important;
}

td.judge-selector-cell {
  padding: 4px 0;
}

.buttons-wrp{
  display: flex;
  align-items: center;
  gap: 10px;
}

.performances-header-non-managed {

  &.disciplines-with-classes {
    width: 26%
  }

  &.disciplines-without-classes {
    width: 68%;

      &.without-entries {
        width: 92%;
      }
  }

  &.preferences-with-classes {
    width: 24%
  }

  &.preferences-without-classes {
    width: 24%
  }

  &.classes {
    width: 42%;

    &.without-entries {
      width: 66%;
    }
  }
}

.table-logs {
  text-transform: none;
  td {
    span {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .log-table-cell-result {
    span {
      text-transform: inherit;
    }
    .result-title {
      font-weight: bold;
      text-transform: capitalize;
    }
    ul {
      padding: 5px;
      padding-left: 15px;
      list-style-type: none;
  
      li {
        font-family: Barlow,sans-serif;
        font-weight: 400;
        list-style-type: none;
        padding-bottom: 10px;
        line-height: 1.5;
      }
    }
  
  }
}

