.edit-performance-title {
  background-color: $white;
  h2 {
    line-height: 25px;
    font-family: $secondary_font;
    font-weight: 900;
    letter-spacing: 0.06em;
    color: $wcra-black;
    font-size: 1.3em;
    text-transform: uppercase;
  }
}

.performance-details {
  width: 100%;
  margin: auto;
  display: inline-flex;
  &.non-managed {
      justify-content: space-around;
    }

  .required-symbol {
    display: block;
    position: absolute;
    right: 0;
    text-align: right;
    margin-right: 12px;
    margin-top: -6px;
    font-size: 15px;
  }

  label {
    display: none;
  }
  div {
    &.header-item {
      width: 20%;
    }
    &.date {
      margin-left: 10px;
      width: 95% !important;
    }
    &.time {
      width: 28% !important;
    }
    &.date,
    &.time {
      input {
        padding-left: 2em;
      }
    }
  }

  div:nth-child(1) {
    &.header-item {
      width: 35%;
      padding-left: 2%;
      &.non-managed {
          width: 40%;
          padding-left: unset;
        }
    }
  }

  div:last-child {
    &.header-item {
      width: 15%;
      &.non-managed {
          width: 40%;
          padding-left: 7.5%;
        }
    }
  }

  .header-item {
    color: $wcra-dark-blue;
    font-family: $secondary_font;
    font-size: 0.7em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 15px;
  }

  .competition-level {
    width: 206px;
    padding: 0;
    margin-left: 6px;
    margin-right: 0;

    select {
      margin-bottom: 15px;
      padding-left: 15px;
    }
  }
}

.custom-error {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
  margin-bottom: 5px;
  font-family: Barlow,sans-serif;
}
.show-error {
  border-bottom: 1px solid #f44336!important;
  &::before {
    border-bottom: 0!important;
  }
  &::after {
    border-bottom: 0!important;
  }
  &:hover {
    border-bottom: 1px solid #f44336!important;
  }
}

.performance-details-classes {
  width: 100%;
  margin: auto;
  display: inline-flex;
  .required-symbol {
    display: block;
    position: absolute;
    right: 0;
    text-align: right;
    margin-right: 16px;
    margin-top: -6px;
    font-size: 15px;
  }
  label {
    display: none;
  }
  div {
    &.header-item {
      width: 20%;
    }
    &.date {
      margin-left: 10px;
      width: 95% !important;
    }
    &.time {
      width: 28% !important;
    }
    &.date,
    &.time {
      input {
        padding-left: 2em;
      }
    }
  }

  div:nth-child(1) {
    &.header-item {
      width: 30%;
      padding-left: 2%;
      &.non-managed {
          width: 40%;
          padding-left: 5%;
        }
    }
  }

  div:nth-child(2) {
    &.header-item {
      width: 35%;
      padding-left: 2%;
      &.non-managed {
          width: 40%;
        }
    }
  }

  div:last-child {
    &.header-item {
      width: 15%;
    }
  }

  .header-item {
    color: $wcra-dark-blue;
    font-family: $secondary_font;
    font-size: 0.7em;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 15px;
  }

  .competition-level {
    width: 206px;
    padding: 0;
    margin-left: 6px;
    margin-right: 0;

    select {
      margin-bottom: 15px;
      padding-left: 15px;
    }
  }
}

.performance-details-discipline {
  width: 100%;
  margin: auto;
  display: inline-flex;
  label {
    display: none;
  }
  div {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
  border-top: $light-gray solid 1px;
  border-bottom: $light-gray solid 1px;
}

.edit-performance-content {
  background-color: $white;
  div,
  label {
    color: $wcra-black;
  }

  &.no-padding {
    padding: 0px !important;
  }

  .control-date {
    width: 100%;
  }

  .symbol {
    display: block;
    position: absolute;
    right: 0;
    text-align: right;
    margin-right: 12px;
    margin-top: -6px;
    font-size: 15px;
  }
}

.add-performance-row {
  button {
    margin-left: 0;
    margin-right: auto;
    margin-top: 5px;
  }
}

.edit-performance-input-form-field {
  height: 4em;
  border-radius: 5px 5px 0 0;
  background-color: $wcra-gray;
  margin-top: 0 !important;
  border-bottom: 1px solid $light-gray;

  select,
  input {
    position: relative;
    top: 1em;
    text-transform: uppercase;
  }
  svg {
    top: 0.6em;
    color: $wcra-green;
  }
  &.date-icon {
    svg {
      position: relative;
      top: 0.6em;
    }
    padding-bottom: 20px;
    padding-left: 15px;
  }
}

.performance-sub-checkbox {
  display: flex;
  width: 70% !important;
  align-items: flex-start;
  justify-content: center;
  &.non-managed {
      align-items: center;
      &.with-classes {
        width: 25% !important;
      }
    }
}

.performance-sub-field {
  border-bottom: 1px solid $light-blue;
  margin-top: 7px !important;

  &.long {
    width: 175%;
    &.non-managed {
        width: 50%;
      }
  }

  label + div {
    margin-top: 0px !important;
  }

  select {
    text-transform: uppercase;
  }

  .performance-sub-field-helper-text {
    display: flex;
    color: $wcra-dark-blue;
    font-size: 0.55em;
    margin-top: 4px;
    margin-bottom: 4px;
    align-items: center;

    svg {
      margin-right: 2px;
      width: 10px;
      height: 10px;
    }
  }
}

.delete-performance-discipline {
  margin-top: 12px;
}

.input-border-bottom {
  height: 1px;
  width: 120px;
  background-color: $light-blue;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.performance-actions {
  background-color: $white;
  margin: 0 !important;
  padding-right: 1em;
  padding-bottom: 1em;
}
