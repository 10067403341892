.athlete-form-title {
  h2 {
    font-size: 1.4em !important;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.97px;
  }
}

.athlete-modal-content {
  max-height: 60vh;
}

.form-row {
  display: inline-flex;
  width: 100%;
  div:nth-child(1) {
    margin-right: 1em !important;
  }
  div:nth-child(2) {
    margin-right: 1em !important;
  }
  .athlete-select {
    .Select-value-label {
      font-size: .8em;
    }
  }
  input {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      text-transform: uppercase !important;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      text-transform: uppercase !important;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      text-transform: uppercase !important;
    }
    &:-moz-placeholder { /* Firefox 18- */
      text-transform: uppercase !important;
    }
  }
  label {
    font-family: $secondary_font;
    font-weight: 100;
  }

  .athlete-photo {
    position: relative;
    top: 1.3em;
    div:nth-child(1) {
      background-color: $wcra-blue;
      height: 2.7em;
      width: 2.7em;
    }
  }
}
.athlete-photo-circle {
  margin-top: -5px;
  height: 50px !important;
  width: 50px !important;
  border: 3px $blue-light solid;
}
.btn-upload-event-image {
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  margin-top: 60px;
  margin-left: 15px !important;
  text-transform: uppercase;
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  .btn-upload-photo-icon {
    height: 18px;
    margin-bottom: 3px;
    vertical-align: middle;
  }
}

.form-row-field {
  &:before {
    border-bottom: $light-gray solid 1px !important;
  }
  &.id-numbers {
    padding-top: 34px !important;
  }
}

.edit-icon {
  cursor: pointer;
}

.athletes-layout-wrapper {
  position: relative;

  .athletes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .athletes-header-buttons {
    height: 40px;
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .text-athletes button:focus {
      outline: none;
    }

    button {
      &:focus {
        outline: none;
      }
    }
  }
}

.merge-select-all {
  background: none;
  border: none;
  display: block;
  font-size: 1em;
  padding: 10px 0;
  text-transform: uppercase;

  &:enabled {
    color: $wcra-blue !important;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.merge-icon {
  margin-bottom: 4px;
  margin-left: 12px;
  margin-right: 12px;
}

.merge-details, .merge-details-disabled {
  display: block;
  font-size: 0.9em;
  margin-top: -5px;
  padding-bottom: 10px;
  padding-left: 34px;
}
.merge-details-disabled {
  opacity: 0.38;
}
.merge-title {
  font-size: 1.25em;
  font-weight: bold;
  display: block;
}
.merge-row-title {
  padding-left: 12px;
}
.merge-different-field-container {
  font-size: .9em;
  font-weight: 300;
  margin: 12px;
  text-transform: uppercase;
}
.merge-selection-circle {
  color: $wcra-blue !important;
}
.merge-buttons-container {
  float: right;
}
.merge-search-box {
  display: inline-block;
  max-width: 300px;
  width: 100%;
}

.athlete-details {
  margin-top: 64px;

  h6 {
    height: 84px;
  }
}

.admin-notes{
  textarea{
    height: auto;
  }
}

.grid-athlete-edit {
  margin-top: 20px !important;
  .athlete-form-section {
    text-transform: uppercase;
    padding: 0;
    margin: 0;

    &.title {
      font-size: 1.4em;
      padding-bottom: 20px;
      font-weight: 400;
    }
    &.subtitle {
      padding-bottom: 20px;
      font-size: 1.1em;
    }

    &.with-margin {
      margin-top: 40px;
    }
  }

  .paper-content {
    padding: 22px 30px 25px 30px;

    &.margin-bottom-20 {
      margin-bottom: 20px;
    }

    &.margin-top-20 {
      margin-top: 20px;
    }

    .linked-accounts-cell {
      font-weight: bold;
    }

    .nav-to-youth {
      color: black;
    }
  }

  .buttons-control {
    padding-top: 25px;
    float: right;
    button {
      margin-left: 5px;
    }
  }
}

.field-icon {
  margin-right: 8px;
  margin-left: 5px;
}
