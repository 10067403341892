.valid-between-column {
  width: 350px;
  text-align: right;
}

.table-first-column {
  max-width: 300px;
}

.table-last-column {
  min-width: 400px; 
  padding-right: 40px;
}

.invalid-percentage {
  color: red;
  font-family: $secondary-font-regular;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 1rem;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.refund-data {
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: #F4F5F8;
  border-radius: 5px;
  padding: 0;
  margin: 0;

  p {
    text-align: right;
    padding: 5px;
    padding-top: 0;
    padding-bottom: 0;
    color: #242424;
    font-size: 13px;
    font-family: Barlow;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.968px;

    span {
      font-size: 18px;
      font-weight: bolder;
    }
  }
}

.full-name-refund {
  legend {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    font-size: 16px;
    font-family: Barlow;
    line-height: 25px;
    letter-spacing: 0.704px;
    color: #242424;
  }
}